import {
  IonContent,
  IonPage,
  useIonViewDidEnter
} from '@ionic/react'
import { useSelector } from 'react-redux'
import AdminsList from '../../components/admins/AdminsList/AdminsList'
import Admin from '../../components/admins/Admin/Admin'
import AddUser from '../../components/admins/AddAdmin/AddAdmin'
import EditAdmin from '../../components/admins/EditAdmin/EditAdmin'
import { User } from '../../interfaces/interfaces'

export const Admins: React.FC = () => {
  const currentAdmin: User | null = useSelector((state: any) => state.admins.current)
  const creatingAdmin: boolean = useSelector((state: any) => state.admins.creating)
  const editingAdmin: boolean = useSelector((state: any) => state.admins.editing)
  const adminsList = creatingAdmin || currentAdmin || editingAdmin

  useIonViewDidEnter(() => { // TODO refactor this
    document.querySelector('.admins-page')?.classList.remove('ion-page-hidden', 'ion-page-invisible')
    document.querySelector('.admins-page .ion-page')?.classList.remove('ion-page-hidden', 'ion-page-invisible')
  })

  return (
    <IonPage className='admins-page'>
      <IonContent>
        {!adminsList && <AdminsList />}
        {currentAdmin && <Admin />}
        {creatingAdmin && <AddUser />}
        {editingAdmin && <EditAdmin />}
      </IonContent>
    </IonPage>
  )
}

export default Admins
