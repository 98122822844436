import {
  IonContent,
  IonPage,
  useIonViewDidEnter
} from '@ionic/react'
import { useSelector } from 'react-redux'
import StoresList from '../../components/stores/StoresList/StoresList'
import Store from '../../components/stores/Store/Store'
import AddUser from '../../components/stores/AddUser/AddUser'
import EditStore from '../../components/stores/EditStore/EditStore'
import { User } from '../../interfaces/interfaces'

export const Stores: React.FC = () => {
  const currentStore: User | null = useSelector((state: any) => state.stores.current)
  const creatingStore: boolean = useSelector((state: any) => state.stores.creating)
  const editingStore: boolean = useSelector((state: any) => state.stores.editing)
  const storesList = creatingStore || currentStore || editingStore

  useIonViewDidEnter(() => { // TODO refactor this
    document.querySelector('.stores-page')?.classList.remove('ion-page-hidden', 'ion-page-invisible')
    document.querySelector('.stores-page .ion-page')?.classList.remove('ion-page-hidden', 'ion-page-invisible')
  })

  return (
    <IonPage className='stores-page'>
      <IonContent>
        {!storesList && <StoresList />}
        {currentStore && <Store />}
        {creatingStore && <AddUser />}
        {editingStore && <EditStore />}
      </IonContent>
    </IonPage>
  )
}

export default Stores
