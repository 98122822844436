import './PaymentShareButtons.css'
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import {
  chatboxEllipsesOutline,
  clipboard,
  closeOutline,
  logoWhatsapp,
  mail,
  paperPlane,
  shareSocial
} from 'ionicons/icons'
import { isPlatform } from '@ionic/core'
import logoWhatsappNumber from '../../../assets/img/logoWhatsappNumber.svg'
import SendWhatsappModal from '../SendWhatsappModal/SendWhatsappModal'
import { useRef } from 'react'
import { Message, MultiplePayment, Payment, User } from '../../../interfaces/interfaces'
import { t } from 'i18next'
import React from 'react'
import { APP_URL, EASYPAY_URL, GENERATE_PAYMENT_IMPERSONATE } from '../../../utils/constants'
import useCopyToClipboard from '../../../hooks/useCopyToClipboard'

interface ModalProps {
  payment: Payment | MultiplePayment
  message: Message
  currentUser: User | null
}

export const PaymentShareButtons: React.FC<ModalProps> = ({ payment, message, currentUser }) => {
  const { copyToClipboard } = useCopyToClipboard()
  const { shareTitle, shareMessage } = message
  const shareButtonsModal = useRef<HTMLIonModalElement>(null)
  const sendWhatsappModal = useRef<HTMLIonModalElement>(null)

  function dismissShareButtonsModal() {
    shareButtonsModal.current?.dismiss()
  }

  function dismissSendWhatsappModal() {
    sendWhatsappModal.current?.dismiss()
  }

  function isPayment(payment: unknown): payment is Payment {
    return (payment as Payment).url !== undefined;
  }

  const url = isPayment(payment)
    ? (currentUser?.is_admin && currentUser.impersonateId
      ? APP_URL
      : (payment.token ? EASYPAY_URL : payment.url)
    )
    : `${APP_URL}/generate_payment/${currentUser?.is_admin && currentUser.impersonateId ? GENERATE_PAYMENT_IMPERSONATE : payment.seed}`

  return (
    <>
      <IonFab id="open-share-buttons-modal" horizontal="end" vertical="bottom" className="payment-share-button">
        <IonFabButton>
          <IonIcon icon={shareSocial}></IonIcon>
        </IonFabButton>
      </IonFab>

      <IonModal id="share-buttons-modal" ref={shareButtonsModal} trigger="open-share-buttons-modal">
        <IonContent>
          <IonToolbar color="primary">
            <IonTitle>{t('field.share_method')}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => dismissShareButtonsModal()}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>

          <IonGrid id="share-buttons-grid" fixed>
            <IonRow class="ion-align-items-start">
              <IonCol>
                <a
                  href={`https://wa.me/?text=${`*${shareTitle}*%0a${shareMessage.replace(/\n/g, '%0a')}`}`}
                  target="_blank noopener noreferrer"
                  className="share-whatsapp"
                  onClick={dismissShareButtonsModal}
                >
                  <IonFabButton>
                    <IonIcon icon={logoWhatsapp}></IonIcon>
                  </IonFabButton>
                </a>
                <IonText>{t('field.share_methods.whatsapp')}</IonText>
              </IonCol>
              <IonCol>
                <IonFabButton id="open-send-whatsapp-modal" className="share-whatsapp-number">
                  <IonIcon icon={logoWhatsappNumber}></IonIcon>
                </IonFabButton>
                <IonText>{t('action.whatsapp_wo_contact')}</IonText>
              </IonCol>
            </IonRow>
            <IonRow class="ion-align-items-start">
              <IonCol>
                <a
                  href={`https://t.me/share/url?url=${url}&text=${shareTitle}%0a${shareMessage.replace(
                    /\n/g,
                    '%0a'
                  )}`}
                  target="_blank noopener noreferrer"
                  className="share-telegram"
                  onClick={dismissShareButtonsModal}
                >
                  <IonFabButton>
                    <IonIcon icon={paperPlane}></IonIcon>
                  </IonFabButton>
                </a>
                <IonText>{t('field.share_methods.telegram')}</IonText>
              </IonCol>
              <IonCol>
                <a
                  href={`mailto:?subject=${shareTitle}&body=${shareMessage.replace(/\n/g, '%0D%0A')}`}
                  target="_blank noopener noreferrer"
                  className="share-email"
                  onClick={dismissShareButtonsModal}
                >
                  <IonFabButton>
                    <IonIcon icon={mail}></IonIcon>
                  </IonFabButton>
                </a>
                <IonText>{t('email')}</IonText>
              </IonCol>
            </IonRow>
            <IonRow class="ion-align-items-start">
              <IonCol>
                <a
                  href={
                    isPlatform('ios')
                      ? `sms:?&body=${shareMessage.replace(/\n/g, '%0D%0A')}`
                      : `sms:?body=${shareMessage.replace(/\n/g, '%0D%0A')}`
                  }
                  target="_blank noopener noreferrer"
                  className="share-message"
                  onClick={dismissShareButtonsModal}
                >
                  <IonFabButton>
                    <IonIcon icon={chatboxEllipsesOutline}></IonIcon>
                  </IonFabButton>
                </a>
                <IonText>{t('field.share_methods.sms')}</IonText>
              </IonCol>
              <IonCol>
                <IonFabButton
                  onClick={() => {
                    copyToClipboard(shareTitle, shareMessage)
                    dismissShareButtonsModal()
                  }}
                  className="share-clipboard"
                >
                  <IonIcon icon={clipboard}></IonIcon>
                </IonFabButton>
                <IonText>{t('action.copy_to_clipboard')}</IonText>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonModal
            id="send-whatsapp-modal"
            ref={sendWhatsappModal}
            trigger="open-send-whatsapp-modal"
            className="send-whatsapp-modal"
          >
            <SendWhatsappModal
              closeAction={dismissSendWhatsappModal}
              parentCloseAction={dismissShareButtonsModal}
              message={{ shareTitle, shareMessage }}
            />
          </IonModal>
        </IonContent>
      </IonModal>
    </>
  )
}

export default PaymentShareButtons
