import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  isPlatform
} from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'
import { closeOutline, heart } from 'ionicons/icons'
import { useEffect, useRef, useState } from 'react'
import { FavoriteUser, Message } from '../../../interfaces/interfaces'
import getSendMethodIcon from '../../../utils/getSendMethodIcon'
import SkeletonItems from '../../common/SkeletonItems/SkeletonItems'
import './SendToFavoriteUserModal.css'
import PropTypes from 'prop-types'

interface ModalProps {
  message: Message
  getFavoriteUsers: Function
  defaultShowModal?: boolean
}

// TODO custom modal layout that inherits ion-modal props
export const SendToFavoriteUserModal: React.FC<ModalProps> = ({
  message,
  getFavoriteUsers,
  defaultShowModal = false
}) => {
  const { shareTitle, shareMessage } = message
  const [showModal, setShowModal] = useState<boolean>(defaultShowModal)
  const selectedFavoriteUser = useRef<FavoriteUser | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const isMounted = useRef(true)

  const { data } = useQuery<FavoriteUser[]>({
    queryKey: ['favoriteUsers'],
    queryFn: () => getFavoriteUsers(),
    onSuccess: () => {
      const timeoutId: NodeJS.Timeout = setTimeout(() => {
        if (isMounted.current) {
          setIsLoading(false)
        }
      }, 1000)
      timeout.current = timeoutId
    }
  })

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const sendPayment = () => {
    if (!selectedFavoriteUser.current) return

    const link = document.createElement('a')

    switch (selectedFavoriteUser.current.shareMethod.toString()) {
      case 'email':
        link.href = `mailto:${selectedFavoriteUser.current.email}?subject=${shareTitle}&body=${shareMessage.replace(/\n/g, '%0D%0A')}`
        break
      case 'sms':
        link.href = `sms:+${selectedFavoriteUser.current.prefix}${selectedFavoriteUser.current.phone}?${isPlatform('ios') ? '&' : ''}body=${shareMessage.replace(/\n/g, '%0D%0A')}`
        break
      case 'whatsapp':
        link.href = `https://wa.me/${selectedFavoriteUser.current.prefix}${selectedFavoriteUser.current.phone}?text=${`*${shareTitle}*%0a${shareMessage.replace(/\n/g, '%0a')}`}`
        link.dataset.action = 'share/whatsapp/share'
        break
    }

    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    link.click()

    toggleModal()
  }

  useEffect(() => {
    if (!selectedFavoriteUser.current && data?.length) selectedFavoriteUser.current = data[0]
  }, [data])

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
      isMounted.current = false
    }
  }, [])

  return (
    <>
      <IonFab onClick={toggleModal} horizontal="end" vertical="bottom" className="favorite-users-button">
        <IonFabButton>
          <IonIcon icon={heart}></IonIcon>
        </IonFabButton>
      </IonFab>

      <IonModal isOpen={showModal} className="send-to-favorite-user-modal" backdropDismiss={false}>
        <IonPage>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>{t('title.favorite_users')}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => toggleModal()}>
                  <IonIcon icon={closeOutline}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="">
            <div className="modal-content-container">
              <div>
                {data?.length ? (
                  <IonList>
                    <IonRadioGroup
                      value={data[0].id}
                      onIonChange={(e) => {
                        selectedFavoriteUser.current =
                          data.find(
                            (favoriteUser) => favoriteUser.id === (e?.target as HTMLIonRadioGroupElement).value
                          ) || null
                      }}
                    >
                      {data.map((favoriteUser) => (
                        <IonItem key={favoriteUser.id} className="modal-fav-user-item">
                          <IonLabel>
                            <h3>
                              <b>{favoriteUser.userName}</b>
                            </h3>
                            <p>{favoriteUser.email || favoriteUser.phone}</p>
                          </IonLabel>
                          <IonThumbnail slot="end">
                            <IonIcon icon={getSendMethodIcon(favoriteUser.shareMethod.toString())} />
                          </IonThumbnail>
                          <IonRadio slot="start" value={favoriteUser.id} />
                        </IonItem>
                      ))}
                    </IonRadioGroup>
                  </IonList>
                ) : isLoading ? (
                  <SkeletonItems items={3} radio />
                ) : (
                  <div>
                    <div className="login-logo-container">
                      <IonImg className="ion-padding logo-favorite-user-modal" src="/assets/img/logo-blue.png" />
                    </div>
                    <p className="text-center no-favorite-users-text">{t('message.no_favorite_users')}</p>
                  </div>
                )}
              </div>

              {!isLoading && data?.length ? (
                <div className="buttons-container">
                  <IonButton onClick={sendPayment}>{t('action.send')}</IonButton>
                  <IonButton onClick={toggleModal}>{t('action.cancel')}</IonButton>
                </div>
              ) : null}
            </div>
          </IonContent>
        </IonPage>
      </IonModal>
    </>
  )
}

SendToFavoriteUserModal.propTypes = {
  message: PropTypes.shape({
    shareTitle: PropTypes.string.isRequired,
    shareMessage: PropTypes.string.isRequired
  }).isRequired,
  getFavoriteUsers: PropTypes.func.isRequired,
  defaultShowModal: PropTypes.bool
}

export default SendToFavoriteUserModal
