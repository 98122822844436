import axios from 'axios'
import { FavoriteUser } from '../interfaces/interfaces'
import { URL_GET_FAVORITE_USERS } from './constants'

const getFavoriteUsers = async (): Promise<FavoriteUser[]> => {
  return await axios({
    method: 'GET',
    headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
    url: URL_GET_FAVORITE_USERS
  })
    .then((response) => {
      return response.data.length ? response.data : []
    })
    .catch(() => {
      return null
    })
}

export default getFavoriteUsers
