import './ConfirmDisableModal.css'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import useToast from '../../../hooks/useToast'
import { User } from '../../../interfaces/interfaces'
import { useDispatch } from 'react-redux'
import { IMPERSONATE_DATA } from '../../../utils/constants'

interface ModalProps {
  id: number
  url: string
  dispatchFn: Function
  title: string
  message: string
  noPaddingTop?: boolean
  currentUser: User | null
  deleted?: boolean
}

export const ConfirmDisableModal: React.FC<ModalProps> = ({ // TODO refactor, this is a copy of ConfirmDeleteModal. Refactor DisableStoreModal too
  id,
  url,
  dispatchFn,
  title,
  message,
  noPaddingTop,
  currentUser,
  deleted = false
}) => {
  const [showModal, setShowModal] = useState(false)
  const [busy, setBusy] = useState<boolean>(false)
  const [unmounted, setUnmounted] = useState(false)
  const { toast } = useToast()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      setUnmounted(true)
    }
  }, [])

  const openDisableModal = () => {
    setShowModal(true)
  }

  async function closeDisableModal(disable: boolean = false) {
    if (!unmounted) {
      setShowModal(false)
    }

    if (disable) {
      setBusy(true)
      await axios({
        method: 'POST',
        headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
        url,
        data: { id, impersonateData: localStorage.getItem(IMPERSONATE_DATA) }
      })
        .then(() => {
          setBusy(false)
          if (currentUser?.is_admin && currentUser?.impersonateId) {
            localStorage.setItem(IMPERSONATE_DATA, JSON.stringify({ ...JSON.parse(localStorage.getItem(IMPERSONATE_DATA)!), deleted: !deleted }))
            toast(t(deleted ? 'impersonate.data_enabled' : 'impersonate.data_disabled'))
          }
          if (!unmounted) {
            dispatch(dispatchFn())
          }
        })
        .catch(() => {
          setBusy(false)
          toast(t('error.generic'))
        })
    }
  }

  return (
    <div className={`ion-padding ${noPaddingTop ? 'no-padding-top' : ''}`}>
      {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

      <IonButton onClick={openDisableModal} expand="block" type="button" color={deleted ? 'success' : 'danger'} className="ion-margin-top confirm-modal-button">
        {t(deleted ? 'action.enable' : 'action.disable')}
      </IonButton>

      <IonModal isOpen={showModal} className="confirm-disable-modal" onDidDismiss={() => closeDisableModal()}>
        <IonPage>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>{t(title)}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => closeDisableModal()}>
                  <IonIcon icon={closeOutline}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            <div className="modal-content-container">
              <IonLabel>{t(message)}</IonLabel>
              <div className="buttons-container">
                <IonButton onClick={() => closeDisableModal(true)}>{t('action.accept')}</IonButton>
                <IonButton onClick={() => closeDisableModal()}>{t('action.cancel')}</IonButton>
              </div>
            </div>
          </IonContent>
        </IonPage>
      </IonModal>
    </div>
  )
}

export default ConfirmDisableModal
