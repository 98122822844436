import './PaymentsList.css'
import React from 'react'
import {
  IonAccordion,
  IonAccordionGroup,
  IonChip,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonThumbnail,
  RefresherEventDetail
} from '@ionic/react'
import { cloudOfflineOutline, codeDownload, documentsOutline } from 'ionicons/icons'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { refreshDbPaymentsList, refreshPaymentsList, setCreatingPayment, setCurrentPayment, setDbPage } from '../../../redux/actions'
import { isPlatform } from '@ionic/core'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Payment, PaymentsListFilters, SearchItemFilters, User } from '../../../interfaces/interfaces'
import {
  STATUS_CANCELLED_CECA_TEXT,
  STATUS_CANCELLED_ID,
  STATUS_COMPLETED_CECA_TEXT,
  STATUS_COMPLETED_ID,
  STATUS_DENIED_CECA_TEXT,
  STATUS_DENIED_ID,
  STATUS_EXPIRED_CECA_TEXT,
  STATUS_EXPIRED_ID,
  STATUS_SENT_CECA_TEXT,
  STATUS_SENT_ID,
  URL_GET_PAYMENTS,
  URL_GET_PAYMENTS_PAGE,
  URL_SEARCH_PAYMENTS
} from '../../../utils/constants'
import Statistics from '../Statistics/Statistics'
import useToast from '../../../hooks/useToast'
import { formatEuroNumber, getStatusById, isImpersonatingOrStore } from '../../../utils/helper'

interface PaymentsListProps {
  showStatistics: boolean,
  storeFilter: number,
  subUserFilter: number,
  currentUser: User | null
}

export const PaymentsList: React.FC<PaymentsListProps> = ({ showStatistics, storeFilter, subUserFilter, currentUser }) => {
  const dispatch = useDispatch()
  const { toast } = useToast()
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false)
  const [page, setPage] = useState(2)
  const dbPage = useSelector((state: any) => state.payments.dbPage)
  const [filters, setFilters] = useState<PaymentsListFilters>({
    status: {
      sent: false,
      expire_payment: false,
      deny: false,
      cancelled: false,
      completed: false
    },
    onlyFromApp: false,
    onlyFromCeca: false
  })
  const descriptionFilter: string | null = useSelector((state: any) => state.descriptionFilter)
  const searchFilters: SearchItemFilters = useSelector((state: any) => state.payments.searchFilters)

  const dbPaymentsList: Payment[] | null = useSelector((state: any) => state.payments.dbList)
  const cecaPaymentsList: Payment[] = useSelector((state: any) => state.payments.list)
  const paymentsList: Payment[] = dbPaymentsList ?? cecaPaymentsList
  const [filteredPaymentsList, setFilteredPaymentList] = useState(paymentsList)

  const getPayments = async (merchantId: string, resetAdminPage: boolean) => {
    const isImpersonatingOrStoreUser = isImpersonatingOrStore(currentUser)
    return await axios({
      method: !isImpersonatingOrStoreUser ? 'POST' : 'GET',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      params: { merchant_id: merchantId },
      url: !isImpersonatingOrStoreUser ? URL_SEARCH_PAYMENTS : URL_GET_PAYMENTS,
      data: !isImpersonatingOrStoreUser ? { ...searchFilters, page: resetAdminPage ? 1 : dbPage } : {}
    })
      .then((response) => {
        return response.data
      })
      .catch(() => {
        return null
      })
  }

  const setPayments = async (resetAdminPage = false) => {
    const merchantId = localStorage.getItem('USER_MERCHANT_ID') || ''
    const payments = await getPayments(merchantId, resetAdminPage)
    dispatch(!isImpersonatingOrStore(currentUser) ? refreshDbPaymentsList(payments) : refreshPaymentsList(payments))
  }

  function toggleAnimationEndClass(show: boolean) {
    if (show) {
      document.querySelector('.payments-list')?.classList.add('d-none')
    } else {
      document.querySelector('.payments-list')?.classList.remove('d-none')
    }
  }

  useEffect(() => {
    if (!descriptionFilter) setPayments()
  }, [])

  useEffect(() => {
    document.querySelector('.payments-list')?.addEventListener('animationend', () => toggleAnimationEndClass(showStatistics))
    return () => {
      document.querySelector('.payments-list')?.removeEventListener('animationend', () => toggleAnimationEndClass(showStatistics))
    }
  }, [showStatistics])

  useEffect(() => {
    if (paymentsList) {
      const filteredByStatus =
        filters.status.completed ||
        filters.status.cancelled ||
        filters.status.deny ||
        filters.status.expire_payment ||
        filters.status.sent
          ? [
            ...paymentsList.filter((payment: any) => {
              return (
                (filters.status.completed && (payment.status === STATUS_COMPLETED_CECA_TEXT || payment.statusId === STATUS_COMPLETED_ID)) ||
                (filters.status.sent && (payment.status === STATUS_SENT_CECA_TEXT || payment.statusId === STATUS_SENT_ID)) ||
                (filters.status.expire_payment && (payment.status === STATUS_EXPIRED_CECA_TEXT || payment.statusId === STATUS_EXPIRED_ID)) ||
                (filters.status.deny && (payment.status === STATUS_DENIED_CECA_TEXT || payment.statusId === STATUS_DENIED_ID)) ||
                (filters.status.cancelled && (payment.status === STATUS_CANCELLED_CECA_TEXT || payment.statusId === STATUS_CANCELLED_ID))
              )
            })
          ]
          : [...paymentsList]

      const filteredBySource = [
        ...filteredByStatus.filter((payment: any) => {
          return (
            (filters.onlyFromApp && !payment.fromCeca) ||
            (filters.onlyFromCeca && payment.fromCeca) ||
            (!filters.onlyFromApp && !filters.onlyFromCeca)
          )
        })
      ]

      setFilteredPaymentList(filteredBySource)
    }
  }, [paymentsList, filters])

  const getStatusColor = (status: string) => {
    if (!status) return 'medium'
    else if (status === STATUS_COMPLETED_CECA_TEXT) return 'success'
    else if (status === STATUS_SENT_CECA_TEXT) return 'primary'
    else return 'danger'
  }

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(setDbPage(1))
    await setPayments(true)
    event.detail.complete()
  }

  const onPaymentClick = async (payment: any, cloning: boolean = false) => {
    if (dbPaymentsList) {
      payment = await axios({
        method: 'GET',
        headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
        params: {
          merchant_id: localStorage.getItem('USER_MERCHANT_ID') || '',
          userId: payment.User.parentStoreId ?? payment.User.id
        },
        url: `${URL_GET_PAYMENTS}/${payment.num_operation}/${payment.createdAt}`
      })
        .then((response) => {
          return response.data[0]
        })
        .catch(() => {
          return {}
        })
    }
    cloning ? dispatch(setCreatingPayment(payment)) : dispatch(setCurrentPayment(payment))
  }

  const pushNewPayments = async () => {
    const newPayments = await axios({
      method: 'GET',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      params: { merchant_id: localStorage.getItem('USER_MERCHANT_ID') || '' },
      url: `${URL_GET_PAYMENTS_PAGE}/${page}`
    })
      .then((response) => {
        setPage(page + 1)
        return response.data
      })
      .catch(() => {
        return null
      })

    if (newPayments?.length) {
      dispatch(refreshPaymentsList([...paymentsList, ...newPayments]))
    } else {
      toast(t('message.no_more_payments'))
    }
  }

  const pushNewDbPayments = async () => {
    const newPayments = await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: URL_SEARCH_PAYMENTS,
      data: { ...searchFilters, page: dbPage + 1 }
    })
      .then((response) => {
        dispatch(setDbPage(dbPage + 1))
        return response.data
      })
      .catch(() => {
        return null
      })

    if (newPayments?.length) {
      dispatch(refreshDbPaymentsList([...paymentsList, ...newPayments]))
    } else {
      toast(t('message.no_more_payments'))
    }
  }

  const loadNewPayments = (event: any) => {
    setTimeout(async () => {
      if (dbPaymentsList) {
        await pushNewDbPayments()
      } else {
        await pushNewPayments()
      }
      event.target.complete()
      if (paymentsList.length >= 400) {
        setInfiniteDisabled(true)
      }
    }, 500)
  }

  return (
    <>
      <IonAccordionGroup className={`payment-status-filters ${showStatistics ? 'payments-list-hide' : 'payments-list-show'}`}>
        <IonAccordion value="filters">
          <IonItem slot="header">
            <IonLabel>{t('filters')}</IonLabel>
          </IonItem>
          <IonList slot="content">
            <IonItem>{t('title.op_status')}</IonItem>
            <IonList className="filters">
              <IonChip
                color={filters.status.completed ? 'primary' : 'medium'}
                outline={!filters.status.completed}
                onClick={() => {
                  setFilters({ ...filters, status: { ...filters.status, completed: !filters.status.completed } })
                }}
              >
                <IonLabel>{t('status.completed')}</IonLabel>
              </IonChip>
              <IonChip
                color={filters.status.sent ? 'primary' : 'medium'}
                outline={!filters.status.sent}
                onClick={() => {
                  setFilters({ ...filters, status: { ...filters.status, sent: !filters.status.sent } })
                }}
              >
                <IonLabel>{t('status.sent')}</IonLabel>
              </IonChip>
              <IonChip
                color={filters.status.expire_payment ? 'primary' : 'medium'}
                outline={!filters.status.expire_payment}
                onClick={() => {
                  setFilters({
                    ...filters,
                    status: { ...filters.status, expire_payment: !filters.status.expire_payment }
                  })
                }}
              >
                <IonLabel>{t('status.expire_payment')}</IonLabel>
              </IonChip>
              <IonChip
                color={filters.status.deny ? 'primary' : 'medium'}
                outline={!filters.status.deny}
                onClick={() => {
                  setFilters({ ...filters, status: { ...filters.status, deny: !filters.status.deny } })
                }}
              >
                <IonLabel>{t('status.denied')}</IonLabel>
              </IonChip>
              <IonChip
                color={filters.status.cancelled ? 'primary' : 'medium'}
                outline={!filters.status.cancelled}
                onClick={() => {
                  setFilters({ ...filters, status: { ...filters.status, cancelled: !filters.status.cancelled } })
                }}
              >
                <IonLabel>{t('status.cancelled')}</IonLabel>
              </IonChip>
            </IonList>
          </IonList>
          <IonList slot="content">
            <IonItem>{t('title.op_source')}</IonItem>
            <IonList className="filters">
              <IonChip
                color={filters.onlyFromApp ? 'primary' : 'medium'}
                outline={!filters.onlyFromApp}
                onClick={() => {
                  setFilters({ ...filters, onlyFromApp: !filters.onlyFromApp, onlyFromCeca: false })
                }}
              >
                <IonLabel>{t('app_name')}</IonLabel>
              </IonChip>
              <IonChip
                color={filters.onlyFromCeca ? 'primary' : 'medium'}
                outline={!filters.onlyFromCeca}
                onClick={() => {
                  setFilters({ ...filters, onlyFromCeca: !filters.onlyFromCeca, onlyFromApp: false })
                }}
              >
                <IonLabel>{t('tpv_name')}</IonLabel>
              </IonChip>
            </IonList>
          </IonList>
        </IonAccordion>
      </IonAccordionGroup>

      <Statistics
        showStatistics={showStatistics}
        paymentsList={dbPaymentsList}
        storeFilter={storeFilter}
        subUserFilter={subUserFilter}
        statusFilter={[filters, setFilters]}
      />

      {!showStatistics && (
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent pullingIcon={isPlatform('ios') ? 'lines' : 'circular'}></IonRefresherContent>
        </IonRefresher>
      )}

      {!paymentsList ? (
        <div className="no-payments">
          <div className="login-logo-container">
            <IonImg className="ion-padding login-logo" src="/assets/img/logo-blue.png" />
          </div>
          <p className="text-center no-payments-text">{t('message.no_payments')}</p>
        </div>
      ) : dbPaymentsList && !dbPaymentsList.length ? (
        <div className="no-payments">
          <div className="login-logo-container">
            <IonImg className="ion-padding login-logo" src="/assets/img/logo-blue.png" />
          </div>
          <p className="text-center no-payments-text">{t('message.no_payments_found')}</p>
        </div>
      ) : (
        <div className={`payments-list ${showStatistics ? 'payments-list-hide' : 'payments-list-show'}`}>
          {filteredPaymentsList.map((payment, index: number) => (
            <IonItem className="mapped-item" key={index}>
              <IonItem className="mapped-item-inner list-item" lines="none" onClick={() => onPaymentClick(payment)}>
                <IonThumbnail slot="start">
                  <IonIcon className="item-icon" icon={payment.url ? codeDownload : cloudOfflineOutline} />
                </IonThumbnail>
                <IonLabel>
                  <h2>
                    <b>{payment.description}</b>
                  </h2>
                  {payment.expiration !== '0000-00-00' && <p>{payment.expiration}</p>}
                  <IonText slot="end" color={getStatusColor(dbPaymentsList ? getStatusById(payment.statusId) : payment.status)}>
                    {dbPaymentsList ? getStatusById(payment.statusId) : (payment.status === STATUS_DENIED_CECA_TEXT ? t('status.op_denied') : payment.status)}
                  </IonText>
                  {
                    payment.name ? (
                      <IonChip color='primary' className='payment-name'>
                        <b>{payment.name}</b>
                      </IonChip>
                    ) : null
                  }
                </IonLabel>
                <div className='item-chips'>
                  {payment?.User?.parentStoreId && currentUser?.identifier !== payment.User.identifier && (
                    <IonChip color="warning">
                      <b>{payment.User.identifier}</b>
                    </IonChip>
                  )}
                  <IonChip color={getStatusColor(dbPaymentsList ? getStatusById(payment.statusId) : payment.status)}>
                    <b>{formatEuroNumber(payment.amount)}</b>
                  </IonChip>
                </div>
              </IonItem>
              {isImpersonatingOrStore(currentUser) && (
                <button className='reset-button list-item-icon-button' onClick={() => onPaymentClick(payment, true)}>
                  <IonIcon className="list-item-icon" color="primary" icon={documentsOutline} />
                  <IonLabel>{t('action.clone')}</IonLabel>
                </button>
              )}
            </IonItem>
          ))}
        </div>
      )}

      {!showStatistics && (
        <IonInfiniteScroll className="ion-padding" onIonInfinite={loadNewPayments} disabled={isInfiniteDisabled}>
          <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText={t('message.loading_more_payments')} />
        </IonInfiniteScroll>
      )}
    </>
  )
}

export default PaymentsList
