import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { chevronBack } from 'ionicons/icons'
import { Dispatch, FormEventHandler, MouseEventHandler, SetStateAction, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SHARE_METHODS, URL_DELETE_FAVORITE_USER } from '../../../utils/constants'
import React from 'react'
import { FavoriteUser, User } from '../../../interfaces/interfaces'
import { ConfirmDeleteModal } from '../../common/ConfirmDeleteModal/ConfirmDeleteModal'
import { setCurrentFavoriteUser } from '../../../redux/actions'
import PhoneInput from '../../common/PhoneInput/PhoneInput'

interface FavoriteUserFormProps {
  onSubmit: Function
  handleDismiss: MouseEventHandler
  emailInput: [boolean, Dispatch<SetStateAction<boolean>>]
  defaultShareMethod: string
  currentUser: User
}

export const FavoriteUserForm: React.FC<FavoriteUserFormProps> = ({
  onSubmit,
  handleDismiss,
  emailInput,
  defaultShareMethod,
  currentUser
}) => {
  const favoriteUser: FavoriteUser | null = useSelector((state: any) => state.favorites.current)
  const { t } = useTranslation()
  const userName = useRef<string>(favoriteUser?.userName || '')
  const email = useRef<string>(favoriteUser?.email || '')
  const prefix = useRef<string>(favoriteUser?.prefix || '34')
  const phone = useRef<string>(favoriteUser?.phone || '')
  const shareMethod = useRef<string>(favoriteUser?.shareMethod.toString() || defaultShareMethod)
  const [showEmailInput, setShowEmailInput] = emailInput

  const saveFavoriteUser: FormEventHandler = (event) => {
    event.preventDefault()

    const formData = {
      userName: userName.current,
      email: email.current,
      prefix: prefix.current,
      phone: phone.current,
      shareMethod: shareMethod.current
    }
    onSubmit(formData)
  }

  useEffect(() => {
    setShowEmailInput(favoriteUser ? favoriteUser.shareMethod.toString() === defaultShareMethod : true)
  }, [])

  return (
    <IonPage className="input-form">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle>{favoriteUser ? t('title.edit_favorite_user') : t('title.new_favorite_user')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form onSubmit={saveFavoriteUser}>
          <IonList lines="full" className="ion-no-margin ion-no-padding">
            <IonItem>
              <IonLabel position="stacked">{t('field.name')}</IonLabel>
              <IonInput
                required
                name="userName"
                type="text"
                onInput={(e: any) => {
                  userName.current = e.target.value
                }}
                value={userName.current}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">{t('field.share_method')}</IonLabel>
              <IonSelect
                name="shareMethod"
                interface="action-sheet"
                cancelText={t('action.cancel')}
                placeholder={t('field.share_method')}
                onIonChange={(e) => {
                  shareMethod.current = e.detail.value
                  setShowEmailInput(e.detail.value === defaultShareMethod)
                }}
                value={shareMethod.current}
              >
                {SHARE_METHODS.map((method) => (
                  <IonSelectOption key={method} value={method} defaultChecked={method === shareMethod.current}>
                    {t(`field.share_methods.${method}`)}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            {showEmailInput ? (
              <IonItem>
                <IonLabel position="stacked">{t('field.email')}</IonLabel>
                <IonInput
                  required
                  name="email"
                  inputMode="email"
                  type="email"
                  onInput={(e: any) => {
                    email.current = e.target.value
                  }}
                  value={email.current}
                />
              </IonItem>
            ) : (
              <PhoneInput prefix={prefix} phone={phone} />
            )}
          </IonList>

          <div className="ion-padding">
            <IonButton expand="block" type="submit" className="ion-no-margin">
              {t('action.save_favorite_user')}
            </IonButton>
          </div>

          {favoriteUser && (
            <ConfirmDeleteModal
              id={favoriteUser.id}
              url={URL_DELETE_FAVORITE_USER}
              dispatchFn={() => setCurrentFavoriteUser(null)}
              title="action.delete_favorite_user"
              message="message.delete_favorite_user_confirmation"
              noPaddingTop
              currentUser={currentUser}
            />
          )}
        </form>
      </IonContent>
    </IonPage>
  )
}

export default FavoriteUserForm
