import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setCreatingAdmin } from '../../../redux/actions'
import { chevronBack } from 'ionicons/icons'
import axios from 'axios'
import { registerUser } from '../../../firebase'
import useToast from '../../../hooks/useToast'
import { URL_CREATE_ADMIN, URL_USER } from '../../../utils/constants'

export const AddAdmin: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const { toast } = useToast()
  const [busy, setBusy] = useState<boolean>(false)
  const handleDismiss = () => {
    setBusy(false)
    dispatch(setCreatingAdmin(false))
  }

  const addAdmin = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setBusy(true)

    if (!name) {
      toast(t('error.user_name_required'))
      setBusy(false)
      return
    }

    if (!email) {
      toast(t('error.email_required'))
      setBusy(false)
      return
    }

    const password = Math.random().toString(36)
    const result = await registerUser(email, password)
    if (!result.success) {
      toast(t('error.email_already_exists'))
    } else {
      const uid = result?.user?.uid

      await axios({
        method: 'GET',
        headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
        url: `${URL_USER}?email=${email}`
      }).then(() => {
        toast(t('error.email_already_exists'))
      }).catch(async () => {
        await axios({
          method: 'POST',
          headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
          url: URL_CREATE_ADMIN,
          data: {
            name,
            email,
            uid
          }
        })

        handleDismiss()
      })
    }

    setBusy(false)
  }

  return (
    <IonPage>
      {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle> {t('title.new_user')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form onSubmit={addAdmin} id='admin-form'>
          <IonList lines="full" className="ion-no-margin ion-no-padding">
            <IonItem>
              <IonLabel position="stacked">{t('field.name')}</IonLabel>
              <IonInput
                required
                type="text"
                onInput={(e: any) => {
                  setName(e.target.value)
                }}
                value={name}
                name="name"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">{t('field.email')}</IonLabel>
              <IonInput
                required
                type="email"
                onInput={(e: any) => {
                  setEmail(e.target.value)
                }}
                value={email}
                name="email"
              />
            </IonItem>
          </IonList>

          <div className="ion-padding">
            <IonButton expand="block" type="submit" className="ion-no-margin">
              {t('action.create_admin')}
            </IonButton>
          </div>
        </form>
      </IonContent>
    </IonPage>
  )
}

export default AddAdmin
