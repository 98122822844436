import './Statistics.css'
import React, { useEffect, useState } from 'react'
import { IonContent, IonFab, IonFabButton, IonIcon, IonItem, IonLoading } from '@ionic/react'
import StatisticBarChart from '../../common/StatisticBarChart/StatisticBarChart'
import { BarChart, LastYearTotalStatistics, Payment, PaymentsListFilters, PieChart, SearchItemFilters, User } from '../../../interfaces/interfaces'
import { cloudDownload, newspaper, trophy } from 'ionicons/icons'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import {
  STATUS_CANCELLED_ID,
  STATUS_COMPLETED_ID,
  STATUS_DENIED_ID,
  STATUS_EXPIRED_ID,
  STATUS_SENT_ID,
  URL_GET_BAR_CHART_TOTAL_STATISTICS,
  URL_GET_LAST_YEAR_TOTAL_STATISTICS,
  URL_GET_TOP_10_STORES_STATISTICS,
  URL_SEND_STATISTICS_EMAIL
} from '../../../utils/constants'
import { t } from 'i18next'
import { getMonthName, isImpersonatingOrStore } from '../../../utils/helper'
import useToast from '../../../hooks/useToast'
import StatisticPieChart from '../../common/StatisticPieChart/StatisticPieChart'
import { useSelector } from 'react-redux'

interface StatisticsProps {
  showStatistics: boolean
  paymentsList: Payment[] | null
  storeFilter: number
  subUserFilter: number
  statusFilter: [PaymentsListFilters, Function]
}

export const Statistics: React.FC<StatisticsProps> = ({ showStatistics, paymentsList, storeFilter, subUserFilter, statusFilter }) => {
  const [busy, setBusy] = useState<boolean>(false)
  const { toast } = useToast()
  const [barChartStatistics, setBarChartStatistics] = useState<BarChart[]>([])
  const currentUser: User | null = useSelector((state: any) => state.userData)
  const searchFilters: SearchItemFilters = useSelector((state: any) => state.payments.searchFilters)

  const defaultLastYearTotalStatistics: LastYearTotalStatistics = {
    totalAmount: 0,
    monthTotal: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0
    }
  }

  const defaultBarChartStatistics: BarChart[] = [
    {
      id: STATUS_EXPIRED_ID,
      title: 'Caducadas',
      count: 0,
      fillColor: '#FF0000',
      amount: 0
    },
    {
      id: STATUS_SENT_ID,
      title: 'Enviadas',
      count: 0,
      fillColor: '#FFC800',
      amount: 0
    },
    {
      id: STATUS_DENIED_ID,
      title: 'Denegadas',
      count: 0,
      fillColor: '#009DFF',
      amount: 0
    },
    {
      id: STATUS_COMPLETED_ID,
      title: 'Completadas',
      count: 0,
      fillColor: '#0040FF',
      amount: 0
    },
    {
      id: STATUS_CANCELLED_ID,
      title: 'Canceladas',
      count: 0,
      fillColor: '#9F9F9F',
      amount: 0
    }
  ]

  const defaultPieChartData: PieChart[][] = [
    [{
      name: 'No hay datos',
      numOp: 1,
    }],
    [{
      name: 'No hay datos',
      amount: 1
    }]
  ]

  if (showStatistics) {
    document.querySelector('.payments-list')?.classList.add('d-none')
  } else {
    document.querySelector('.payments-list')?.classList.remove('d-none')
  }

  async function getLastYearTotalStatistics(): Promise<LastYearTotalStatistics> {
    return await axios({
      method: 'GET',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: `${URL_GET_LAST_YEAR_TOTAL_STATISTICS}/${storeFilter}/${subUserFilter}`
    }).then((response) => {
      return response.data
    })
  }

  const { data: lastYearTotalStatistics } = useQuery<LastYearTotalStatistics>({
    queryKey: ['lastYearTotalStatistics', storeFilter, subUserFilter],
    queryFn: () => getLastYearTotalStatistics()
  })

  async function getBarChartTotalStatistics(): Promise<BarChart[]> {
    return await axios({
      method: 'GET',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: URL_GET_BAR_CHART_TOTAL_STATISTICS,
      params: { ...searchFilters, page: -1 }
    }).then((response) => {
      return response.data
    })
  }

  const { data: barChartTotalStatistics } = useQuery<BarChart[]>({
    queryKey: ['barChartTotalStatistics', storeFilter, subUserFilter, paymentsList, searchFilters],
    queryFn: () => getBarChartTotalStatistics()
  })

  async function getTop10StoresStatistics(): Promise<PieChart[][]> {
    return await axios({
      method: 'GET',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: URL_GET_TOP_10_STORES_STATISTICS,
      params: { ...searchFilters, page: -1 }
    }).then((response) => {
      return response.data
    })
  }

  const { data: [top10StoresByNumOp, top10StoresByAmount] } = useQuery<PieChart[][]>({
    queryKey: ['top10StoresStatistics', storeFilter, subUserFilter, paymentsList, searchFilters],
    queryFn: () => getTop10StoresStatistics(),
    initialData: defaultPieChartData,
    enabled: Boolean(!isImpersonatingOrStore(currentUser))
  })

  useEffect(() => {
    setBarChartStatistics(barChartTotalStatistics || defaultBarChartStatistics)
  }, [barChartTotalStatistics, paymentsList])

  async function sendExcel() {
    setBusy(true)

    const barChartData = barChartStatistics.map((item) => {
      return {
        Estado: item.title,
        Operaciones: item.count,
        Total: Number(item.amount).toFixed(2).replace('.', ',')
      }
    })

    const last12MonthsData: any = { Total: lastYearTotalStatistics!.totalAmount.toFixed(2).replace('.', ',') }
    for (const [key, value] of Object.entries(lastYearTotalStatistics!.monthTotal)) {
      last12MonthsData[getMonthName(key)] = value.toFixed(2).replace('.', ',')
    }

    const top10StoresByNumOpData = top10StoresByNumOp.map((item) => {
      return { [item.name]: item.numOp }
    })

    const top10StoresByAmountData = top10StoresByAmount.map((item) => {
      return { [item.name]: item.amount }
    })

    await axios({
      method: 'POST',
      url: URL_SEND_STATISTICS_EMAIL,
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      params: { merchant_id: localStorage.getItem('USER_MERCHANT_ID') || '' },
      data: {
        ...searchFilters,
        page: -1,
        barChartData,
        last12MonthsData,
        top10StoresStatisticsData: {
          top10StoresByNumOpData,
          top10StoresByAmountData
        }
      },
    }).then(() => {
      toast(t('message.send_statistics_email'))
    }).catch(() => {
      toast(t('error.send_statistics_email'))
    })

    setBusy(false)
  }

  return (
    <>
      <IonContent className={`statistics-page`}>
        {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

        <div className="statistics-card">
          <IonItem>
            <IonIcon icon={newspaper} color={'success'} style={{ marginRight: 8 }} />
            {t('field.operations_performed')}
          </IonItem>
          <StatisticBarChart
            totalStatistics={
              showStatistics && lastYearTotalStatistics ? lastYearTotalStatistics : defaultLastYearTotalStatistics
            }
            barChartSeries={barChartStatistics}
            showStatistics={showStatistics}
            statusFilter={statusFilter}
          />
        </div>

        {currentUser && !isImpersonatingOrStore(currentUser) && (
          <>
            <div className="statistics-card">
              <IonItem>
                <IonIcon icon={trophy} color={'warning'} style={{ marginRight: 8 }} />
                {t('field.top_10_stores_by_num_op')}
              </IonItem>
              <StatisticPieChart
                showStatistics={showStatistics}
                pieChartData={top10StoresByNumOp.map((item) => {
                  return { name: item.name, value: Number(item.numOp) }
                })}
              />
            </div>

            <div className="statistics-card">
              <IonItem>
                <IonIcon icon={trophy} color={'warning'} style={{ marginRight: 8 }} />
                {t('field.top_10_stores_by_amount')}
              </IonItem>
              <StatisticPieChart
                showStatistics={showStatistics}
                pieChartData={top10StoresByAmount.map((item) => {
                  return { name: item.name, value: Number(item.amount) }
                })}
                isCurrency
              />
            </div>
          </>
        )}
      </IonContent>

      <IonFab onClick={sendExcel} horizontal="end" vertical="bottom" className="send-excel-button">
        <IonFabButton>
          <IonIcon className="send-excel-icon" icon={cloudDownload}  />
        </IonFabButton>
      </IonFab>
    </>
  )
}

export default Statistics
