import { ApexOptions } from 'apexcharts'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { DARK_MODE_PALETTE, LIGHT_MODE_PALETTE } from '../../../utils/constants'
import { formatEuroNumber } from '../../../utils/helper'

export interface StatisticPieChartProps {
  showStatistics: boolean
  pieChartData: any[]
  isCurrency?: boolean
}

export const StatisticPieChart: React.FC<StatisticPieChartProps> = ({ showStatistics, pieChartData, isCurrency = false }) => {
  const series: ApexOptions['series'] = pieChartData.map((obj: any) => showStatistics ? obj.value : 0)
  const options: ApexOptions = {
    theme: {
      mode: document.body.classList.contains('dark') ? 'dark' : 'light',
    },
    labels: pieChartData.map((obj: any) => showStatistics ? obj.name : ''),
    tooltip: {
      enabled: true,
      y: {
        formatter: (val: number) => isCurrency ? formatEuroNumber(val) : String(val)
      }
    },
    colors: document.body.classList.contains('dark') ? DARK_MODE_PALETTE : LIGHT_MODE_PALETTE
  }

  return (
    <div style={{ marginTop: 10 }}>
      <ReactApexChart options={options} series={series} type="pie" height={180} />
    </div>
  )
}

export default StatisticPieChart
