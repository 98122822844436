import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setCreatingSubUser, setCurrentSubUser } from '../../../redux/actions'
import { chevronBack } from 'ionicons/icons'
import useToast from '../../../hooks/useToast'
import { User } from '../../../interfaces/interfaces'
import PasswordFormFields from '../../common/PasswordFormFields/PasswordFormFields'
import axios from 'axios'
import { URL_DISABLE_SUB_USER, URL_SAVE_SUB_USER } from '../../../utils/constants'
import ConfirmDisableModal from '../../common/ConfirmDisableModal/ConfirmDisableModal'
import { _signInWithEmailAndPassword } from '../../../firebase'

export const SubUserForm: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const currentUser: User | null = useSelector((state: any) => state.userData)
  const subUser: User | null = useSelector((state: any) => state.profile.currentSubUser)
  const identifier = useRef<string>(subUser?.identifier || '')
  const name = useRef<string>(subUser?.name || '')
  const newPasswordState = useState('')
  const confirmNewPasswordState = useState('')
  const { toast } = useToast()
  const [busy, setBusy] = useState<boolean>(false)

  const handleSubUserError: any = { // TODO utils function / hook that handles errors with toasts
    'auth/fill-identifier-field': () => toast(t('error.fill_identifier_field')),
    'auth/fill-name-field': () => toast(t('error.fill_name_field')),
    'auth/wrong-password': () => toast(t('error.current_password_mismatch')),
    'auth/wrong-confirm-password': () => toast(t('error.confirm_password_mismatch')),
    'auth/password-validation': () => toast(t('error.password_validation')),
    'auth/identifier-already-exists': () => toast(t('error.identifier_already_exists')),
    'auth/identifier-validation': () => toast(t('error.identifier_validation')),
    default: () => toast(t('error.generic'))
  }

  const handleDismiss = () => {
    dispatch(setCreatingSubUser(false))
  }

  const saveSubUser = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setBusy(true)

    await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: URL_SAVE_SUB_USER,
      data: {
        id: subUser?.id,
        identifier: identifier.current,
        name: name.current,
        newPassword: newPasswordState[0],
        confirmNewPassword: confirmNewPasswordState[0]
      }
    }).then(() => {
      if (currentUser?.is_admin && currentUser?.impersonateId) toast(t('impersonate.data_saved'))
      handleDismiss()
    }).catch((error) => {
      (handleSubUserError[error.response?.data?.code] || handleSubUserError.default)()
      setBusy(false)
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle>{subUser ? t('title.edit_sub_user') : t('title.new_sub_user')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

        <div>
          <form onSubmit={saveSubUser} className='sub-user-form'>
            <IonList lines="full" className="ion-no-margin ion-no-padding">
              <IonItem>
                <IonLabel position="stacked">{t('field.identifier')}</IonLabel>
                <IonInput
                  onInput={(e: any) => {
                    identifier.current = e.target.value
                  }}
                  value={identifier.current}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t('field.name')}</IonLabel>
                <IonInput
                  onInput={(e: any) => {
                    name.current = e.target.value
                  }}
                  value={name.current}
                />
              </IonItem>
              <PasswordFormFields
                newPasswordState={newPasswordState}
                confirmNewPasswordState={confirmNewPasswordState}
              />
            </IonList>

            <div className="ion-padding">
              <IonButton expand="block" type="submit" className="ion-no-margin">{t(subUser ? 'action.save_changes' : 'action.create_sub_user')}</IonButton>
            </div>

          </form>

          {subUser && (
            <ConfirmDisableModal
              id={subUser.id}
              url={URL_DISABLE_SUB_USER}
              dispatchFn={() => setCurrentSubUser(null)}
              title={subUser.deleted ? 'action.enable_sub_user' : 'action.disable_sub_user'}
              message={subUser.deleted ? 'message.enable_sub_user_confirmation' : 'message.disable_sub_user_confirmation'}
              noPaddingTop
              currentUser={currentUser}
              deleted={subUser.deleted}
            />
          )}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default SubUserForm
