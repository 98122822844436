import './ConfirmDeleteModal.css'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import useToast from '../../../hooks/useToast'
import { User } from '../../../interfaces/interfaces'
import { useDispatch } from 'react-redux'
import { IMPERSONATE_DATA } from '../../../utils/constants'

interface ModalProps {
  id: number
  url: string
  dispatchFn: Function
  title: string
  message: string
  noPaddingTop?: boolean
  currentUser: User | null
}

export const ConfirmDeleteModal: React.FC<ModalProps> = ({ id, url, dispatchFn, title, message, noPaddingTop, currentUser }) => {
  const [showModal, setShowModal] = useState(false)
  const [busy, setBusy] = useState<boolean>(false)
  const [unmounted, setUnmounted] = useState(false)
  const { toast } = useToast()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      setUnmounted(true)
    }
  }, [])

  const openDeleteModal = () => {
    setShowModal(true)
  }

  async function closeDeleteModal(disable: boolean = false) {
    if (!unmounted) {
      setShowModal(false)
    }

    if (disable) {
      setBusy(true)
      await axios({
        method: 'POST',
        headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
        url,
        data: { id, impersonateData: localStorage.getItem(IMPERSONATE_DATA) }
      })
        .then(() => {
          setBusy(false)
          if (currentUser?.is_admin && currentUser?.impersonateId) {
            localStorage.setItem(IMPERSONATE_DATA, JSON.stringify({ ...JSON.parse(localStorage.getItem(IMPERSONATE_DATA)!), deleted: true }))
            toast(t('impersonate.data_removed'))
          }
          if (!unmounted) {
            dispatch(dispatchFn())
          }
        })
        .catch(() => {
          setBusy(false)
          toast(t('error.generic'))
        })
    }
  }

  return (
    <div className={`ion-padding ${noPaddingTop ? 'no-padding-top' : ''}`}>
      {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

      <IonButton onClick={openDeleteModal} expand="block" type="button" color="danger" className="ion-margin-top">
        {t('action.delete')}
      </IonButton>

      <IonModal isOpen={showModal} className="confirm-delete-modal" onDidDismiss={() => closeDeleteModal()}>
        <IonPage>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>{t(title)}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => closeDeleteModal()}>
                  <IonIcon icon={closeOutline}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            <div className="modal-content-container">
              <IonLabel>{t(message)}</IonLabel>
              <div className="buttons-container">
                <IonButton onClick={() => closeDeleteModal(true)}>{t('action.accept')}</IonButton>
                <IonButton onClick={() => closeDeleteModal()}>{t('action.cancel')}</IonButton>
              </div>
            </div>
          </IonContent>
        </IonPage>
      </IonModal>
    </div>
  )
}

export default ConfirmDeleteModal
