import axios from 'axios'
import { URL_LOGGER } from './constants'

type Level = keyof typeof logger
interface LogParams {
  route: string,
  source: string,
  credential?: string,
  firebase_code?: string
}

function logtail(level: Level, message: string, params: LogParams) {
  axios({
    method: 'GET',
    headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
    url: URL_LOGGER,
    params: {
      level,
      message,
      params
    }
  })
}

const logger = {
  log: (message: string, params: LogParams) => {
    logtail('log', message, params )
  },
  debug: (message: string, params: LogParams) => {
    logtail('debug', message, params )
  },
  info: (message: string, params: LogParams) => {
    logtail('info', message, params )
  },
  warn: (message: string, params: LogParams) => {
    logtail('warn', message, params )
  },
  error: (message: string, params: LogParams) => {
    logtail('error', message, params )
  }
}

export default logger
