import './AddPayment.css'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonPopover,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar
} from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setCreatingPayment, setCurrentMultiplePayment, setCurrentPayment } from '../../../redux/actions'
import { chevronBack } from 'ionicons/icons'
import axios from 'axios'
import moment from 'moment'
import useToast from '../../../hooks/useToast'
import { Payment, User } from '../../../interfaces/interfaces'
import {
  IMPERSONATE_DATA,
  IMPERSONATE_EMAIL_NOTIFICATIONS,
  IMPERSONATE_EMAIL_PAYMENT_EXPIRATION,
  URL_ADD_MULTIPLE_PAYMENT,
  URL_ADD_PAYMENT
} from '../../../utils/constants'
import { getLocalStorageUserToggle } from '../../../utils/helper'

export const AddPayment: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { toast } = useToast()
  const userData: User = useSelector((state: any) => state.userData)
  const clonedPayment: Payment | boolean = useSelector((state: any) => state.payments.creating)
  const [description, setDescription] = useState(
    typeof clonedPayment === 'object' ? clonedPayment.description.trim() : ''
  )
  const [amount, setAmount] = useState<number | null>(
    typeof clonedPayment === 'object' ? Number(clonedPayment.amount.toString().replace(',', '.')) : null
  )
  const [expiration, setExpiration] = useState<string | string[]>(
    typeof clonedPayment === 'object' &&
      clonedPayment.expiration &&
      new Date(clonedPayment.expiration).toISOString() > new Date().toISOString()
      ? new Date(clonedPayment.expiration).toISOString()
      : ''
  )
  const [expirationWarning, setExpirationWarning] = useState<boolean>(false)
  const [token, setToken] = useState(typeof clonedPayment === 'object' ? !!clonedPayment.token : false)
  const [multiplePayment, setMultiplePayment] = useState(false)

  const twoMonthsLater = new Date()
  twoMonthsLater.setMonth(twoMonthsLater.getMonth() + 2)

  const handleDismiss = () => {
    dispatch(setCreatingPayment(false))
  }

  const createPayment = (apiResponse: any) => {
    return {
      date: moment().format('DD/MM/YYYY'),
      description,
      status: t('status.just_created'),
      amount,
      expiration,
      url: apiResponse.url,
      token: apiResponse.token,
      numOperation: apiResponse.numOperation,
      cancelled: 'NO'
    }
  }

  const addPayment = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (expiration && moment(expiration).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      toast(t('error.invalid_expiration_date'))
      return
    }

    if (!description) {
      toast(t('error.description_required'))
      return
    }

    if (!amount) {
      toast(t('error.amount_required'))
      return
    }

    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
    axios.defaults.headers.post['Content-Type'] = 'application/json'

    await axios({
      method: 'POST',
      url: multiplePayment ? URL_ADD_MULTIPLE_PAYMENT : URL_ADD_PAYMENT,
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      params: { merchant_id: localStorage.getItem('USER_MERCHANT_ID') || '' },
      data: {
        description,
        expiration: expiration || twoMonthsLater.toISOString(),
        amount,
        token,
        multiplePayment
      }
    }).then((response) => {
      if (multiplePayment) {
        localStorage.setItem(IMPERSONATE_DATA, JSON.stringify(response.data.multiplePayment))
        dispatch(setCurrentMultiplePayment(response.data.multiplePayment))
        document.querySelector<HTMLIonTabButtonElement>('ion-tab-button[tab="multiple_payments"]')?.click()
      } else {
        dispatch(setCurrentPayment(createPayment(response.data)))
      }
      handleDismiss()
      // dispatch(refreshPaymentsList());
    }).catch((error) => {
      if (multiplePayment) {
        toast(t('error.creating_multiple_payment'))
      } else {
        if (error.response.data.message === t('error.token_needed')) {
          toast(t('error.creating_payment_without_token'))
        } else {
          toast(t('error.creating_payment'))
        }
      }
    })
  }

  const handleMultiplePaymentChange = (checked: boolean) => {
    if (checked) {
      setToken(false)
      document.querySelector<HTMLIonToggleElement>('#token-toggle')?.setAttribute('disabled', 'true')
    } else {
      document.querySelector<HTMLIonToggleElement>('#token-toggle')?.removeAttribute('disabled')
    }
    setMultiplePayment(checked)
  }

  return (
    <IonPage className="input-form">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle> {t('title.new_payment')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={addPayment}>
          <IonList lines="full" className="ion-no-margin ion-no-padding">
            <IonItem>
              <IonLabel position="stacked">{t('field.description')}</IonLabel>
              <IonInput
                required
                name="description"
                type="text"
                onInput={(e: any) => {
                  setDescription(e.target.value)
                }}
                value={description}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">{t('field.amount')}</IonLabel>
              <IonInput
                required
                name="amount"
                inputMode="decimal"
                type="number"
                min="1"
                step="any"
                onInput={(e: any) => {
                  const value = Number(e.target.value)
                  if (!isNaN(value)) setAmount(e.target.value)
                }}
                value={amount}
              />
            </IonItem>

            <IonItem button={true} id="open-date-input">
              <IonLabel position="stacked">{t('field.expiration')}</IonLabel>
              <IonText>{expiration ? moment(expiration).format(process.env.REACT_APP_DATE_FORMAT) : ''}</IonText>
              <IonPopover id="date-expiration-popover" trigger="open-date-input" showBackdrop={false} alignment="start">
                <IonDatetime
                  presentation="date"
                  showClearButton
                  onIonChange={(ev) => {
                    setExpiration(ev.detail.value!)
                    if (ev.detail.value &&
                      ((!userData.is_admin && userData.emailNotifications && userData.emailPaymentExpiration) ||
                      (userData.is_admin && userData.impersonateId && getLocalStorageUserToggle(IMPERSONATE_EMAIL_NOTIFICATIONS) && getLocalStorageUserToggle(IMPERSONATE_EMAIL_PAYMENT_EXPIRATION)))
                    ) {
                      const givenDate = new Date(ev.detail.value.toString())
                      const currentDate = new Date()
                      const diffInDays = Math.abs(Math.floor((currentDate.getTime() - givenDate.getTime()) / 86400000)) // 86400000 -> ms on a day
                      setExpirationWarning(diffInDays <= 2)
                    } else {
                      setExpirationWarning(false)
                    }
                    document.querySelector<HTMLIonPopoverElement>('#date-expiration-popover')?.dismiss()
                  }}
                  value={expiration}
                  min={moment(new Date()).add(1, 'days').toISOString()}
                />
              </IonPopover>
            </IonItem>

            <IonItem>
              <IonLabel>{t('field.send_token')}</IonLabel>
              <IonToggle id='token-toggle' checked={token} onIonChange={(e) => setToken(e.detail.checked)} />
            </IonItem>

            <IonItem>
              <IonLabel>{t('field.multiple_payment')}</IonLabel>
              <IonToggle checked={multiplePayment} onIonChange={(e) => handleMultiplePaymentChange(e.detail.checked)} />
            </IonItem>
          </IonList>

          {!expiration ? (
            <div className="expiration-default-warning">
              <p>{t('message.expiration_date_default_warning', { date: moment(twoMonthsLater).format('DD/MM/YYYY') })}</p>
            </div>
          ) : null}

          {expirationWarning ? (
            <div className="expiration-warning">
              <p>{t('message.expiration_date_warning')}</p>
            </div>
          ) : null}

          <div className="ion-padding">
            <IonButton expand="block" type="submit" className="ion-no-margin">
              {t('action.create_payment')}
            </IonButton>
          </div>
        </form>
      </IonContent>
    </IonPage>
  )
}

export default AddPayment
