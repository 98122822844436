import { SearchItemFilters } from '../interfaces/interfaces'

/**
 * Environment variables
*/
export const APP_URL = process.env.REACT_APP_URL || ''
export const URL_ENDPOINT = process.env.REACT_APP_BACKEND_URL || ''
export const FIREBASE_AUTH_EMULATOR_HOST = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST
export const EASYPAY_URL = process.env.REACT_APP_EASYPAY_URL
export const RECAPTCHA_V3_SITE_KEY = FIREBASE_AUTH_EMULATOR_HOST ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' : process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY || ''

/**
 * API Urls
 */
export const URL_LOGGER = URL_ENDPOINT + '/logger'

// Ceca
export const URL_GET_PAYMENTS = URL_ENDPOINT + '/ceca/get_payments'
export const URL_GET_PAYMENTS_PAGE = URL_ENDPOINT + '/ceca/get_payments_page'
export const URL_SEARCH_PAYMENTS = URL_ENDPOINT + '/ceca/search_payments'
export const URL_ADD_PAYMENT = URL_ENDPOINT + '/ceca/add_payment'
export const URL_ADD_MULTIPLE_PAYMENT = URL_ENDPOINT + '/ceca/add_multiple_payment'
export const URL_ADD_PAYMENT_BY_MULTIPLE_PAYMENT = URL_ENDPOINT + '/ceca/add_payment_by_multiple_payment'

// Auth
export const URL_USER = URL_ENDPOINT + '/auth/user'
export const URL_MY_USERDATA = URL_ENDPOINT + '/auth/my_user_data'
export const URL_UPDATE_USER = URL_ENDPOINT + '/auth/update_user'
export const URL_RESET_PASSWORD = URL_ENDPOINT + '/auth/reset_password'
export const URL_REGISTER_USER = URL_ENDPOINT + '/auth/register_user'
export const URL_VERIFY_RECAPTCHA_TOKEN = URL_ENDPOINT + '/auth/verify_recaptcha_token'

// Admin
export const URL_GET_ADMIN_USERS = URL_ENDPOINT + '/admin/get_admin_users'
export const URL_DISABLE_ADMIN = URL_ENDPOINT + '/admin/disable_admin'
export const URL_CREATE_ADMIN = URL_ENDPOINT + '/admin/create_admin'
export const URL_UPDATE_ADMIN = URL_ENDPOINT + '/admin/update_admin'
export const URL_IMPERSONATE = URL_ENDPOINT + '/admin/impersonate'
export const URL_SEND_STORES_LIST_EMAIL = URL_ENDPOINT + '/admin/send_stores_list_email'

// Store
export const URL_GET_FAVORITE_USERS = URL_ENDPOINT + '/store/get_favorite_users'
export const URL_SAVE_FAVORITE_USER = URL_ENDPOINT + '/store/save_favorite_user'
export const URL_DELETE_FAVORITE_USER = URL_ENDPOINT + '/store/delete_favorite_user'
export const URL_UPDATE_STORE = URL_ENDPOINT + '/store/update_store'
export const URL_GET_MULTIPLE_PAYMENT = URL_ENDPOINT + '/store/get_multiple_payment'
export const URL_GET_MULTIPLE_PAYMENTS = URL_ENDPOINT + '/store/get_multiple_payments'
export const URL_DELETE_MULTIPLE_PAYMENT = URL_ENDPOINT + '/store/delete_multiple_payment'
export const URL_GET_SUB_USERS = URL_ENDPOINT + '/store/get_sub_users'
export const URL_SAVE_SUB_USER = URL_ENDPOINT + '/store/save_sub_user'
export const URL_DISABLE_SUB_USER = URL_ENDPOINT + '/store/disable_sub_user'
export const URL_GET_NOTIFICATIONS = URL_ENDPOINT + '/store/get_notifications'
export const URL_SET_NOTIFICATION_READ_AT = URL_ENDPOINT + '/store/set_notification_read_at'

// Statistics
export const URL_GET_LAST_YEAR_TOTAL_STATISTICS = URL_ENDPOINT + '/statistics/get_year_total_payments_statistics'
export const URL_GET_BAR_CHART_TOTAL_STATISTICS = URL_ENDPOINT + '/statistics/get_bar_chart_total_statistics'
export const URL_GET_TOP_10_STORES_STATISTICS = URL_ENDPOINT + '/statistics/get_top_10_stores_statistics'
export const URL_SEND_STATISTICS_EMAIL = URL_ENDPOINT + '/statistics/send_statistics_email'

/**
 * Status
 */
export const STATUS_SENT_ID = 1
export const STATUS_CANCELLED_ID = 2
export const STATUS_COMPLETED_ID = 3
export const STATUS_EXPIRED_ID = 4
export const STATUS_DENIED_ID = 5

export const STATUS_SENT_CECA_TEXT = 'Enviado'
export const STATUS_CANCELLED_CECA_TEXT = 'Cancelado'
export const STATUS_COMPLETED_CECA_TEXT = 'Completado'
export const STATUS_EXPIRED_CECA_TEXT = 'Pago caducado'
export const STATUS_DENIED_CECA_TEXT = 'Operación denegada'

/**
 * Constants
 */
export const SHARE_METHODS = ['email', 'sms', 'whatsapp'] as const
export const DEFAULT_VALUE = 0
export const LIGHT_MODE_PALETTE = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#FFA07A', '#00FFFF', '#FFD700', '#A0522D', '#008080']
export const DARK_MODE_PALETTE = ['#4ECDC4', '#C7F464', '#81D4FA', '#FD6A6A', '#546E7A', '#E0FFFF', '#FFA07A', '#DA70D6', '#00FA9A', '#FFE4C4']
export const GENERATE_PAYMENT_IMPERSONATE = 'generate_payment_impersonate'

/**
 * Local storage keys
 */
export const IMPERSONATE_DATA = 'impersonateData'
export const IMPERSONATE_DARK_MODE = 'impersonateDarkMode'
export const IMPERSONATE_EMAIL_NOTIFICATIONS = 'impersonateEmailNotifications'
export const IMPERSONATE_EMAIL_PAYMENT_STATUS = 'impersonateEmailPaymentStatus'
export const IMPERSONATE_EMAIL_PAYMENT_EXPIRATION = 'impersonateEmailPaymentExpiration'
export const IMPERSONATE_ALLOW_SUB_USERS = 'impersonateAllowSubUsers'

/**
 * Regex
 */
// Contains at least one letter, one number and is 8 characters long
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,8}$/
// Contains at least one letter, does not contain spaces and can contain numbers
export const IDENTIFIER_REGEX = /^(?=.*[a-zA-Z])(?=\S+$)[a-zA-Z0-9]*$/
// Exactly 9 digits
export const MERCHANT_ID_REGEX = /^[0-9]{9,9}$/
export const PREFIX_REGEX = /^(\d){1,3}$/
export const PHONE_REGEX = /^(\d){1,10}$/

export const DEFAULT_SEARCH_ITEM_FILTERS: SearchItemFilters = {
  search: null,
  store: 0,
  subUser: 0,
  dateFrom: null,
  dateTo: null,
  expirationFrom: null,
  expirationTo: null
}

// Cookies
export const COOKIE_NOTICE_ACCEPTED = 'Cookie_notice_accepted'
export const COOKIE_EXPIRATION_DAYS = 15

// reCAPTCHA V3 actions
export const RECAPTCHA_LOGIN_ACTION = 'login_action'
export const RECAPTCHA_REGISTER_ACTION = 'register_action'
export const RECAPTCHA_RESET_PASSWORD_REQUEST_ACTION = 'reset_password_request_action'
export const RECAPTCHA_RESET_PASSWORD_ACTION = 'reset_password_action'
