import './SendWhatsappModal.css'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { Message } from '../../../interfaces/interfaces'
import { PHONE_REGEX, PREFIX_REGEX } from '../../../utils/constants'
import PhoneInput from '../../common/PhoneInput/PhoneInput'
import useToast from '../../../hooks/useToast'

interface ModalProps {
  closeAction: Function
  parentCloseAction: Function
  message: Message
}

// TODO custom modal layout that inherits ion-modal props
export const SendWhatsappModal: React.FC<ModalProps> = ({ closeAction, parentCloseAction, message }) => {
  const { shareTitle, shareMessage } = message
  const prefix = useRef<string>('34')
  const phone = useRef<string>('')
  const { toast } = useToast()

  const sendWhatsapp = () => {
    if (!PREFIX_REGEX.test(prefix.current)) {
      toast(t('error.prefix_validation'))
      return
    } else if (!PHONE_REGEX.test(phone.current)) {
      toast(t('error.phone_validation'))
      return
    }

    const link = document.createElement('a')
    link.href = `https://wa.me/${prefix.current}${phone.current}?text=${`*${shareTitle}*%0a${shareMessage.replace(
      /\n/g,
      '%0a'
    )}`}`
    link.dataset.action = 'share/whatsapp/share'
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    link.click()

    closeAction()
    parentCloseAction()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{t('action.send_whatsapp')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => closeAction()}>
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="modal-content-container">
          <IonLabel>{t('message.fill_number')}</IonLabel>
          <div id="phone-container">
            <PhoneInput prefix={prefix} phone={phone} />
          </div>
          <div className="buttons-container">
            <IonButton onClick={() => sendWhatsapp()}>{t('action.send')}</IonButton>
            <IonButton onClick={() => closeAction()}>{t('action.cancel')}</IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

SendWhatsappModal.propTypes = {
  closeAction: PropTypes.func.isRequired,
  parentCloseAction: PropTypes.func.isRequired,
  message: PropTypes.shape({
    shareTitle: PropTypes.string.isRequired,
    shareMessage: PropTypes.string.isRequired
  }).isRequired
}

export default SendWhatsappModal
