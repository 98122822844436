import {
  IonButton,
  IonContent,
  IonHeader,
  IonImg,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import InputPassword from '../../components/common/InputPassword/InputPassword'
import { confirmPasswordChange } from '../../firebase'
import useToast from '../../hooks/useToast'
import { PASSWORD_REGEX, RECAPTCHA_RESET_PASSWORD_ACTION } from '../../utils/constants'
import RecaptchaMessage from '../../components/common/RecaptchaMessage/RecaptchaMessage'
import { useRecaptcha } from '../../hooks/useRecaptcha'

const ResetPassword: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [busy, setBusy] = useState<boolean>(false)
  const { toast } = useToast()
  const { handleVerifyRecaptcha, isRecaptchaReady } = useRecaptcha(RECAPTCHA_RESET_PASSWORD_ACTION)

  const urlParams = new URLSearchParams(window.location.search)
  const oobCode = urlParams.get('oobCode') || ''
  if (oobCode === '') history.push('/login')

  const handleResetPassword = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    const { error } = await handleVerifyRecaptcha(true)
    if (error) {
      toast(t('error.generic'))
      setBusy(false)
      return
    }

    if (newPassword !== confirmNewPassword) {
      toast(t('error.confirm_password_mismatch'))
    } else if (!newPassword.match(PASSWORD_REGEX)) {
      toast(t('error.password_validation'))
    } else {
      setBusy(true)

      await confirmPasswordChange(oobCode, newPassword)
        .then(() => {
          window.location.href = '/dashboard'
        })
        .catch(() => {
          setNewPassword('')
          setConfirmNewPassword('')
          toast(t('error.reset_password_expired'))
        })

      setBusy(false)
    }
  }

  return (
    <IonPage className="login">
      {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{t('action.reset_password')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="login-logo-container">
          <IonImg className="ion-padding login-logo" src="/assets/img/logo-blue.png" />
        </div>

        <form onSubmit={handleResetPassword}>
          <InputPassword
            required
            autocomplete="new-password"
            className="ion-padding"
            placeholder={t('field.new_password')}
            onIonChange={(e: any) => setNewPassword(e.target.value)}
          />
          <InputPassword
            required
            autocomplete="new-password"
            className="ion-padding"
            placeholder={t('field.confirm_new_password')}
            onIonChange={(e: any) => setConfirmNewPassword(e.target.value)}
          />

          <IonButton expand="block" type="submit" disabled={!isRecaptchaReady}>{t('action.reset_password')}</IonButton>
          <RecaptchaMessage />
        </form>
      </IonContent>
    </IonPage>
  )
}

export default ResetPassword
