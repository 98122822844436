import { FavoriteUser, MultiplePayment, NotificationsState, Payment, SearchItemFilters, User } from '../interfaces/interfaces'

export const setPWAEvent = (payload: any) => {
  return { type: 'SET_PWA_EVENT', payload }
}

export const setUserState = (payload: any) => {
  return { type: 'SET_USER_STATE', payload }
}

export const setUserData = (payload: any) => {
  return { type: 'SET_USER_DATA', payload }
}

export const setCurrentStore = (payload: any) => {
  return { type: 'SET_CURRENT_STORE', payload }
}

export const setCreatingUser = (payload: boolean) => {
  return { type: 'SET_CREATING_USER', payload }
}

export const setEditingStore = (payload: boolean) => {
  return { type: 'SET_EDITING_STORE', payload }
}

export const refreshStoresList = (payload: boolean) => {
  return { type: 'REFRESH_STORES_LIST', payload }
}

export const setStoresList = (payload: User[]) => {
  return { type: 'SET_STORES_LIST', payload }
}

export const setCurrentAdmin = (payload: any) => {
  return { type: 'SET_CURRENT_ADMIN', payload }
}

export const setCreatingAdmin = (payload: boolean) => {
  return { type: 'SET_CREATING_ADMIN', payload }
}

export const setEditingAdmin = (payload: boolean) => {
  return { type: 'SET_EDITING_ADMIN', payload }
}

export const setAdminsList = (payload: User[]) => {
  return { type: 'SET_ADMINS_LIST', payload }
}

export const setCurrentPayment = (payload: any) => {
  return { type: 'SET_CURRENT_PAYMENT', payload }
}

export const setCreatingPayment = (payload: Payment | boolean) => {
  return { type: 'SET_CREATING_PAYMENT', payload }
}

export const refreshPaymentsList = (payload: any) => {
  return { type: 'REFRESH_PAYMENTS_LIST', payload }
}

export const refreshDbPaymentsList = (payload: any) => {
  return { type: 'REFRESH_DB_PAYMENTS_LIST', payload }
}

export const setEditingUserProfile = (payload: boolean) => {
  return { type: 'SET_EDITING_USER_PROFILE', payload }
}

export const setCreatingSubUser = (payload: boolean) => {
  return { type: 'SET_CREATING_SUB_USER', payload }
}

export const setCurrentSubUser = (payload: User | null) => {
  return { type: 'SET_CURRENT_SUB_USER', payload }
}

export const setCreatingFavoriteUser = (payload: boolean) => {
  return { type: 'SET_CREATING_FAVORITE_USER', payload }
}

export const setCurrentFavoriteUser = (payload: FavoriteUser | null) => {
  return { type: 'SET_CURRENT_FAVORITE_USER', payload }
}

export const setCurrentMultiplePayment = (payload: MultiplePayment | null) => {
  return { type: 'SET_CURRENT_MULTIPLE_PAYMENT', payload }
}

export const setDescriptionFilter = (payload: string | null) => {
  return { type: 'SET_DESCRIPTION_FILTER', payload }
}

export const setNotifications = (payload: NotificationsState) => {
  return { type: 'SET_NOTIFICATIONS', payload }
}

export const setDbPage = (payload: number) => {
  return { type: 'SET_DB_PAGE', payload }
}

export const setSearchFilters = (payload: SearchItemFilters) => {
  return { type: 'SET_SEARCH_FILTERS', payload }
}
