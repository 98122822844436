// Import the functions you need from the SDKs you need
import axios from 'axios'
import { FirebaseError, getApp, initializeApp } from 'firebase/app'
import {
  Auth,
  EmailAuthProvider,
  User,
  applyActionCode,
  confirmPasswordReset,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
  verifyPasswordResetCode
} from 'firebase/auth'
import { FIREBASE_AUTH_EMULATOR_HOST, URL_IMPERSONATE, URL_MY_USERDATA, URL_USER } from './utils/constants'
import logger from './utils/logger'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = require(process.env.REACT_APP_FIREBASE_CONFIG as string)

initializeApp(firebaseConfig)
initializeApp(firebaseConfig, 'helper')

const auth = getAuth()
const authHelper = getAuth(getApp('helper'))

if (FIREBASE_AUTH_EMULATOR_HOST) {
  connectAuthEmulator(auth, FIREBASE_AUTH_EMULATOR_HOST, { disableWarnings: true })
  connectAuthEmulator(authHelper, FIREBASE_AUTH_EMULATOR_HOST, { disableWarnings: true })
}

export function getCurrentUser() {
  return new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged(function(user) {
      resolve(user)
      unsubscribe()
    })
  })
}

export async function logoutUser() {
  if (auth?.currentUser?.uid) {
    await axios({
      method: 'GET',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: URL_MY_USERDATA
    }).then(async response => {
      if (response.data.impersonateId) {
        await axios({
          method: 'POST',
          headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
          url: URL_IMPERSONATE,
          data: { impersonateId: null }
        })
      }
    })
  }
  return await auth.signOut()
}

export function sendVerification(user: User | null) {
  if (user) sendEmailVerification(user)
}

export async function _signInWithEmailAndPassword(email: string, password: string, authInstance: Auth = authHelper) {
  try {
    const result = await signInWithEmailAndPassword(authInstance, email, password)
    return { success: true, user: result.user, error: '', uid: result.user.uid }
  } catch (error) {
    logger.error(`${({ error }.error as any).message} (${email})`, { credential: email, route: 'firebase/loginUser', source: '[FIREBASE]', firebase_code: ({ error }.error as any).code })
    return { success: false, error, user: null }
  }
}

export async function loginUser(email: string, password: string) {
  return _signInWithEmailAndPassword(email, password, auth)
}

export async function registerUser(email: string, password: string, merchantId = '') {
  if (merchantId !== '') { // checks if merchantId is already registered
    const user = await fetch(`${URL_USER}?merchantId=${merchantId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('AUTH_TOKEN') || ''
      }
    }).then(res => {
      return res.json()
    })

    if (user.uid) {
      logger.error(`Merchant ID already exists`, { route: 'firebase/registerUser', source: '[FIREBASE]', firebase_code: 'auth/merchant-id-already-exists' })
      return { success: false, user: null, error: { code: 'auth/merchant-id-already-exists' } }
    }
  }

  const user = await fetch(`${URL_USER}?email=${email}`, { // check if email is already registered
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('AUTH_TOKEN') || ''
    }
  }).then(res => {
    return res.json()
  })

  if (user.uid) {
    return { success: true, user, error: '' }
  }

  try { // register email in firebase
    const result = await createUserWithEmailAndPassword(authHelper, email, password)
    return { success: true, user: result.user, error: '' }
  } catch (error) {
    logger.error(`${({ error }.error as any).message} (${email})`, { route: 'firebase/registerUser', source: '[FIREBASE]', firebase_code: ({ error }.error as any).code })
    return { success: false, user: null, error }
  }
}

export async function getUserData(merchantId: string) {
  const response = await axios({
    method: 'GET',
    headers: {
      Authorization: localStorage.getItem('AUTH_TOKEN') || ''
    },
    params: { merchant_id: merchantId },
    url: URL_MY_USERDATA
  })

  return response?.data
}

export async function updateUserPassword(currentPassword: string, newPassword: string) {
  try {
    const currentUser = auth?.currentUser
    const userEmail = auth.currentUser?.email
    if (auth && currentUser && userEmail) {
      const emailCred = EmailAuthProvider.credential(userEmail, currentPassword)
      return await reauthenticateWithCredential(currentUser, emailCred).then(async () => {
        await updatePassword(currentUser, newPassword)
        return { success: true, error: '' }
      })
    }
  } catch (error) {
    logger.error(`${({ error }.error as any).message}`, { route: 'firebase/updateUserPassword', source: '[FIREBASE]', firebase_code: ({ error }.error as any).code })
    return { success: false, error: (error as FirebaseError).code }
  }
}

async function handleResetPassword(auth: Auth, oobCode: string) {
  return await verifyPasswordResetCode(auth, oobCode).then(async (email) => {
    return await sendPasswordResetEmail(auth, email)
      .then(() => {
        return { success: true, email, error: '' }
      })
      .catch((error) => {
        logger.error(`${({ error }.error as any).message}`, { route: 'firebase/handleResetPassword', source: '[FIREBASE]', firebase_code: ({ error }.error as any).code })
        return { success: true, email: '', error }
      })
  }).catch((error) => {
    return { success: true, email: '', error }
  })
}

async function handleVerifyEmail(auth: Auth, oobCode: string) {
  return await applyActionCode(auth, oobCode).then(() => {
    return { success: true, email: '', error: '' }
  }).catch((error) => {
    logger.error(`${({ error }.error as any).message}`, { route: 'firebase/handleVerifyEmail', source: '[FIREBASE]', firebase_code: ({ error }.error as any).code })
    return { success: false, email: '', error }
  })
}

export async function handleUserAction(url: string) {
  const link = new URL(url).searchParams
  const mode = link.get('mode')
  const oobCode = link.get('oobCode') || ''
  switch (mode) {
    case 'resetPassword':
      return await handleResetPassword(auth, oobCode)
    // case 'recoverEmail':
    //   return await handleRecoverEmail(auth, oobCode)
    case 'verifyEmail':
      return await handleVerifyEmail(auth, oobCode)
    default:
      return { success: false, email: '', error: 'Invalid mode' }
  }
}

export async function confirmPasswordChange(oobCode: string, newPassword: string) {
  return await confirmPasswordReset(auth, oobCode, newPassword).then(() => {
    return { success: true, error: '' }
  }).catch((error) => {
    return { success: false, error: error.code }
  })
}
