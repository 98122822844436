import axios from 'axios'
import {
  URL_IMPERSONATE,
  IMPERSONATE_DARK_MODE,
  IMPERSONATE_EMAIL_NOTIFICATIONS,
  IMPERSONATE_EMAIL_PAYMENT_STATUS,
  IMPERSONATE_EMAIL_PAYMENT_EXPIRATION,
  IMPERSONATE_ALLOW_SUB_USERS
} from './constants'
import { User } from '../interfaces/interfaces'

async function impersonateUser(user: User, catchFn: Function) {
  await axios({
    method: 'POST',
    headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
    url: URL_IMPERSONATE,
    data: { impersonateId: user.id }
  })
    .then((response) => {
      localStorage.setItem(IMPERSONATE_DARK_MODE, String(Number(response.data.dark_mode)))
      localStorage.setItem(IMPERSONATE_EMAIL_NOTIFICATIONS, String(Number(response.data.emailNotifications)))
      localStorage.setItem(IMPERSONATE_EMAIL_PAYMENT_STATUS, String(Number(response.data.emailPaymentStatus)))
      localStorage.setItem(IMPERSONATE_EMAIL_PAYMENT_EXPIRATION, String(Number(response.data.emailPaymentExpiration)))
      localStorage.setItem(IMPERSONATE_ALLOW_SUB_USERS, String(Number(response.data.allowSubUsers)))
      window.location.reload()
    })
    .catch(async () => {
      catchFn()
    })
}

export default impersonateUser
