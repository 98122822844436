import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react'
import { ApexOptions } from 'apexcharts'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { BarChart, LastYearTotalStatistics, PaymentsListFilters } from '../../../interfaces/interfaces'
import { STATUS_CANCELLED_ID, STATUS_COMPLETED_ID, STATUS_DENIED_ID, STATUS_EXPIRED_ID, STATUS_SENT_ID } from '../../../utils/constants'
import { formatEuroNumber, getMonthName } from '../../../utils/helper'
import './StatisticBarChart.css'

type ChartBarStyle = {
  style: React.CSSProperties & {
    '--statistic-bar-chart-width'?: string
    '--statistic-bar-chart-color'?: string
  }
}

export interface StatisticBarChartProps {
  totalStatistics: LastYearTotalStatistics
  barChartSeries: BarChart[]
  showStatistics: boolean
  statusFilter: [PaymentsListFilters, Function]
}

export const StatisticBarChart: React.FC<StatisticBarChartProps> = ({ totalStatistics, barChartSeries, showStatistics, statusFilter }) => {
  const { totalAmount, monthTotal } = totalStatistics
  const [lineData, setLineData] = useState<number[]>(Object.values(monthTotal))
  const [filters, setFilters] = statusFilter

  useEffect(() => {
    const statisticsBars: any = document.querySelectorAll('.statistics-bar')
    if (showStatistics) {
      const timerIds: any = Object.values(statisticsBars).map((bar: any, index: number) => {
        return setTimeout(() => {
          bar.classList.add('statistics-fill-bar-open')
          bar.classList.remove('statistics-fill-bar-close')
        }, index * 200)
      })

      return () => {
        timerIds.forEach((timerId: any) => clearTimeout(timerId))
      }
    } else {
      statisticsBars.forEach((bar: any) => {
        bar.classList.remove('statistics-fill-bar-open')
        bar.classList.add('statistics-fill-bar-close')
      })
    }
  }, [showStatistics])

  function formatMonthName(val: any, opts: any) {
    return getMonthName(Object.keys(monthTotal)[val - 1])
  }

  const [lineChartOptions, setLineChartOptions] = useState<ApexOptions>({
    theme: {
      mode: document.body.classList.contains('dark') ? 'dark' : 'light',
    },
    chart: {
      animations: {
        dynamicAnimation: {
          enabled: true,
          speed: 600
        }
      },
      type: 'line',
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 2,
        opacity: 0.2
      }
    },
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 1
    },
    colors: ['#00c800'],
    tooltip: {
      x: {
        formatter: (val, opts) => formatMonthName(val, opts)
      },
      y: {
        title: {
          formatter: function formatter(val: any) {
            return ''
          }
        },
        formatter: function formatter(val: any, opts: any) {
          return formatEuroNumber(val.toFixed(2))
        }
      }
    }
  })

  const lineChartSeries: ApexOptions['series'] = [
    {
      data: lineData
    }
  ]

  useEffect(() => {
    setLineChartOptions({
      ...lineChartOptions,
      tooltip: {
        x: {
          formatter: (val, opts) => formatMonthName(val, opts)
        },
      }
    })
    const timerId = setTimeout(() => {
      setLineData(Object.values(monthTotal))
    }, 300)

    return () => {
      clearTimeout(timerId)
    }
  }, [monthTotal, showStatistics])

  const total: number = barChartSeries.reduce((acc: number, curr: BarChart) => acc + curr.count, 0)
  const barChartList: BarChart[] = barChartSeries.map((obj: BarChart) => {
    const percentage = (obj.count / total) * 100
    return { ...obj, percentage }
  })

  function handleStatisticStatus(statusKey: string | null) {
    if (statusKey) {
      const newStatus = Object.keys(filters.status).reduce((acc: any, key) => {
        acc[key] = key === statusKey
        return acc
      }, {})
      setFilters({ ...filters, status: { ...newStatus } })

      const statisticsItemIconContainer: any = document.querySelector('.statistics-item-icon-container')
      if (statisticsItemIconContainer) {
        statisticsItemIconContainer.click()
      }
    }
  }

  function getFilterKeyByStatusId(statusId: number) {
    switch (statusId) {
      case STATUS_COMPLETED_ID:
        return 'completed'
      case STATUS_SENT_ID:
        return 'sent'
      case STATUS_EXPIRED_ID:
        return 'expire_payment'
      case STATUS_DENIED_ID:
        return 'deny'
      case STATUS_CANCELLED_ID:
        return 'cancelled'
      default:
        return null
    }
  }

  return (
    <IonGrid className="statistics-chart-bar-container">
      {barChartList.map((bar: any, index: number) => (
        <div
          key={index}
          className="statistics-chart-bar-row"
          style={
            {
              '--statistic-bar-chart-width': `${bar.percentage}%`,
              '--statistic-bar-chart-color': `${bar.fillColor}`
            } as ChartBarStyle['style']
          }
        >
          <IonRow>
            <IonCol className="statistics-chart-title">
              <IonLabel className="statistics-chart-title-color" />
              <IonLabel style={{ cursor: 'pointer' }} onClick={() => handleStatisticStatus(getFilterKeyByStatusId(bar.id))}>{bar.title}</IonLabel>
            </IonCol>
            <IonCol className="statistics-chart-bar">
              <div className="statistics-bar" style={{ backgroundColor: bar.fillColor }} />
            </IonCol>
            <IonCol size="3">
              <IonLabel>{bar.count}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonLabel>{formatEuroNumber(Number(bar.amount).toFixed(2))}</IonLabel>
            </IonCol>
          </IonRow>
        </div>
      ))}

      <IonRow>
        <IonCol size="4">{t('field.total_charged')}</IonCol>
        <IonCol size="8">{t('field.last_12_months')}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="4">
          <IonLabel className="statistics-total-amount">{formatEuroNumber(totalAmount.toFixed(2))}</IonLabel>
        </IonCol>
        <IonCol size="8">
          <ReactApexChart options={lineChartOptions} series={lineChartSeries} height={60} type="line" />
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default StatisticBarChart
