import { useQuery } from '@tanstack/react-query'
import { useState, useRef, useEffect } from 'react'

function useReactQuery<T>(queryKey: string, queryFn: Function) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const isMounted = useRef<boolean>(true)

  const { data } = useQuery<T>({
    // networkMode: 'always', // INFO to make queries without ethernet connection
    queryKey: [queryKey],
    queryFn: () => queryFn(),
    onSuccess: () => {
      const timeoutId: NodeJS.Timeout = setTimeout(() => {
        if (isMounted.current) {
          setIsLoading(false)
        }
      }, 1000)
      timeout.current = timeoutId
    }
  })

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
      isMounted.current = false
    }
  }, [])

  return { data, isLoading }
}

export default useReactQuery
