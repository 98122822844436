import './Store.css'
import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentStore, setEditingStore } from '../../../redux/actions'
import { buildOutline, checkmarkCircleOutline, chevronBack, pencilOutline, trashOutline } from 'ionicons/icons'
import { useState } from 'react'
import { DisableStoreModal } from '../DisableStoreModal/DisableStoreModal'
import axios from 'axios'
import { User } from '../../../interfaces/interfaces'
import useToast from '../../../hooks/useToast'
import { SubUsersList } from '../../common/SubUsersList/SubUsersList'

export const Store: React.FC = () => {
  const currentStore: User = useSelector((state: any) => state.stores.current)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [busy, setBusy] = useState<boolean>(false)
  const { toast } = useToast()

  const handleDismiss = () => {
    dispatch(setCurrentStore(null))
  }

  const editStore = () => {
    dispatch(setEditingStore(true))
  }

  const openModal = () => {
    setShowModal(true)
  }

  async function closeModal(disableStore: boolean = false) {
    setShowModal(false)
    if (disableStore) {
      setBusy(true)
      await axios({
        method: 'POST',
        headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
        url: `${process.env.REACT_APP_BACKEND_URL}/store/disable_store`,
        data: {
          uid: currentStore.uid,
          disable: !currentStore.deleted
        }
      })
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          setBusy(false)
          toast(t('error.generic'))
        })
    }
  }

  return (
    <IonPage className="details">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle>{currentStore.name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

        <IonItem>
          <IonLabel>{t('field.email')}</IonLabel>
          <IonChip>{currentStore.email}</IonChip>
        </IonItem>
        <IonItem>
          <IonLabel>{t('field.merchant_id')}</IonLabel>
          <IonChip>{currentStore.merchant_id}</IonChip>
        </IonItem>
        <IonItem>
          <IonLabel>{t('field.adquirer_bin')}</IonLabel>
          <IonChip>{currentStore.adquirer_bin}</IonChip>
        </IonItem>
        <IonItem>
          <IonLabel>{t('field.terminal_id')}</IonLabel>
          <IonChip>{currentStore.terminal_id}</IonChip>
        </IonItem>
        <IonItem>
          <IonLabel>{t('field.encryption_key')}</IonLabel>
          <IonChip>{currentStore.encryption_key}</IonChip>
        </IonItem>

        {currentStore.allowSubUsers ? (
          <div className='sub-users-info'>
            <IonItem>
              <IonLabel color="primary">{t('field.sub_users')}</IonLabel>
            </IonItem>
            <SubUsersList storeId={currentStore.id} />
          </div>
        ) : null}

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon icon={buildOutline} />
          </IonFabButton>
          <IonFabList side="start">
            <IonFabButton onClick={editStore} className="icon-success edit-store">
              <IonIcon icon={pencilOutline} />
            </IonFabButton>
            <IonFabButton onClick={openModal} className={`${currentStore.deleted ? 'icon-success' : 'icon-danger'} disable-store`} >
              <IonIcon icon={currentStore.deleted ? checkmarkCircleOutline : trashOutline} />
            </IonFabButton>
          </IonFabList>
        </IonFab>

        <IonModal isOpen={showModal} className="disable-store-modal" onDidDismiss={() => closeModal()}>
          <DisableStoreModal closeAction={closeModal} disable={!currentStore.deleted} />
        </IonModal>
      </IonContent>
    </IonPage>
  )
}

export default Store
