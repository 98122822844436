import { t } from 'i18next'
import { z } from 'zod'
import { PHONE_REGEX, PREFIX_REGEX, SHARE_METHODS } from './constants'

export const validateFavoriteUserForm = (data: any, showEmailInput: boolean) => {
  const { userName, email, prefix, phone, shareMethod } = data

  const formData: { [k: string]: any } = {
    userName,
    shareMethod
  }

  const formSchemaData: { [k: string]: any } = {
    userName: z.string().min(1, t('error.user_name_required')),
    shareMethod: z.enum(SHARE_METHODS)
  }

  if (showEmailInput) {
    formData.email = email
    formSchemaData.email = z.string().email(t('error.email_invalid'))
  } else {
    formData.prefix = prefix
    formSchemaData.prefix = z.string().regex(PREFIX_REGEX, t('error.prefix_validation'))
    formData.phone = phone
    formSchemaData.phone = z.string().regex(PHONE_REGEX, t('error.phone_validation'))
  }

  const formSchema = z.object(formSchemaData)
  try {
    formSchema.parse(formData)
    return { success: true, formData, message: '' }
  } catch (error) {
    const result = { success: false, formData, message: '' }
    if (error instanceof z.ZodError) result.message = error.issues[0].message
    return result
  }
}

export const validateMultiplePaymentForm = (data: any) => {
  const formSchemaData = {
    name: z.string().min(1, t('error.user_name_required')),
    email: z.union([z.string().email(t('error.email_invalid')), z.string().length(0)]),
    phone: z.union([z.string().min(5, t('error.phone_validation')).max(9, t('error.phone_validation')), z.string().length(0)])
  }

  const formSchema = z.object(formSchemaData)
  try {
    formSchema.parse(data)
    return { success: true, message: '' }
  } catch (error) {
    const result = { success: false, message: '' }
    if (error instanceof z.ZodError) result.message = error.issues[0].message
    return result
  }
}
