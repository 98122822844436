import React from 'react'
import { IonInput, IonItem, IonLabel } from '@ionic/react'
import { useTranslation } from 'react-i18next'

export const PhoneInput: React.FC<{
  prefix: React.MutableRefObject<string>
  phone: React.MutableRefObject<string>
}> = ({ prefix, phone }) => {
  const { t } = useTranslation()

  return (
    <>
      <IonItem>
        <IonLabel position="stacked">{t('field.prefix')}</IonLabel>
        <IonInput
          required
          name="prefix"
          inputMode="decimal"
          type="number"
          step="any"
          onInput={(e: any) => {
            prefix.current = e.target.value
          }}
          value={prefix.current}
        />
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">{t('field.phone')}</IonLabel>
        <IonInput
          required
          name="phone"
          inputMode="decimal"
          type="number"
          step="any"
          onInput={(e: any) => {
            phone.current = e.target.value
          }}
          value={phone.current}
        />
      </IonItem>
    </>
  )
}

export default PhoneInput
