import {
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonThumbnail,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { add, person } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FavoriteUser } from '../../../interfaces/interfaces'
import { setCreatingFavoriteUser, setCurrentFavoriteUser } from '../../../redux/actions'
import SkeletonItems from '../../common/SkeletonItems/SkeletonItems'
import getSendMethodIcon from '../../../utils/getSendMethodIcon'
import React from 'react'
import useReactQuery from '../../../hooks/useReactQuery'

interface FavoritesListProps {
  getFavoriteUsers: Function
}

export const FavoritesList: React.FC<FavoritesListProps> = ({ getFavoriteUsers }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { data, isLoading } = useReactQuery<FavoriteUser[]>('favoriteUsers', getFavoriteUsers)

  const addFavoriteUser = () => {
    dispatch(setCreatingFavoriteUser(true))
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{t('favorites')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data?.length ? (
          data.map((favoriteUser) => (
            <IonItem
              key={favoriteUser.id}
              onClick={() => {
                dispatch(setCurrentFavoriteUser(favoriteUser))
              }}
              className="list-item"
            >
              <IonThumbnail slot="start">
                <IonIcon className="item-icon" icon={person} />
              </IonThumbnail>
              <IonLabel>
                <h2>
                  <b>{favoriteUser.userName}</b>
                </h2>
                <p>{favoriteUser.email || favoriteUser.phone}</p>
              </IonLabel>
              <IonThumbnail slot="end">
                <IonIcon icon={getSendMethodIcon(favoriteUser.shareMethod.toString())} />
              </IonThumbnail>
            </IonItem>
          ))
        ) : isLoading ? (
          <SkeletonItems items={5} />
        ) : (
          <div>
            <div className="login-logo-container">
              <IonImg className="ion-padding login-logo" src="/assets/img/logo-blue.png" />
            </div>
            <p className="text-center no-favorite-users-text">{t('message.no_favorite_users')}</p>
          </div>
        )}

        <IonFab onClick={addFavoriteUser} horizontal="end" vertical="bottom" slot="fixed" className='bounce-show'>
          <IonFabButton>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  )
}

export default FavoritesList
