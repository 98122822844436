import './InputPassword.css'
import React, { useState } from 'react'
import { IonIcon, IonInput } from '@ionic/react'
import { eye, eyeOff } from 'ionicons/icons'

const InputPassword: React.FC<any> = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const inputType = showPassword ? 'text' : 'password'

  return (
    <div className="input-password">
      <IonInput type={inputType} {...props} />
      <button type="button" className="password-toggle" onClick={toggleShowPassword}>
        <IonIcon icon={showPassword ? eye : eyeOff} />
      </button>
    </div>
  )
}

export default InputPassword
