import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { t } from 'i18next'
import PropTypes from 'prop-types'

interface ModalProps {
  closeAction: Function
  disable: Boolean
}

export const DisableStoreModal: React.FC<ModalProps> = ({ closeAction, disable }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{disable ? t('action.disable_store') : t('action.enable_store')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => closeAction()}>
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="modal-content-container">
          <IonLabel>
            {disable ? t('message.disable_store_confirmation') : t('message.enable_store_confirmation')}
          </IonLabel>
          <div className="buttons-container">
            <IonButton onClick={() => closeAction(true)}>{t('action.accept')}</IonButton>
            <IonButton onClick={() => closeAction()}>{t('action.cancel')}</IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

DisableStoreModal.propTypes = {
  closeAction: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired
}

export default DisableStoreModal
