import './MultiplePaymentsList.css'
import React from 'react'
import {
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonThumbnail,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { readerOutline, search } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useReactQuery from '../../../hooks/useReactQuery'
import { MultiplePayment, User } from '../../../interfaces/interfaces'
import { setCurrentMultiplePayment, setDescriptionFilter } from '../../../redux/actions'
import SkeletonItems from '../../common/SkeletonItems/SkeletonItems'
import axios from 'axios'
import { IMPERSONATE_DATA, URL_GET_MULTIPLE_PAYMENTS } from '../../../utils/constants'
import moment from 'moment'
import { formatEuroNumber, isDeletedOrExpired } from '../../../utils/helper'

export const MultiplePaymentsList: React.FC<{ currentUser: User | null }> = ({ currentUser }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  function searchPayments(description: string) {
    dispatch(setDescriptionFilter(description))
    document.querySelector<HTMLIonTabButtonElement>('ion-tab-button[tab="payments"]')?.click()
  }

  async function getMultiplePayments() {
    return await axios({
      method: 'GET',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: URL_GET_MULTIPLE_PAYMENTS
    })
      .then((response) => {
        return response.data.length ? response.data : []
      })
      .catch(() => {
        return null
      })
  }

  const { data, isLoading } = useReactQuery<MultiplePayment[]>('multiplePayments', getMultiplePayments)

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{t('multiple_payments')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data?.length ? (
          data.map((multiplePayment) => (
            <IonItem className="mapped-item" key={multiplePayment.id}>
              <IonItem
                onClick={() => {
                  localStorage.setItem(IMPERSONATE_DATA, JSON.stringify({ ...multiplePayment, isExpired: moment(multiplePayment.expiration, 'YYYY-MM-DD').isBefore(moment(), 'day')}))
                  dispatch(setCurrentMultiplePayment(multiplePayment))
                }}
                lines="none"
                className={`mapped-item-inner list-item ${isDeletedOrExpired(multiplePayment) ? 'item-disabled' : ''}`}
              >
                <IonThumbnail slot="start">
                  <IonIcon className="item-icon" icon={readerOutline} />
                </IonThumbnail>
                <IonLabel>
                  <h2>
                    <b>{multiplePayment.description}</b>
                  </h2>
                  {multiplePayment.expiration !== '0000-00-00' && <p>{multiplePayment.expiration}</p>}
                </IonLabel>
                <div className='item-chips'>
                  {multiplePayment?.User?.parentStoreId && currentUser?.identifier !== multiplePayment.User.identifier && (
                    <IonChip color="warning">
                      <b>{multiplePayment.User.identifier}</b>
                    </IonChip>
                  )}
                  <IonChip color="primary">
                    <b>{formatEuroNumber(multiplePayment.amount)}</b>
                  </IonChip>
                </div>
              </IonItem>
              <button className='reset-button list-item-icon-button' onClick={() => searchPayments(multiplePayment.description)}>
                <IonIcon className="list-item-icon" color="primary" icon={search} />
              </button>
            </IonItem>
          ))
        ) : isLoading ? (
          <SkeletonItems items={5} />
        ) : (
          <div>
            <div className="login-logo-container">
              <IonImg className="ion-padding login-logo" src="/assets/img/logo-blue.png" />
            </div>
            <p className="text-center no-multiple-payments-text">{t('message.no_multiple_payments')}</p>
          </div>
        )}
      </IonContent>
    </IonPage>
  )
}

export default MultiplePaymentsList
