import { useEffect, useState } from 'react'
import { IonToast } from '@ionic/react'
import ReactDOM from 'react-dom'

let toastContainer: HTMLDivElement | null = null

function useToast() {
  const [showToast, setShowToast] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')
  const [toastDuration, setToastDuration] = useState<number>()
  const [toastId, setToastId] = useState<number>()

  useEffect(() => {
    toastContainer = document.createElement('div')
    document.body.appendChild(toastContainer)
    return () => {
      if (document.body.contains(toastContainer)) document.body.removeChild(toastContainer as HTMLDivElement)
    }
  }, [])

  function toast(message: string, duration: number = 4000) {
    setToastMessage(message)
    setToastDuration(duration)
    setToastId(Date.now())
    setShowToast(true)
  }

  useEffect(() => {
    if (toastContainer) {
      if (showToast) {
        ReactDOM.render(
          <IonToast
            key={toastId}
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            duration={toastDuration}
            htmlAttributes={{ tabindex: undefined }}
          />,
          toastContainer
        )
      }
    }
  }, [showToast, toastMessage, toastId])

  return { toast }
}

export default useToast
