import { useTranslation } from 'react-i18next'
import './CookiesModal.css'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonImg,
  IonItemDivider
} from '@ionic/react'
import { getCookie, setCookie } from '../../../utils/helper'
import { COOKIE_EXPIRATION_DAYS, COOKIE_NOTICE_ACCEPTED } from '../../../utils/constants'
import { useState } from 'react'
import { arrowBack } from 'ionicons/icons'

export const CookiesModal: React.FC = () => {
  const cookieValue = getCookie(COOKIE_NOTICE_ACCEPTED)
  if (cookieValue) return null

  const { t } = useTranslation()
  const [showCookiesManagement, setShowCookiesManagement] = useState(false)
  const [showCookiesManagementList, setShowCookiesManagementList] = useState(false)

  function acceptCookies() {
    setCookie(COOKIE_NOTICE_ACCEPTED, 'true', COOKIE_EXPIRATION_DAYS)
    window.location.reload()
  }

  const CookiesManagementList = () => {
    return (
      <div className="cookies-modal-container cookies-management">
        <IonCard>
          <IonCardHeader id="cm-header">
            <IonButton className="go-back" fill="default" onClick={() => setShowCookiesManagementList(false)}>
              <IonIcon icon={arrowBack} />
              <span className="">{t('action.go_back')}</span>
            </IonButton>
            <IonImg className="header-logo" src="/assets/img/logo-lettering.png" />
          </IonCardHeader>

          <IonItemDivider className="cm-divider" />

          <IonCardContent className="cm-body">
            <ul>
              <li>
                <b>{t('cookies.management.technical_cookies.auth_token')}</b>
                {': '}
                {t('cookies.management.technical_cookies.auth_token_info')}
              </li>
              <li>
                <b>{t('cookies.management.technical_cookies.last_active')}</b>
                {': '}
                {t('cookies.management.technical_cookies.last_active_info')}
              </li>
              <li>
                <b>{t('cookies.management.technical_cookies.recaptcha')}</b>
                {': '}
                {t('cookies.management.technical_cookies.recaptcha_info')}
              </li>
              <li>
                <b>{t('cookies.management.technical_cookies.cookie_notice_accepted')}</b>
                {': '}
                {t('cookies.management.technical_cookies.cookie_notice_accepted_info')}
              </li>
            </ul>
          </IonCardContent>
        </IonCard>
      </div>
    )
  }

  const CookiesManagement = () => {
    return (
      <div className="cookies-modal-container cookies-management">
        <IonCard>
          <IonCardHeader id="cm-header">
            <IonButton className="go-back" fill="default" onClick={() => setShowCookiesManagement(false)}>
              <IonIcon icon={arrowBack} />
              <span className="">{t('action.go_back')}</span>
            </IonButton>
            <IonImg className="header-logo" src="/assets/img/logo-lettering.png" />
          </IonCardHeader>

          <IonItemDivider className="cm-divider" />

          <IonCardContent className="cm-body">
            <IonCardTitle id="cm-body-title">{t('cookies.management.your_privacity')}</IonCardTitle>
            <div>
              {t('cookies.management.info1')}
              <br />
              <br />
              {t('cookies.management.info2')}
              <br />
              <br />
              <br />
              <a
                href="/docs/cookies-policy.pdf"
                aria-label={t('cookies.privacy_policy_aria_label')}
                rel="noreferrer"
                target="_blank"
              >
                {t('more_info')}
              </a>
            </div>
          </IonCardContent>

          <br />

          <IonCardContent className="cm-body">
            <div className="technical_cookies">
              <h2>{t('cookies.management.technical_cookies.title')}</h2>
              <p>{t('cookies.management.technical_cookies.info')}</p>
              <div>
                <button
                  className="cookie-list"
                  type="button"
                  onClick={() => setShowCookiesManagementList(true)}
                  aria-label={t('cookies.management.used_cookies_aria_label')}
                >
                  {t('cookies.management.used_cookies')}
                </button>
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      </div>
    )
  }

  const CookieModalMain = () => {
    return (
      <div className="cookies-modal-container">
        <IonCard>
          <IonCardHeader>
            <IonCardTitle className="cm-title">{t('cookies.title')}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="cm-body">
            <p>
              {t('cookies.body')}
              <a
                href="/docs/cookies-policy.pdf"
                aria-label={t('cookies.cookies_policy_aria_label')}
                rel="noreferrer"
                target="_blank"
              >
                {t('cookies.cookies_policy1')}
              </a>
            </p>
            <br />
            <p>
              {t('cookies.cookies_policy2')}
            </p>
          </IonCardContent>
          <br />
          <div className="cookies-modal-footer">
            <button id="cookie-config" type="button" onClick={() => setShowCookiesManagement(true)}>
              {t('cookies.cookie_config')}
            </button>
            <button id="accept-all" type="button" onClick={acceptCookies}>
              {t('cookies.accept_all')}
            </button>
          </div>
        </IonCard>
      </div>
    )
  }

  if (showCookiesManagementList) return <CookiesManagementList />
  else if (showCookiesManagement) return <CookiesManagement />
  else return <CookieModalMain />
}

export default CookiesModal
