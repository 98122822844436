import { IonItem, IonLabel, IonList, IonSkeletonText, IonThumbnail } from '@ionic/react'
import PropTypes from 'prop-types'

interface SkeletonItemsProps {
  items?: number
  radio?: boolean
}

export const SkeletonItems: React.FC<SkeletonItemsProps> = ({ items = 10, radio = false }) => {
  return (
    <IonList className='skeleton-items-list'>
      {[...Array(items)].map((e, i) =>
        radio
          ? (
            <IonItem key={i} className="skeleton-item">
              <IonLabel>
                <h3>
                  <IonSkeletonText animated style={{ width: '50%' }} />
                </h3>
                <p>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                </p>
              </IonLabel>
              <IonThumbnail slot="end" style={{ width: '20px', height: '20px', marginRight: '1.25rem' }}>
                <IonSkeletonText animated />
              </IonThumbnail>
            </IonItem>
          )
          : (
            <IonItem key={i} className="skeleton-item">
              <IonThumbnail slot="start">
                <IonSkeletonText animated />
              </IonThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText animated style={{ width: '50%' }} />
                </h3>
                <p>
                  <IonSkeletonText animated style={{ width: '80%' }} />
                </p>
                <p>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                </p>
              </IonLabel>
            </IonItem>
          )
      )}
    </IonList>
  )
}

SkeletonItems.propTypes = {
  items: PropTypes.number,
  radio: PropTypes.bool
}

export default SkeletonItems
