import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react'
import { useSelector } from 'react-redux'
import UserProfile from '../../components/profile/UserProfile/UserProfile'
import EditProfile from '../../components/profile/EditProfile/EditProfile'
import SubUserForm from '../../components/profile/SubUserForm/SubUserForm'

export const Profile: React.FC = () => {
  const editingProfile: boolean = useSelector((state: any) => state.profile.editing)
  const creatingSubUser: boolean = useSelector((state: any) => state.profile.creatingSubUser)
  const currentSubUser: boolean = useSelector((state: any) => state.profile.currentSubUser)
  const subUserForm: boolean = creatingSubUser || currentSubUser
  const showUserProfile = !editingProfile && !subUserForm

  useIonViewDidEnter(() => { // TODO refactor this
    document.querySelector('.profile-page')?.classList.remove('ion-page-hidden', 'ion-page-invisible')
    document.querySelector('.profile-page .ion-page')?.classList.remove('ion-page-hidden', 'ion-page-invisible')
  })

  return (
    <IonPage className='profile-page'>
      <IonContent>
        {showUserProfile && <UserProfile />}
        {editingProfile && <EditProfile />}
        {subUserForm && <SubUserForm />}
      </IonContent>
    </IonPage>
  )
}

export default Profile
