import { t } from 'i18next'
import useToast from './useToast'

function useCopyToClipboard() {
  const { toast } = useToast()

  function copyToClipboard(title: string, message?: string) {
    navigator.clipboard.writeText(`${title}${message ? `\n${message}` : ''}`)
    toast(t('message.copied_to_clipboard'))
  }

  return { copyToClipboard }
}

export default useCopyToClipboard
