import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  es: {
    translation: {
      all: 'Todos',
      app_name: 'CobroFácil',
      tpv_name: 'Cecabank',
      admin: 'Administrador',
      admins: 'Administradores',
      confirm_password: 'Confirmar contraseña',
      credential: 'Credencial',
      email: 'Correo electrónico',
      favorites: 'Favoritos',
      login: 'Iniciar sesión',
      logout: 'Cerrar sesión',
      merchant_id: 'Número de comercio',
      merchant_id_or_email: 'Número de comercio o correo electrónico',
      multiple_payments: 'Pagos múltiples',
      password: 'Contraseña',
      payments: 'Pagos',
      profile: 'Perfil',
      register: 'Regístrate',
      search: 'Buscar',
      statistics: 'Informe de operaciones',
      store: 'Comercio',
      stores: 'Comercios',
      filters: 'Filtros',
      more_info: 'Más información',
      recaptcha_message: 'Este sitio web está protegido por reCAPTCHA y la aplicación de la <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer" className="recaptcha-message-link">Política de Privacidad</a> y los <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer" className="recaptcha-message-link">Términos y condiciones</a> de Google.',
      action: {
        accept: 'Aceptar',
        add: 'Añadir',
        cancel: 'Cancelar',
        clone: 'Clonar',
        create_admin: 'Crear administrador',
        create_payment: 'Crear pago',
        create_store: 'Crear comercio',
        create_sub_user: 'Crear sub-usuario',
        delete: 'Borrar',
        delete_favorite_user: 'Borrar usuario frecuente',
        delete_multiple_payment: 'Borrar plantilla de pago múltiple',
        disable: 'Deshabilitar',
        disable_admin: 'Deshabilitar administrador',
        disable_store: 'Deshabilitar comercio',
        disable_sub_user: 'Deshabilitar sub-usuario',
        edit: 'Editar',
        enable: 'Habilitar',
        enable_admin: 'Habilitar administrador',
        enable_store: 'Habilitar comercio',
        enable_sub_user: 'Habilitar sub-usuario',
        copy_to_clipboard: 'Copiar al portapapeles',
        go_back: 'Volver',
        go_to_login: 'Ir a inicio',
        install: 'Instalar App',
        logout: 'Salir',
        recover: 'Restablecer',
        reset_filters: 'Borrar filtros',
        reset_password: 'Restablecer contraseña',
        save_changes: 'Guardar cambios',
        save_favorite_user: 'Guardar favorito',
        send: 'Enviar',
        send_whatsapp: 'Enviar pago por WhatsApp',
        share: 'Compartir',
        update_password: 'Actualizar contraseña',
        whatsapp_wo_contact: 'WhatsApp sin contacto'
      },
      error: {
        admin_email_not_allowed: 'El email introducido no es válido',
        amount_required: 'Introduzca una cantidad por favor',
        adquirer_bin_required: 'Introduzca un número de Adquirer BIN por favor',
        credentials_required: 'Los campos de credencial y contraseña son obligatorios',
        creating_multiple_payment: 'Error al generar la plantilla de pago múltiple',
        creating_payment: 'Error al generar el pago',
        creating_payment_without_token: 'El comercio no tiene habilitada la opción de generar pagos sin token, ponte en contacto con tu entidad',
        confirm_password_mismatch: 'Los campos de nueva contraseña y confirmar nueva contraseña no coinciden',
        current_password_mismatch: 'La contraseña actual no es correcta',
        description_required: 'Introduzca una descripción por favor',
        encryption_key_required: 'Introduzca una clave de encriptación por favor',
        email_already_exists: 'El email introducido ya está en uso',
        email_and_merchant_id_duplicated: 'El email y el número de comercio introducido ya está en uso',
        email_invalid: 'El formato de email introducido no es válido',
        email_not_found: 'No existe una cuenta registrada con el email introducido',
        email_not_verified_header: 'La petición para verificar tu correo electrónico ha caducado o ya se ha usado el enlace',
        email_not_verified: 'Genere un nuevo enlace de verificación, por favor',
        email_required: 'Introduzca un email por favor',
        favorite_already_exists: 'El usuario favorito introducido ya existe',
        fill_identifier_field: 'Debe rellenar el campo de identificador',
        fill_name_field: 'Debe rellenar el campo de nombre',
        generic: 'Algo salió mal, inténtelo de nuevo más tarde',
        identifier_already_exists: 'El identificador introducido ya está en uso',
        identifier_validation: 'El identificador debe contener al menos 1 letra y no puede contener espacios en blanco',
        invalid_expiration_date: 'La fecha de caducidad no puede ser inferior a hoy',
        login: 'Error intentando acceder con sus credenciales',
        too_many_requests: 'El acceso a esta cuenta ha sido temporalmente inhabilitado debido a varios intentos fallidos de inicio de sesión. Puede restablecer su contraseña o puede volver a intentarlo más tarde',
        merchant_id_duplicated: 'El número de comercio introducido ya está en uso',
        merchant_id_validation: 'El número de comercio debe tener 9 dígitos',
        merchant_id_already_in_use: 'El número de comercio introducido ya está en uso',
        merchant_id_required: 'Introduzca un número de comercio por favor',
        merchant_id_not_found: 'No existe un usuario registrado con el número de comercio o correo electrónico introducido',
        network_failed: 'Necesita estar conectado a internet',
        password_mismatch: 'Las contraseñas no coinciden',
        password_validation: 'La contraseña debe tener 8 caracteres, incluyendo al menos una letra y un número',
        phone_validation: 'El número introducido no es válido',
        prefix_validation: 'El prefijo introducido no es válido',
        reset_password_expired: 'Ha ocurrido un error. Vuelva a intentarlo más tarde.',
        send_statistics_email: 'Error al enviar el informe de operaciones',
        send_stores_list_email: 'Error al enviar la lista de comercios',
        terminal_id_required: 'Introduzca un número de Terminal ID por favor',
        token_needed: 'No se puede realizar un pago fácil sin generar un token',
        user_name_required: 'El campo nombre es obligatorio'
      },
      field: {
        adquirer_bin: 'Adquirer BIN',
        amount: 'Cantidad',
        allow_sub_users: 'Permitir sub-usuarios',
        cancelled: 'Cancelado',
        client_info: 'Información del cliente',
        confirm_new_password: 'Confirmar nueva contraseña',
        creation_date: 'Fecha de creación',
        current_password: 'Contraseña actual',
        dark_mode: 'Modo oscuro',
        date: 'Fecha',
        description: 'Descripción',
        email: 'Correo electrónico',
        email_notifications: 'Recibir notificaciones por correo electrónico',
        email_payment_expiration: 'Pagos a 2 días de vencer',
        email_payment_status: 'Cambio de estado de un pago',
        encryption_key: 'Clave de encriptación',
        expiration: 'Fecha de caducidad',
        identifier: 'Identificador',
        last_12_months: 'Últimos 12 meses',
        merchant_id: 'Merchant ID',
        multiple_payment: 'Pago múltiple',
        name: 'Nombre',
        new_password: 'Nueva contraseña',
        num_operation: 'Número de operación',
        operations_performed: 'Operaciones realizadas',
        phone: 'Número de teléfono',
        prefix: 'Prefijo',
        status: 'Estado',
        send_token: 'Enviar token',
        share_method: 'Método de envío',
        share_methods: {
          sms: 'Mensaje de texto',
          email: 'Correo electrónico',
          telegram: 'Telegram',
          whatsapp: 'WhatsApp'
        },
        sub_users: 'Sub-usuarios',
        terminal_id: 'Terminal ID',
        top_10_stores_by_num_op: 'Top 10 clientes por número de operaciones',
        top_10_stores_by_amount: 'Top 10 clientes por total cobrado',
        total_charged: 'Total cobrado',
        token: 'Token',
        url: 'URL'
      },
      impersonate: {
        data_saved: '[Admin] Registro guardado satisfactoriamente',
        data_removed: '[Admin] Registro borrado satisfactoriamente',
        data_disabled: '[Admin] Registro deshabilitado satisfactoriamente',
        data_enabled: '[Admin] Registro habilitado satisfactoriamente'
      },
      message: {
        active: 'Activo',
        already_have_account: '¿Ya estás registrado?',
        copied_to_clipboard: 'Copiado en el portapapeles',
        delete_favorite_user_confirmation: '¿Seguro que desea borrar este usuario frecuente?',
        delete_multiple_payment_confirmation: '¿Seguro que desea borrar esta plantilla de pagos múltiples?',
        disable_admin_confirmation: '¿Seguro que desea deshabilitar este administrador?',
        disable_store_confirmation: '¿Seguro que desea deshabilitar este comercio?',
        disable_sub_user_confirmation: '¿Seguro que desea deshabilitar este sub-usuario?',
        dont_have_account: '¿No tienes una cuenta?',
        email_verified_header: 'Se ha verificado tu correo electrónico',
        email_verified: 'Ya puedes iniciar sesión con la cuenta nueva',
        enable_admin_confirmation: '¿Seguro que desea habilitar este administrador?',
        enable_store_confirmation: '¿Seguro que desea habilitar este comercio?',
        enable_sub_user_confirmation: '¿Seguro que desea habilitar este sub-usuario?',
        expiration_date_default_warning: 'Si no se selecciona una fecha de caducidad, el pago caducará en 2 meses ({{ date }})',
        expiration_date_warning: 'Si seleccionas una fecha de caducidad inferior a dos días a partir de hoy no se notificará vía email',
        fill_number: 'Introduce el número de teléfono al que quieres enviar el pago por WhatsApp',
        forgot_password: '¿Has olvidado tu contraseña?',
        from: 'Desde',
        generate_payment_info: '{{ store }} ha generado este enlace para que puedas pagar tu "{{ description }}"',
        generate_payment_info2: 'Por favor, introduce la siguiente información para que {{ store }} pueda identificarte',
        go_pay: 'Ir a pagar',
        inactive: 'Inactivo',
        invalid_url: 'La url a la que se quiere acceder no es válida',
        loading_more_payments: 'Cargando más pagos...',
        multiple_payment_deleted: 'El enlace al que has accedido no está disponible',
        multiple_payment_expired: 'El enlace al que has accedido ha expirado',
        no: 'No',
        no_favorite_users: 'No tiene usuarios frecuentes guardados',
        no_payments: 'No se han encontrado pagos\nArrastre hacia abajo para actualizar',
        no_notifications: 'No tiene notificaciones',
        no_more_payments: 'No se han encontrado más pagos',
        no_multiple_payments: 'No se han encontrado plantillas de pagos múltiples',
        no_payments_found: 'No se han encontrado pagos con los filtros seleccionados',
        no_token_needed: 'La operación no requiere token',
        no_users_found: 'No se han encontrado usuarios',
        password_updated: 'Contraseña actualizada',
        payment_share_text: 'Descripción: {{desc}}\nCantidad: {{amount}}\nFecha de caducidad: {{expiration}}\nToken: {{token}}\nURL: {{url}}',
        payment_share_title: 'Se ha solicitado un pago desde {{from}}',
        payment_without_url: 'Este pago ha sido generado en la aplicación de Cecabank, por favor, consulte allí los detalles',
        please_copy_token: 'Se ha generado el pago correctamente. Por favor, copia el token para proceder a realizar el pago.',
        please_wait: 'Por favor espere...',
        registered: 'Usuario registrado. Recibirás un email para confirmar tu dirección de correo.',
        resend_verification_email: 'Reenviar correo de verificación',
        send_statistics_email: 'Se ha enviado un correo con el informe de operaciones',
        send_stores_list_email: 'Se ha enviado un correo con la lista de comercios',
        to: 'Hasta',
        token_will_be_generated: 'Se generará un token para esta operación',
        verification_required: 'El usuario {{email}} no está verificado. Por favor compruebe su correo y verifique su cuenta.',
        check_inbox: 'Por favor, compruebe la bandeja de entrada de su correo',
        welcome_generate_payment: 'Bienvenido a Cobro Fácil de ABANCA',
        yes: 'Sí'
      },
      status: {
        just_created: 'Recién creado',
        sent: 'Enviado',
        expire_payment: 'Pago caducado',
        cancelled: 'Cancelado',
        denied: 'Denegado',
        op_denied: 'Operación denegada',
        completed: 'Completado'
      },
      title: {
        edit_admin: 'Editar administrador',
        edit_favorite_user: 'Editar favorito',
        edit_profile: 'Editar perfil',
        edit_store: 'Editar comercio',
        edit_sub_user: 'Editar sub-usuario',
        favorite_users: 'Usuarios frecuentes',
        new_admin: 'Nuevo administrador',
        new_payment: 'Nuevo pago',
        new_store: 'Nuevo comercio',
        new_sub_user: 'Nuevo sub-usuario',
        new_favorite_user: 'Nuevo favorito',
        new_user: 'Nuevo usuario',
        notification: 'Notificación',
        op_status: 'Estado de la operación',
        op_source: 'Origen de la operación'
      },
      cookies: {
        cookies_policy: 'Política de Cookies',
        title: 'Aviso de Cookies',
        body: 'Cobro Fácil utiliza cookies propias y de terceros tanto para mejorar tu experiencia de usuario como para mostrarte contenidos comerciales adaptados a tus intereses mediante el análisis de tus hábitos de navegación, tal y como te explicamos a continuación, en la presente ',
        cookies_policy1: 'Política de Cookies.',
        cookies_policy_aria_label: 'Más información sobre su privacidad, se abre en una nueva pestaña',
        cookies_policy2: 'Esta Política puede ser actualizada por diversas razones; por ejemplo, debido a nuevas exigencias legislativas, instrucciones dictadas por la autoridad de control competente o cuando exista alguna variación en el funcionamiento de las cookies empleadas por la App. Por este motivo, te invitamos a revisar la citada Política de forma regular.',
        denied_all: 'Rechazarlas todas',
        cookie_config: 'Más información',
        accept_all: 'Aceptar cookies',
        management: {
          your_privacity: 'Tu privacidad',
          info1: 'Las páginas web que visitas pueden almacenar o recuperar información de tu navegador, principalmente en forma de cookies. Esta información puede tratar sobre ti, tus preferencias o tu dispositivo y se utiliza principalmente para lograr el adecuado funcionamiento de dichas páginas. Generalmente, esta información no te identifica de forma directa, pero puede brindarte una experiencia web más personalizada. Todas las cookies de esta App aparecen activadas por defecto ya que solo se utilizan cookies técnicas, como te indicamos en el apartado correspondiente, son necesarias y no se pueden desactivar de nuestros sistemas.',
          info2: 'Al pulsar el botón de "Aceptar cookies", se guardará la selección de cookies técnicas y podrá navegar por la app.',
          permit_all: 'Permitirlas todas',
          used_cookies: 'Cookies utilizadas',
          used_cookies_aria_label: 'El botón Cookies utilizadas abre el menú Lista de cookies',
          technical_cookies: {
            title: 'Cookies técnicas',
            info: 'Estas cookies son las estrictamente necesarias para que la App funcione correctamente, motivo por el que no pueden ser desactivadas en nuestros sistemas. Esta tipología de cookies sólo se configura en respuesta a determinadas acciones que realices (p.ej., establecer tus preferencias de privacidad, iniciar sesión, permitir el acceso a áreas restringidas o completar formularios) o para ejecutar elementos de seguridad durante la navegación. Puedes configurar tu navegador para bloquear o alertar de la presencia de este tipo de cookies pero algunas áreas de la App no funcionarán correctamente. Mediante el uso de estas cookies no se recaban ni se almacenan datos de carácter personal.',
            always_on: 'Activas siempre',
            auth_token: 'AUTH_TOKEN',
            auth_token_info: 'Cookie propia. Funcionalidad: usada para controlar si el usuario tiene una sesión iniciada en la aplicación. Duración: hasta que el usuario cierre sesión.',
            last_active: 'LAST_ACTIVE',
            last_active_info: 'Cookie propia. Funcionalidad: usada para detectar la última vez que el usuario estuvo activo. Si el usuario lleva 30 minutos sin interactuar con la aplicación, se le cerrará sesión automáticamente. Duración: indefinida.',
            recaptcha: '_GRECAPTCHA',
            recaptcha_info: 'Cookie de terceros (Google). Funcionalidad: utilizada por Google reCATPCHA con el fin de proporcionar seguridad frente a spam y abusos. Duración: cookie persistente (6 meses). Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros identificados en esta política de cookies en sus correspondientes políticas (https://policies.google.com/privacy).',
            cookie_notice_accepted: 'Cookie_notice_accepted',
            cookie_notice_accepted_info: 'Cookie propia. Funcionalidad: usada para controlar si el usuario ha aceptado la utilización de cookies. Duración: 15 días.'
          }
        }
      }
    }
  },
  en: {
    translation: {
      stores: 'Stores',
      payments: 'Payments',
      profile: 'Profile',
      login: 'Login',
      logout: 'Logout',
      register: 'Register',
      email: 'Email',
      password: 'Password'
    }
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'es', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
