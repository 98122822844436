import {
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonThumbnail
} from '@ionic/react'
import { t } from 'i18next'
import { add, settings } from 'ionicons/icons'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentAdmin, setAdminsList, setCreatingAdmin } from '../../../redux/actions'
import SkeletonItems from '../../common/SkeletonItems/SkeletonItems'
import ToolbarSearch from '../../common/ToolbarSearch/ToolbarSearch'
import useReactQuery from '../../../hooks/useReactQuery'
import axios from 'axios'
import { User } from '../../../interfaces/interfaces'
import { URL_GET_ADMIN_USERS } from '../../../utils/constants'

export const AdminsList: React.FC = () => {
  const dispatch = useDispatch()

  const getAdminUsers = async (): Promise<User[]> => {
    return await axios({
      method: 'GET',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: URL_GET_ADMIN_USERS
    })
      .then((response) => {
        return response.data.length ? response.data : []
      })
      .catch(() => {
        return null
      })
  }

  const { data, isLoading } = useReactQuery<User[]>('allAdminUsers', getAdminUsers)

  const addAdmin = () => {
    dispatch(setCreatingAdmin(true))
  }

  useEffect(() => {
    if (data) {
      dispatch(setAdminsList(data))
    }
  }, [data])

  const searchItem = (event: any) => {
    const searchText = event.detail.value.toLowerCase()
    const admins = document.querySelectorAll<HTMLElement>('.list-item')
    admins.forEach((element) => {
      element?.dataset?.name?.toLowerCase().includes(searchText) || element?.dataset?.email?.toLowerCase().includes(searchText) ? element.classList.remove('hidden') : element.classList.add('hidden')
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <ToolbarSearch title={t('admins')} searchItem={searchItem} />
      </IonHeader>
      <IonContent>
        {data?.length ? (
          data.map((user: User) => (
            <IonItem
              key={user.id}
              data-name={`${user.name}`}
              data-email={`${user.email}`}
              onClick={() => { dispatch(setCurrentAdmin(user)) }}
              className={`mapped-item list-item ${user.deleted ? 'item-disabled' : ''}`}
            >
              <IonThumbnail slot='start'>
                <IonIcon className="item-icon" icon={settings} />
              </IonThumbnail>
              <IonLabel>
                <h2><b>{user.name}</b></h2>
                <p>{user.email}</p>
              </IonLabel>
            </IonItem>
          ))
        ) : isLoading ? (
          <SkeletonItems items={5} />
        ) : (
          <div>
            <div className="login-logo-container">
              <IonImg className="ion-padding login-logo" src="/assets/img/logo-blue.png" />
            </div>
            <p className="text-center no-admin-users-text">{t('message.no_users_found')}</p>
          </div>
        )}

        <IonFab onClick={addAdmin} horizontal="end" vertical="bottom" slot="fixed" className='bounce-show'>
          <IonFabButton>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  )
}

export default AdminsList
