import './Admin.css'
import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentAdmin, setEditingAdmin } from '../../../redux/actions'
import { buildOutline, checkmarkCircleOutline, chevronBack, pencilOutline, trashOutline } from 'ionicons/icons'
import { useState } from 'react'
import { DisableAdminModal } from '../DisableAdminModal/DisableAdminModal'
import axios from 'axios'
import { User } from '../../../interfaces/interfaces'
import useToast from '../../../hooks/useToast'
import { URL_DISABLE_ADMIN } from '../../../utils/constants'

export const Admin: React.FC = () => {
  const currentAdmin: User = useSelector((state: any) => state.admins.current)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [busy, setBusy] = useState<boolean>(false)
  const { toast } = useToast()

  const handleDismiss = () => {
    dispatch(setCurrentAdmin(null))
  }

  const editAdmin = () => {
    dispatch(setEditingAdmin(true))
  }

  const openModal = () => {
    setShowModal(true)
  }

  async function closeModal(disableAdmin: boolean = false) {
    setShowModal(false)
    if (disableAdmin) {
      setBusy(true)
      await axios({
        method: 'POST',
        headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
        url: URL_DISABLE_ADMIN,
        data: {
          uid: currentAdmin.uid,
          disable: !currentAdmin.deleted
        }
      })
        .then(() => {
          dispatch(setCurrentAdmin(null))
        })
        .catch(() => {
          setBusy(false)
          toast(t('error.generic'))
        })
    }
  }

  return (
    <IonPage className="details">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle>{currentAdmin.name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

        <IonItem>
          <IonLabel>{t('field.email')}</IonLabel>
          <IonChip>{currentAdmin.email}</IonChip>
        </IonItem>

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon icon={buildOutline} />
          </IonFabButton>
          <IonFabList side="start">
            <IonFabButton onClick={editAdmin} className="icon-success edit-admin">
              <IonIcon icon={pencilOutline} />
            </IonFabButton>
            <IonFabButton onClick={openModal} className={`${currentAdmin.deleted ? 'icon-success' : 'icon-danger'} disable-admin`} >
              <IonIcon icon={currentAdmin.deleted ? checkmarkCircleOutline : trashOutline} />
            </IonFabButton>
          </IonFabList>
        </IonFab>

        <IonModal isOpen={showModal} className="disable-admin-modal" onDidDismiss={() => closeModal()}>
          <DisableAdminModal closeAction={closeModal} disable={!currentAdmin.deleted} />
        </IonModal>
      </IonContent>
    </IonPage>
  )
}

export default Admin
