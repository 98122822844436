import './StoresList.css'
import { gql, useQuery } from '@apollo/client'
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonThumbnail
} from '@ionic/react'
import { t } from 'i18next'
import { accessibility, add, buildOutline, cloudDownload, storefront } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCreatingUser, setCurrentStore, setStoresList } from '../../../redux/actions'
import SkeletonItems from '../../common/SkeletonItems/SkeletonItems'
import ToolbarSearch from '../../common/ToolbarSearch/ToolbarSearch'
import { User } from '../../../interfaces/interfaces'
import useToast from '../../../hooks/useToast'
import impersonateUser from '../../../utils/impersonateUser'
import { URL_SEND_STORES_LIST_EMAIL } from '../../../utils/constants'
import axios from 'axios'

const ALL_STORES_QUERY = gql`
query {
  allStoreUsers {
    id
    name
    email
    merchant_id
    adquirer_bin
    terminal_id
    encryption_key
    uid
    deleted
    allowSubUsers
  }
}
`

export const StoresList: React.FC = () => {
  const dispatch = useDispatch()
  const { loading, error, data } = useQuery(ALL_STORES_QUERY)
  const needToRefresh: boolean = useSelector((state: any) => state.stores.refresh)
  const { toast } = useToast()
  const [busy, setBusy] = useState<boolean>(false)

  useEffect(() => {
    if (data) {
      dispatch(setStoresList(data.allStoreUsers))
    }
  }, [data])

  if (needToRefresh) {
    window.location.reload()
  }

  if (loading) return <SkeletonItems />
  if (error) return <div>{error}</div>

  const addUser = () => {
    dispatch(setCreatingUser(true))
  }

  const searchItem = (event: any) => {
    const searchText = event.detail.value.toLowerCase()
    const stores = document.querySelectorAll<HTMLElement>('.mapped-item')
    stores.forEach((element) => {
      element?.dataset?.name?.toLowerCase().includes(searchText) || element?.dataset?.merchantId?.toLowerCase().includes(searchText) ? element.classList.remove('hidden') : element.classList.add('hidden')
    })
  }

  function _impersonateUser(store: User) {
    setBusy(true)
    impersonateUser(store, () => {
      toast(t('error.generic'))
      setBusy(false)
    })
  }

  async function sendStoresListExcel() {
    setBusy(true)

    const storesList = data.allStoreUsers.map((store: User) => {
      return {
        'Número de comercio': store.merchant_id,
        'Usuario': store.name,
        'Correo electrónico': store.email,
        'Estado': store.deleted ? t('message.inactive') : t('message.active')
      }
    })

    await axios({
      method: 'POST',
      url: URL_SEND_STORES_LIST_EMAIL,
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      data: {
        storesList
      }
    }).then(() => {
      toast(t('message.send_stores_list_email'))
    }).catch(() => {
      toast(t('error.send_stores_list_email'))
    })

    setBusy(false)
  }

  return (
    <IonPage>
      <IonHeader>
        <ToolbarSearch title={t('stores')} searchItem={searchItem} />
      </IonHeader>
      <IonContent>
        {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

        { /* <SkeletonItems /> */ } { /* TODO cambiar de graphql a react query y meter esto */ }
        {data.allStoreUsers.map((store: User) => (
          <IonItem
            key={store.id}
            className="mapped-item"
            data-name={`${store.name}`}
            data-merchantId={`${store.merchant_id}`}
          >
            <IonItem
              lines="none"
              onClick={() => { dispatch(setCurrentStore(store)) }}
              className={`mapped-item-inner list-item ${store.deleted ? 'item-disabled' : ''}`}
            >
              <IonThumbnail slot='start'>
                <IonIcon className="item-icon" icon={storefront} />
              </IonThumbnail>
              <IonLabel>
                <h2><b>{store.name}</b></h2>
                <p>{store?.merchant_id}</p>
              </IonLabel>
            </IonItem>
            {!store.deleted && (
              <button className='reset-button impersonate-user-button' onClick={() => { _impersonateUser(store) }} >
                <IonIcon className="impersonate-user" icon={accessibility} />
              </button>
            )}
          </IonItem>
        ))}

        <IonFab id="stores-list-options-fab" vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton id="stores-list-options" className='bounce-show'>
            <IonIcon icon={buildOutline} />
          </IonFabButton>
          <IonFabList side="start">
            <IonFabButton onClick={addUser} color="success" className='add-user'>
              <IonIcon icon={add} />
            </IonFabButton>
            <IonFabButton onClick={sendStoresListExcel} color="secondary">
              <IonIcon className="send-excel-icon" icon={cloudDownload}  />
            </IonFabButton>
          </IonFabList>
        </IonFab>
      </IonContent>
    </IonPage>
  )
}

export default StoresList
