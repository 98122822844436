import { IonItem, IonLabel } from '@ionic/react'
import { t } from 'i18next'
import React, { Dispatch, SetStateAction } from 'react'
import InputPassword from '../InputPassword/InputPassword'

interface PasswordFormFieldsProps {
  currentPasswordState?: [string, Dispatch<SetStateAction<string>>] | null
  newPasswordState: [string, Dispatch<SetStateAction<string>>]
  confirmNewPasswordState: [string, Dispatch<SetStateAction<string>>]
}

export const PasswordFormFields: React.FC<PasswordFormFieldsProps> = ({ currentPasswordState = null, newPasswordState, confirmNewPasswordState }) => {
  const [currentPassword, setCurrentPassword] = currentPasswordState ?? []
  const [newPassword, setNewPassword] = newPasswordState
  const [confirmNewPassword, setConfirmNewPassword] = confirmNewPasswordState

  return (
    <>
      {currentPasswordState && (
        <IonItem>
          <IonLabel position="stacked">{t('field.current_password')}</IonLabel>
          <InputPassword
            autocomplete="new-password"
            onIonChange={(e: any) => setCurrentPassword!(e.target.value)}
            value={currentPassword}
          />
        </IonItem>
      )}
      <IonItem>
        <IonLabel position="stacked">{t('field.new_password')}</IonLabel>
        <InputPassword
          autocomplete="new-password"
          onIonChange={(e: any) => setNewPassword(e.target.value)}
          value={newPassword}
        />
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">{t('field.confirm_new_password')}</IonLabel>
        <InputPassword
          autocomplete="new-password"
          onIonChange={(e: any) => setConfirmNewPassword(e.target.value)}
          value={confirmNewPassword}
        />
      </IonItem>
    </>
  )
}

export default PasswordFormFields
