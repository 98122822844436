import DOMPurify from 'dompurify'

DOMPurify.addHook('afterSanitizeAttributes', function(node) {
  if (node.tagName === 'A') {
    node.setAttribute('target', '_blank')
    node.setAttribute('rel', 'noopener noreferrer')
  }
})

export const SanitizeHtml = (htmlText: string) => DOMPurify.sanitize(htmlText)
