import {
  IonButton,
  IonChip,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
  isPlatform
} from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setCreatingSubUser, setEditingUserProfile, setUserData, setUserState } from '../../../redux/actions'
import { getUserData, logoutUser } from '../../../firebase'
import { accessibility, add, buildOutline, pencilOutline } from 'ionicons/icons'
import axios from 'axios'
import './UserProfile.css'
import {
  IMPERSONATE_DARK_MODE,
  IMPERSONATE_DATA,
  IMPERSONATE_EMAIL_NOTIFICATIONS,
  IMPERSONATE_EMAIL_PAYMENT_EXPIRATION,
  IMPERSONATE_EMAIL_PAYMENT_STATUS,
  IMPERSONATE_ALLOW_SUB_USERS,
  URL_IMPERSONATE,
  URL_UPDATE_USER
} from '../../../utils/constants'
import { User } from '../../../interfaces/interfaces'
import { getLocalStorageUserToggle, isImpersonatingOrStore } from '../../../utils/helper'
import useToast from '../../../hooks/useToast'
import logoutIcon from '../../../assets/img/logoutIcon.svg'
import { SubUsersList } from '../../common/SubUsersList/SubUsersList'
import Notifications from '../../common/Notifications/Notifications'
import DocsLinks from '../../common/DocsLinks/DocsLinks'

export const UserProfile: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const userData: User = useSelector((state: any) => state.userData)
  const deferredPrompt: any = useSelector((state: any) => state.pwaEvent)
  const [busy, setBusy] = useState<boolean>(false)
  const { toast } = useToast()

  if (!userData) {
    window.location.href = '/login'
  }

  function installPWA() {
    if (!deferredPrompt) return

    deferredPrompt.prompt()
    deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt')
      } else {
        console.log('User dismissed the install prompt')
      }
    })
  }

  async function logout() {
    await logoutUser()
    localStorage.clear()
    dispatch(setUserState(null))
    dispatch(setUserData(null))
  }

  const toggleDarkMode = async (event: any) => {
    event.target.disabled = true

    if (userData.is_admin && userData.impersonateId) {
      document.body.classList.toggle('dark')
      localStorage.setItem(IMPERSONATE_DARK_MODE, String(Number(!Number(localStorage.getItem(IMPERSONATE_DARK_MODE)))))
      event.target.checked = Number(localStorage.getItem(IMPERSONATE_DARK_MODE))
      event.target.disabled = false
      return
    }

    await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      params: { merchant_id: localStorage.getItem('USER_MERCHANT_ID') || '' },
      url: URL_UPDATE_USER,
      data: { dark_mode: !userData.dark_mode }
    })
      .then(async () => {
        document.body.classList.toggle('dark')
        const newUserData = await getUserData(userData.merchant_id)
        if (newUserData) dispatch(setUserData(newUserData))
      })
      .catch(() => {
        event.target.checked = userData.dark_mode
      })
      .finally(() => {
        event.target.disabled = false
      })
  }

  const toggleEmailNotifications = async (event: any) => {
    event.target.disabled = true

    if (userData.is_admin && userData.impersonateId) {
      const impersonateEmailNotifications = String(Number(!Number(localStorage.getItem(IMPERSONATE_EMAIL_NOTIFICATIONS))))
      localStorage.setItem(IMPERSONATE_EMAIL_NOTIFICATIONS, impersonateEmailNotifications)
      localStorage.setItem(IMPERSONATE_EMAIL_PAYMENT_STATUS, impersonateEmailNotifications)
      localStorage.setItem(IMPERSONATE_EMAIL_PAYMENT_EXPIRATION, impersonateEmailNotifications)
      event.target.checked = Number(localStorage.getItem(IMPERSONATE_EMAIL_NOTIFICATIONS))
      event.target.disabled = false
      dispatch(setUserData({ ...userData, emailNotifications: event.target.checked, emailPaymentStatus: event.target.checked, emailPaymentExpiration: event.target.checked}))
      return
    }

    await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      params: { merchant_id: localStorage.getItem('USER_MERCHANT_ID') || '' },
      url: URL_UPDATE_USER,
      data: {
        emailNotifications: !userData.emailNotifications,
        emailPaymentStatus: !userData.emailNotifications,
        emailPaymentExpiration: !userData.emailNotifications
      }
    })
      .then(async () => {
        const newUserData = await getUserData(userData.merchant_id)
        if (newUserData) dispatch(setUserData(newUserData))
      })
      .catch(() => {
        event.target.checked = userData.emailNotifications
      })
      .finally(() => {
        event.target.disabled = false
      })
  }

  const toggleEmailPaymentStatus = async (event: any) => {
    event.target.disabled = true

    if (userData.is_admin && userData.impersonateId) {
      localStorage.setItem(IMPERSONATE_EMAIL_PAYMENT_STATUS, String(Number(!Number(localStorage.getItem(IMPERSONATE_EMAIL_PAYMENT_STATUS)))))
      event.target.checked = Number(localStorage.getItem(IMPERSONATE_EMAIL_PAYMENT_STATUS))
      event.target.disabled = false
      return
    }

    await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      params: { merchant_id: localStorage.getItem('USER_MERCHANT_ID') || '' },
      url: URL_UPDATE_USER,
      data: { emailPaymentStatus: !userData.emailPaymentStatus }
    })
      .then(async () => {
        const newUserData = await getUserData(userData.merchant_id)
        if (newUserData) dispatch(setUserData(newUserData))
      })
      .catch(() => {
        event.target.checked = userData.emailPaymentStatus
      })
      .finally(() => {
        event.target.disabled = false
      })
  }

  const toggleEmailPaymentExpiration = async (event: any) => {
    event.target.disabled = true

    if (userData.is_admin && userData.impersonateId) {
      localStorage.setItem(IMPERSONATE_EMAIL_PAYMENT_EXPIRATION, String(Number(!Number(localStorage.getItem(IMPERSONATE_EMAIL_PAYMENT_EXPIRATION)))))
      event.target.checked = Number(localStorage.getItem(IMPERSONATE_EMAIL_PAYMENT_EXPIRATION))
      event.target.disabled = false
      return
    }

    await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      params: { merchant_id: localStorage.getItem('USER_MERCHANT_ID') || '' },
      url: URL_UPDATE_USER,
      data: { emailPaymentExpiration: !userData.emailPaymentExpiration }
    })
      .then(async () => {
        const newUserData = await getUserData(userData.merchant_id)
        if (newUserData) dispatch(setUserData(newUserData))
      })
      .catch(() => {
        event.target.checked = userData.emailPaymentExpiration
      })
      .finally(() => {
        event.target.disabled = false
      })
  }

  const toggleSubUsers = async (event: any) => {
    event.target.disabled = true
    if(document.querySelector<HTMLIonFabButtonElement>('.fab-button-close-active')) document.querySelector<HTMLIonFabButtonElement>('.fab-button-close-active')?.click()

    if (userData.is_admin && userData.impersonateId) {
      localStorage.setItem(IMPERSONATE_ALLOW_SUB_USERS, String(Number(!Number(localStorage.getItem(IMPERSONATE_ALLOW_SUB_USERS)))))
      event.target.checked = Number(localStorage.getItem(IMPERSONATE_ALLOW_SUB_USERS))
      event.target.disabled = false
      dispatch(setUserData({ ...userData, allowSubUsers: event.target.checked}))
      return
    }

    await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      params: { merchant_id: localStorage.getItem('USER_MERCHANT_ID') || '' },
      url: URL_UPDATE_USER,
      data: { allowSubUsers: !userData.allowSubUsers }
    })
      .then(async () => {
        const newUserData = await getUserData(userData.merchant_id)
        if (newUserData) dispatch(setUserData(newUserData))
      })
      .catch(() => {
        event.target.checked = userData.allowSubUsers
      })
      .finally(() => {
        event.target.disabled = false
      })
  }

  async function unimpersonateUser() {
    setBusy(true)

    await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: URL_IMPERSONATE,
      data: { impersonateId: null }
    }).then(() => {
      localStorage.removeItem(IMPERSONATE_DATA)
      localStorage.removeItem(IMPERSONATE_DARK_MODE)
      localStorage.removeItem(IMPERSONATE_EMAIL_NOTIFICATIONS)
      localStorage.removeItem(IMPERSONATE_EMAIL_PAYMENT_STATUS)
      localStorage.removeItem(IMPERSONATE_EMAIL_PAYMENT_EXPIRATION)
      localStorage.removeItem(IMPERSONATE_ALLOW_SUB_USERS)
      window.location.reload()
    }).catch(async () => {
      toast(t('error.generic'))
      setBusy(false)
    })

    return
  }

  return (
    <IonPage>
      {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

      <IonHeader>
        <IonToolbar color="primary" className='toolbar-container'>
          <IonTitle>{t('profile')}</IonTitle>
          <Notifications />
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className='flex-space-between-container'>
          <div>
            <IonItem>
              <IonLabel>{t('field.name')}</IonLabel>
              <IonChip color="primary">{userData.name}</IonChip>
            </IonItem>
            <IonItem>
              <IonLabel>{t(userData.parentStoreId ? 'field.identifier' : 'field.email')}</IonLabel>
              <IonChip color="primary">{userData.parentStoreId ? userData.identifier : userData.email}</IonChip>
            </IonItem>
            <IonItem>
              <IonLabel>{t('field.dark_mode')}</IonLabel>
              <IonToggle checked={userData.is_admin && userData.impersonateId ? getLocalStorageUserToggle(IMPERSONATE_DARK_MODE) : !!userData.dark_mode} onClick={toggleDarkMode} />
            </IonItem>

            {isImpersonatingOrStore(userData) && !userData.parentStoreId ? (
              <>
                <IonItem>
                  <IonLabel>{t('field.email_notifications')}</IonLabel>
                  <IonToggle checked={userData.is_admin && userData.impersonateId ? getLocalStorageUserToggle(IMPERSONATE_EMAIL_NOTIFICATIONS) : !!userData.emailNotifications} onClick={toggleEmailNotifications} />
                </IonItem>
                {((userData.is_admin && userData.impersonateId && getLocalStorageUserToggle(IMPERSONATE_EMAIL_NOTIFICATIONS)) || (!userData.is_admin && userData.emailNotifications)) && (
                  <IonList className="email-notifications-list">
                    <IonItem>
                      <IonLabel>{t('field.email_payment_status')}</IonLabel>
                      <IonToggle checked={userData.is_admin && userData.impersonateId ? getLocalStorageUserToggle(IMPERSONATE_EMAIL_PAYMENT_STATUS) : !!userData.emailPaymentStatus} onClick={toggleEmailPaymentStatus} />
                    </IonItem>
                    <IonItem>
                      <IonLabel>{t('field.email_payment_expiration')}</IonLabel>
                      <IonToggle checked={userData.is_admin && userData.impersonateId ? getLocalStorageUserToggle(IMPERSONATE_EMAIL_PAYMENT_EXPIRATION) : !!userData.emailPaymentExpiration} onClick={toggleEmailPaymentExpiration} />
                    </IonItem>
                  </IonList>
                )}

                {!userData.parentStoreId && (
                  <IonItem>
                    <IonLabel>{t('field.allow_sub_users')}</IonLabel>
                    <IonToggle checked={userData.is_admin && userData.impersonateId ? getLocalStorageUserToggle(IMPERSONATE_ALLOW_SUB_USERS) : !!userData.allowSubUsers} onClick={toggleSubUsers} />
                  </IonItem>
                )}
              </>
            ) : null}

            {!isPlatform('ios') && (
              <IonButton onClick={installPWA} expand="block" className="ion-padding install-pwa">
                {t('action.install')}
              </IonButton>
            )}

            {isImpersonatingOrStore(userData) && !userData.parentStoreId && ((userData.is_admin && userData.impersonateId && getLocalStorageUserToggle(IMPERSONATE_ALLOW_SUB_USERS)) || (!userData.is_admin && userData.allowSubUsers)) ? (
              <SubUsersList storeId={userData.impersonateId ? userData.impersonateId : userData.id} isAdmin={false} />
            ) : null}

            {userData.is_admin && userData.impersonateId ? (
              <IonFab id="profile-options-fab" vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton id="profile-options" className='bounce-show'>
                  <IonIcon icon={buildOutline} />
                </IonFabButton>
                <IonFabList side="start">
                  <IonFabButton onClick={unimpersonateUser} color='danger' className='unimpersonate-user'>
                    <IonIcon icon={accessibility} />
                  </IonFabButton>
                  {(!userData.parentStoreId && (userData.is_admin && userData.impersonateId && getLocalStorageUserToggle(IMPERSONATE_ALLOW_SUB_USERS)) || (!userData.is_admin && userData.allowSubUsers)) && (
                    <IonFabButton onClick={() => dispatch(setCreatingSubUser(true))} color="secondary" className='add-new-sub-user'>
                      <IonIcon icon={add} />
                    </IonFabButton>
                  )}
                </IonFabList>
              </IonFab>
            ) : (
              <IonFab id="profile-options-fab" vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton id="profile-options" className='bounce-show'>
                  <IonIcon icon={buildOutline} />
                </IonFabButton>
                <IonFabList side="start">
                  <IonFabButton onClick={logout} color="danger" className='user-profile-logout'>
                    <IonIcon icon={logoutIcon} />
                  </IonFabButton>
                  <IonLabel className='icon-label'>{t('action.logout')}</IonLabel>
                  {!userData.parentStoreId && (
                    <>
                      <IonFabButton onClick={() => dispatch(setEditingUserProfile(true))} color="success" className="edit-user-profile">
                        <IonIcon icon={pencilOutline} />
                      </IonFabButton>
                      <IonLabel className='icon-label'>{t('action.edit')}</IonLabel>
                    </>
                  )}
                  {(!userData.parentStoreId && (userData.is_admin && userData.impersonateId && getLocalStorageUserToggle(IMPERSONATE_ALLOW_SUB_USERS)) || (!userData.is_admin && userData.allowSubUsers)) && (
                    <>
                      <IonFabButton onClick={() => dispatch(setCreatingSubUser(true))} color="secondary" className='add-new-sub-user'>
                        <IonIcon icon={add} />
                      </IonFabButton>
                      <IonLabel className='icon-label'>{t('action.add')}</IonLabel>
                    </>
                  )}
                </IonFabList>
              </IonFab>
            )}
          </div>

          <DocsLinks />
        </div>
      </IonContent>
    </IonPage>
  )
}

export default UserProfile
