import { Link, useHistory } from 'react-router-dom'
import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter
} from '@ionic/react'
import { useState } from 'react'
import { loginUser, logoutUser, sendVerification } from '../../firebase'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import InputPassword from '../../components/common/InputPassword/InputPassword'
import useToast from '../../hooks/useToast'
import { PASSWORD_REGEX, RECAPTCHA_REGISTER_ACTION, URL_REGISTER_USER, URL_USER } from '../../utils/constants'
import { useRecaptcha } from '../../hooks/useRecaptcha'
import RecaptchaMessage from '../../components/common/RecaptchaMessage/RecaptchaMessage'

const Register: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [registerText, setRegisterText] = useState(<></>)
  const [credential, setCredential] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { toast } = useToast()
  const { handleVerifyRecaptcha, isRecaptchaReady } = useRecaptcha(RECAPTCHA_REGISTER_ACTION)

  useIonViewDidEnter(() => {
    setRegisterText(<>{t('register')}</>)
  })

  async function checkUser(credential: string, recaptchaToken: string) {
    const params = new URLSearchParams({
      credential,
      recaptchaToken,
      recaptchaAction: RECAPTCHA_REGISTER_ACTION
    }).toString()

    return await axios({
      method: 'GET',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: `${URL_USER}?${params}`
    }).then((response) => {
      return response.data
    }).catch(() => {
      return false
    })
  }

  function showRegistered() {
    toast(t('message.registered'))
    setTimeout(() => {
      history.push('/login')
    }, 2000)
  }

  async function register() {
    setRegisterText(<IonSpinner />)

    const { recaptchaToken, error } = await handleVerifyRecaptcha()
    if (error) {
      toast(t('error.generic'))
      setRegisterText(<>{t('register')}</>)
      return
    }

    if (password !== confirmPassword) {
      toast(t('error.password_mismatch'))
    } else if (credential.trim() === '' || password.trim() === '') {
      toast(t('error.credentials_required'))
    } else if (!password.match(PASSWORD_REGEX)) {
      toast(t('error.password_validation'))
    } else {
      const userInDB = await checkUser(credential, recaptchaToken)

      if (!userInDB) {
        showRegistered()
        return
      }

      const { recaptchaToken: _recaptchaToken, error: _error } = await handleVerifyRecaptcha()
      if (_error) {
        toast(t('error.generic'))
        setRegisterText(<>{t('register')}</>)
        return
      }

      await axios({
        method: 'POST',
        headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
        url: URL_REGISTER_USER,
        data: {
          credential,
          password,
          recaptchaToken: _recaptchaToken,
          recaptchaAction: RECAPTCHA_REGISTER_ACTION
        }
      }).then(async (response) => {
        if (response.data.success) {
          const result = await loginUser(response.data.user.email, password)

          if (result.success && !result.user?.emailVerified) {
            sendVerification(result.user)
            await logoutUser()
          }
        }
        showRegistered()
      }).catch(() => {
        toast(t('error.generic'))
      })
    }
    setRegisterText(<>{t('register')}</>)
  }

  return (
    <IonPage className='register-page'>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{t('register')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonInput
          placeholder={t('merchant_id')}
          onIonChange={(e: any) => setCredential(e.target.value)}
        />
        <InputPassword
          placeholder={t('password')}
          onIonChange={(e: any) => setPassword(e.target.value)}
        />
        <InputPassword
          placeholder={t('confirm_password')}
          onIonChange={(e: any) => setConfirmPassword(e.target.value)}
        />
        <IonButton onClick={register} disabled={!isRecaptchaReady}>{registerText}</IonButton>

        <RecaptchaMessage />

        <p>
          {t('message.already_have_account')} <Link to="/login">{t('login')}</Link>
        </p>
      </IonContent>
    </IonPage>
  )
}

export default Register
