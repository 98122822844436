import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react'
import { useSelector } from 'react-redux'
import MultiplePayment from '../../components/multiple_payments/MultiplePayment/MultiplePayment'
import MultiplePaymentsList from '../../components/multiple_payments/MultiplePaymentsList/MultiplePaymentsList'
import { MultiplePayment as MultiplePaymentInterface, User } from '../../interfaces/interfaces'

export const MultiplePayments: React.FC = () => {
  const currentUser: User | null = useSelector((state: any) => state.userData)
  const currentMultiplePayment: MultiplePaymentInterface | null = useSelector(
    (state: any) => state.multiple_payments.current
  )

  useIonViewDidEnter(() => {
    // TODO refactor this
    document.querySelector('.multiple_payments-page')?.classList.remove('ion-page-hidden', 'ion-page-invisible')
    document
      .querySelector('.multiple_payments-page .ion-page')
      ?.classList.remove('ion-page-hidden', 'ion-page-invisible')
  })

  return (
    <IonPage className="multiple_payments-page">
      <IonContent>{currentMultiplePayment ? <MultiplePayment /> : <MultiplePaymentsList currentUser={currentUser} />}</IonContent>
    </IonPage>
  )
}

export default MultiplePayments
