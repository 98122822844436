import {
  IonIcon,
  IonPopover,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonModal,
  IonButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonSpinner
} from '@ionic/react'
import { t } from 'i18next'
import { notifications, informationCircleOutline, closeOutline } from 'ionicons/icons'
import { SanitizeHtml } from '../../../utils/Sanitizer'
import './Notifications.css'
import React, { useRef, useState } from 'react'
import { Notification, NotificationsState } from '../../../interfaces/interfaces'
import axios from 'axios'
import { URL_SET_NOTIFICATION_READ_AT } from '../../../utils/constants'
import { useSelector } from 'react-redux'

const Notifications: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const notificationTitle = useRef<string>('')
  const notificationBody = useRef<string>('')

  const {
    isLoading,
    list: notificationsList,
    count: notificationsCount,
    unreadCount: notificationsUnreadCount,
    refetch
  } = useSelector((state: { notifications: NotificationsState }) => state.notifications)

  async function showInfoModal(notification: Notification) {
    const { id, title, body, readAt } = notification

    if (!readAt) { // FIXME - uncomment when client wants to mark notifications as read
      // await axios({
      //   method: 'POST',
      //   headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      //   url: URL_SET_NOTIFICATION_READ_AT,
      //   data: { id }
      // })
    }

    notificationTitle.current = SanitizeHtml(title)
    notificationBody.current = SanitizeHtml(body)
    setIsOpen(true)
    const popover = document.getElementById('notifications-popover') as HTMLIonPopoverElement
    popover.dismiss()
    refetch()
  }

  return (
    <div id="notification-button" className="notifications-item-icon-container" aria-hidden="true" slot="end">
      <IonIcon className="notifications-item-icon" icon={notifications} />
      {notificationsUnreadCount > 0 ? (
        <div className="notifications-count">
          <span>{notificationsUnreadCount}</span>
        </div>
      ) : null}

      <IonPopover id="notifications-popover" trigger="notification-button" triggerAction="click" arrow={false}>
        <IonContent class="ion-padding">
          <IonList id="notifications-list">
            {isLoading ? (
              <IonSpinner className="w-full" />
            ) : notificationsList && notificationsCount && notificationsCount > 0 ? (
              notificationsList.map((notification, index) => (
                <IonItem
                  key={notification.id}
                  className={`notification-item ${notification.readAt ? 'item-disabled' : ''}`}
                  lines={index === notificationsCount - 1 ? 'none' : 'full'}
                  onClick={() => showInfoModal(notification)}
                >
                  <IonLabel className="notification-container">
                    <IonIcon className="notification-icon" icon={informationCircleOutline} slot="start" />
                    <IonLabel className='notification-info'>
                      <IonLabel
                        className="notification-title"
                        dangerouslySetInnerHTML={{ __html: SanitizeHtml(notification.title) }}
                      />
                      <IonLabel
                        className="notification-body"
                        dangerouslySetInnerHTML={{ __html: SanitizeHtml(notification.body) }}
                      />
                    </IonLabel>
                  </IonLabel>
                </IonItem>
              ))
            ) : (
              <IonItem className="notification-item" lines="none">
                <IonLabel className="notification-container">
                  <IonIcon className="notification-icon" icon={informationCircleOutline} slot="start" />
                  <IonLabel>{t('message.no_notifications')}</IonLabel>
                </IonLabel>
              </IonItem>
            )}
          </IonList>
        </IonContent>
      </IonPopover>

      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>{t('title.notification')}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonLabel className="notification-title" dangerouslySetInnerHTML={{ __html: notificationTitle.current }} />
          <br />
          <br />
          <IonLabel className="notification-body" dangerouslySetInnerHTML={{ __html: notificationBody.current }} />
        </IonContent>
      </IonModal>
    </div>
  )
}

export default Notifications
