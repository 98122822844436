import './DocsLinks.css'
import { useTranslation } from 'react-i18next'

const DocsLinks: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div id="docs-links">
      <a href="/docs/cookies-policy.pdf" target="_blank" rel="noreferrer">
        {t('cookies.cookies_policy')}
      </a>
    </div>
  )
}

export default DocsLinks
