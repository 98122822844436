import './ToolbarSearch.css'
import React, { useEffect } from 'react'
import {
  IonAccordion,
  IonAccordionGroup,
  IonBackdrop,
  IonButton,
  IonCol,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter
} from '@ionic/react'
import { barChart, calendar, search as searchIcon } from 'ionicons/icons'
import { t } from 'i18next'
import { useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { refreshDbPaymentsList, setDbPage, setDescriptionFilter, setSearchFilters } from '../../../redux/actions'
import { Payment, SearchItemFilters, User } from '../../../interfaces/interfaces'
import { DEFAULT_VALUE, DEFAULT_SEARCH_ITEM_FILTERS } from '../../../utils/constants'
import { filtersAreEmpty, isImpersonatingOrStore } from '../../../utils/helper'
import { useQuery } from '@tanstack/react-query'
import getSubUsers from '../../../utils/getSubUsers'

interface ToolbarSearchProps {
  title: string
  searchItem: Function
  showStatistics?: boolean
  setShowStatistics?: Function
  setStoreFilter?: Function
  setSubUserFilter?: Function
  descriptionFilter?: string | null
  advanced?: boolean
}

export const ToolbarSearch: React.FC<ToolbarSearchProps> = ({
  title,
  searchItem,
  showStatistics = false,
  setShowStatistics = false,
  setStoreFilter = () => {},
  setSubUserFilter = () => {},
  descriptionFilter = null,
  advanced = false
}) => {
  const dispatch = useDispatch()
  const currentUser: User = useSelector((state: any) => state.userData)
  const allStoresList: any = useSelector((state: any) => state.stores.list)
  const dbPaymentsList: Payment[] | null = useSelector((state: any) => state.payments.dbList)
  const [search, setSearch] = useState<string | null>(descriptionFilter)
  const [storeFilterId, setStoreFilterId] = useState<number>(DEFAULT_VALUE)
  const [subUserFilterId, setSubUserFilterId] = useState<number>(DEFAULT_VALUE)
  const [dateFrom, setDateFrom] = useState<string | null>(null)
  const [dateTo, setDateTo] = useState<string | null>(null)
  const [expirationFrom, setExpirationFrom] = useState<string | null>(null)
  const [expirationTo, setExpirationTo] = useState<string | null>(null)
  const [backdrop, setBackdrop] = useState(false)
  const [localSearchFilters, setLocalSearchFilters] = useState<SearchItemFilters>(DEFAULT_SEARCH_ITEM_FILTERS)

  const toggleSearchBar = () => {
    if (advanced) {
      const isActive = document.querySelector('#search-filters')?.classList.contains('accordion-expanded') || false
      document
        .querySelector<HTMLIonAccordionGroupElement>('#search-filters-container')
        ?.requestAccordionToggle('search-filters', !isActive)
      setBackdrop(!backdrop)

      const filters: SearchItemFilters = {
        search: search ? search.trim() : null,
        store: storeFilterId,
        subUser: subUserFilterId,
        dateFrom,
        dateTo,
        expirationFrom,
        expirationTo
      }
      if (isImpersonatingOrStore(currentUser) && filtersAreEmpty(filters)) dispatch(refreshDbPaymentsList(null))
    } else {
      document.querySelector('.toolbar-container ion-title')?.classList.toggle('hidden')
      document.querySelector('.toolbar-container .search-item-icon-container')?.classList.toggle('hidden')
      document.querySelector('.toolbar-container ion-searchbar')?.classList.toggle('not-visible')
    }

    if(advanced) document.querySelector<HTMLIonAccordionGroupElement>('#date-accordion-group')!.value = undefined
  }

  const onSubmitForm = (event: any) => {
    const filters: SearchItemFilters = {
      search: search ? search.trim() : null,
      store: storeFilterId,
      subUser: subUserFilterId,
      dateFrom,
      dateTo,
      expirationFrom,
      expirationTo
    }
    setLocalSearchFilters(filters)
    setStoreFilter(storeFilterId)
    setSubUserFilter(subUserFilterId)
    dispatch(setDbPage(1))
    searchItem(event, filters)
    if (!descriptionFilter) toggleSearchBar()
  }

  const resetFilters = () => {
    setSearch(null)
    setStoreFilterId(DEFAULT_VALUE)
    setStoreFilter(DEFAULT_VALUE)
    setSubUserFilterId(DEFAULT_VALUE)
    setSubUserFilter(DEFAULT_VALUE)
    setDateFrom(null)
    setDateTo(null)
    setExpirationFrom(null)
    setExpirationTo(null)
    setLocalSearchFilters(DEFAULT_SEARCH_ITEM_FILTERS)
    dispatch(setSearchFilters(DEFAULT_SEARCH_ITEM_FILTERS))
    !isImpersonatingOrStore(currentUser) ? searchItem(null, DEFAULT_SEARCH_ITEM_FILTERS) : dispatch(refreshDbPaymentsList(null))
    toggleSearchBar()
  }

  function toggleStatistics() {
    document.querySelector<HTMLIonAccordionGroupElement>('#search-filters-container')?.requestAccordionToggle(
      'search-filters',
      false
    )

    setBackdrop(false)

    if (setShowStatistics && typeof setShowStatistics === 'function') setShowStatistics(!showStatistics)

    const statisticsPage = document.querySelector('.statistics-page')
    const sendExcelButton = document.querySelector('.send-excel-button')
    if (showStatistics) {
      statisticsPage?.classList.add('statistics-close')
      statisticsPage?.classList.remove('statistics-open')
      sendExcelButton?.classList.add('bounce-hide')
      sendExcelButton?.classList.remove('bounce-show')
    } else {
      statisticsPage?.classList.add('statistics-open')
      statisticsPage?.classList.remove('statistics-close')
      sendExcelButton?.classList.add('bounce-show')
      sendExcelButton?.classList.remove('bounce-hide')
    }
  }

  useIonViewDidEnter(() => {
    if (document.querySelector('.toolbar-container ion-title')?.classList.contains('hidden')) {
      document.querySelector('.toolbar-container ion-searchbar')?.classList.remove('not-visible')
    }
  })

  const { data: subUsers } = useQuery<User[]>({
    queryKey: ['subUsers', storeFilterId],
    queryFn: () => getSubUsers(currentUser.is_admin ? storeFilterId : currentUser.id)
  })

  useEffect(() => {
    if (descriptionFilter) {
      onSubmitForm(null)
      dispatch(setDescriptionFilter(null))
    }
  }, [])

  return (
    <IonAccordionGroup id="search-filters-container">
      <IonToolbar color="primary">
        <div className="toolbar-container">
          <IonTitle>{title}</IonTitle>
          {!advanced && (
            <IonSearchbar
              className="not-visible"
              onIonChange={(event) => searchItem(event)}
              onIonCancel={toggleSearchBar}
              placeholder={t('search')}
              showCancelButton="always"
              debounce={500}
            />
          )}
          {setShowStatistics && (
            <div className="statistics-item-icon-container" onClick={toggleStatistics} aria-hidden="true">
              <IonIcon className={`statistics-item-icon ${showStatistics ? 'ion-color ion-color-success' : ''}`} icon={barChart} slot="end" />
            </div>
          )}
          <div className="search-item-icon-container" onClick={toggleSearchBar} aria-hidden="true">
            <IonIcon className="search-item-icon" color={dbPaymentsList && !filtersAreEmpty(localSearchFilters) ? 'success' : ''} icon={searchIcon} slot="end" />
          </div>
        </div>
      </IonToolbar>

      {advanced && (
        <IonAccordion id="search-filters" value="search-filters">
          <IonList slot="content">
            <form id="search-filters-form" onSubmit={onSubmitForm}>
              <IonItem>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonLabel>{t('search')}</IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonInput
                        name="search"
                        value={search}
                        placeholder={t('search')}
                        onIonChange={(e: any) => setSearch(e.target.value ? e.target.value.trim() : null)}
                        onIonFocus={() =>
                          (document.querySelector<HTMLIonAccordionGroupElement>('#date-accordion-group')!.value =
                            undefined)
                        }
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>

              {currentUser && !isImpersonatingOrStore(currentUser) && (
                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonLabel>{t('store')}</IonLabel>
                      </IonCol>
                      <IonCol>
                        <IonSelect
                          name="store"
                          interface="action-sheet"
                          cancelText={t('action.cancel')}
                          placeholder={t('store')}
                          onIonChange={(e) => {
                            setStoreFilterId(e.detail.value)
                            setSubUserFilterId(DEFAULT_VALUE)
                          }}
                          onIonFocus={() => {
                            document.querySelector<HTMLIonAccordionGroupElement>('#date-accordion-group')!.value = undefined
                          }}
                          value={storeFilterId}
                        >
                          <IonSelectOption value={DEFAULT_VALUE} defaultChecked>
                            {t('all')}
                          </IonSelectOption>
                          {allStoresList?.map((store: any) => (
                            <IonSelectOption key={store.id} value={store.id}>
                              {store.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              )}

              {currentUser && !currentUser.parentStoreId && (
                <IonItem>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonLabel>{t('field.sub_users')}</IonLabel>
                      </IonCol>
                      <IonCol>
                        <IonSelect
                          name="subUser"
                          interface="action-sheet"
                          cancelText={t('action.cancel')}
                          placeholder={t('field.sub_users')}
                          onIonChange={(e) => {
                            setSubUserFilterId(e.detail.value)
                          }}
                          onIonFocus={() => {
                            document.querySelector<HTMLIonAccordionGroupElement>('#date-accordion-group')!.value = undefined
                          }}
                          value={subUserFilterId}
                        >
                          <IonSelectOption value={DEFAULT_VALUE} defaultChecked>
                            {t('all')}
                          </IonSelectOption>
                          {subUsers?.map((subUser: any) => (
                            <IonSelectOption key={subUser.identifier} value={subUser.id}>
                              {subUser.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              )}

              <IonAccordionGroup id="date-accordion-group">
                <IonItem className="date-filter">
                  <IonGrid>
                    <IonAccordion value="date-accordion">
                      <IonRow slot="header">
                        <IonCol>
                          <IonLabel>{t('field.creation_date')}</IonLabel>
                          <IonLabel>
                            {dateFrom ? (
                              moment(dateFrom).format(process.env.REACT_APP_DATE_FORMAT)
                            ) : (
                              <IonIcon icon={calendar} />
                            )}
                            {' - '}
                            {dateTo ? (
                              moment(dateTo).format(process.env.REACT_APP_DATE_FORMAT)
                            ) : (
                              <IonIcon icon={calendar} />
                            )}
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                      <IonRow slot="content" className="date-inputs">
                        <IonCol>
                          <IonItem id="open-date-input-filter-from">
                            <IonText>{t('message.from')}</IonText>
                            <IonText>
                              <IonLabel>
                                {dateFrom ? (
                                  moment(dateFrom).format(process.env.REACT_APP_DATE_FORMAT)
                                ) : (
                                  <IonIcon icon={calendar} />
                                )}
                              </IonLabel>
                            </IonText>
                          </IonItem>
                        </IonCol>
                        {dateFrom ? (
                          <IonCol>
                            <IonItem id="open-date-input-filter-to">
                              <IonText>{t('message.to')}</IonText>
                              <IonText>
                                <IonLabel>
                                  {dateTo ? (
                                    moment(dateTo).format(process.env.REACT_APP_DATE_FORMAT)
                                  ) : (
                                    <IonIcon icon={calendar} />
                                  )}
                                </IonLabel>
                              </IonText>
                            </IonItem>
                          </IonCol>
                        ) : null}
                      </IonRow>
                    </IonAccordion>
                  </IonGrid>

                  <IonPopover
                    id="date-from-popover"
                    trigger="open-date-input-filter-from"
                    showBackdrop={false}
                    alignment="start"
                  >
                    <IonDatetime
                      showClearButton
                      presentation="date"
                      onIonChange={(e: any) => {
                        setDateFrom(e.detail.value ? moment(e.detail.value).format('YYYY-MM-DD') : null)
                        document.querySelector<HTMLIonPopoverElement>('#date-from-popover')?.dismiss()

                        if (dateTo && !moment(e.detail.value).isBefore(dateTo)) {
                          setDateTo(null)
                        }
                      }}
                      value={dateFrom}
                    />
                  </IonPopover>

                  {dateFrom ? (
                    <IonPopover
                      id="date-to-popover"
                      trigger="open-date-input-filter-to"
                      showBackdrop={false}
                      alignment="start"
                    >
                      <IonDatetime
                        showClearButton
                        presentation="date"
                        onIonChange={(e: any) => {
                          setDateTo(e.detail.value ? moment(e.detail.value).format('YYYY-MM-DD') : null)
                          document.querySelector<HTMLIonPopoverElement>('#date-to-popover')?.dismiss()
                        }}
                        value={dateTo}
                        min={dateFrom ? moment(dateFrom).add(1, 'days').toISOString() : undefined}
                      />
                    </IonPopover>
                  ) : null}
                </IonItem>

                <IonItem className="date-filter">
                  <IonGrid>
                    <IonAccordion value="expiration-accordion">
                      <IonRow slot="header">
                        <IonCol>
                          <IonLabel>{t('field.expiration')}</IonLabel>
                          <IonLabel>
                            {expirationFrom ? (
                              moment(expirationFrom).format(process.env.REACT_APP_DATE_FORMAT)
                            ) : (
                              <IonIcon icon={calendar} />
                            )}
                            {' - '}
                            {expirationTo ? (
                              moment(expirationTo).format(process.env.REACT_APP_DATE_FORMAT)
                            ) : (
                              <IonIcon icon={calendar} />
                            )}
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                      <IonRow slot="content" className="date-inputs">
                        <IonCol>
                          <IonItem id="open-expiration-input-filter-from">
                            <IonText>{t('message.from')}</IonText>
                            <IonText>
                              <IonLabel>
                                {expirationFrom ? (
                                  moment(expirationFrom).format(process.env.REACT_APP_DATE_FORMAT)
                                ) : (
                                  <IonIcon icon={calendar} />
                                )}
                              </IonLabel>
                            </IonText>
                          </IonItem>
                        </IonCol>
                        {expirationFrom ? (
                          <IonCol>
                            <IonItem id="open-expiration-input-filter-to">
                              <IonText>{t('message.to')}</IonText>
                              <IonText>
                                <IonLabel>
                                  {expirationTo ? (
                                    moment(expirationTo).format(process.env.REACT_APP_DATE_FORMAT)
                                  ) : (
                                    <IonIcon icon={calendar} />
                                  )}
                                </IonLabel>
                              </IonText>
                            </IonItem>
                          </IonCol>
                        ) : null}
                      </IonRow>
                    </IonAccordion>
                  </IonGrid>

                  <IonPopover
                    id="expiration-from-popover"
                    trigger="open-expiration-input-filter-from"
                    showBackdrop={false}
                    alignment="start"
                  >
                    <IonDatetime
                      showClearButton
                      presentation="date"
                      onIonChange={(e: any) => {
                        setExpirationFrom(e.detail.value ? moment(e.detail.value).format('YYYY-MM-DD') : null)
                        document.querySelector<HTMLIonPopoverElement>('#expiration-from-popover')?.dismiss()

                        if (expirationTo && !moment(e.detail.value).isBefore(expirationTo)) {
                          setExpirationTo(null)
                        }
                      }}
                      value={expirationFrom}
                    />
                  </IonPopover>

                  {expirationFrom ? (
                    <IonPopover
                      id="expiration-to-popover"
                      trigger="open-expiration-input-filter-to"
                      showBackdrop={false}
                      alignment="start"
                    >
                      <IonDatetime
                        showClearButton
                        presentation="date"
                        onIonChange={(e: any) => {
                          setExpirationTo(e.detail.value ? moment(e.detail.value).format('YYYY-MM-DD') : null)
                          document.querySelector<HTMLIonPopoverElement>('#expiration-to-popover')?.dismiss()
                        }}
                        value={expirationTo}
                        min={expirationFrom ? moment(expirationFrom).add(1, 'days').toISOString() : undefined}
                      />
                    </IonPopover>
                  ) : null}
                </IonItem>
              </IonAccordionGroup>

              <IonButton
                type="submit"
                className="save-filters"
              >
                {t('search')}
              </IonButton>

              <div className="reset-filters-container">
                <IonButton type="reset" className="reset-filters" expand="block" onClick={resetFilters}>
                  {t('action.reset_filters')}
                </IonButton>
              </div>
            </form>
          </IonList>
        </IonAccordion>
      )}

      {backdrop && advanced && <IonBackdrop onIonBackdropTap={() => toggleSearchBar()} />}
    </IonAccordionGroup>
  )
}

export default ToolbarSearch
