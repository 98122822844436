import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import axios from 'axios'
import { chevronBack } from 'ionicons/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { handleUserAction } from '../../firebase'
import { RECAPTCHA_RESET_PASSWORD_REQUEST_ACTION, URL_RESET_PASSWORD } from '../../utils/constants'
import useToast from '../../hooks/useToast'
import RecaptchaMessage from '../../components/common/RecaptchaMessage/RecaptchaMessage'
import { useRecaptcha } from '../../hooks/useRecaptcha'

const ResetPasswordRequest: React.FC = () => {
  const { t } = useTranslation()
  const [merchantId, setMerchantId] = useState('')
  const [busy, setBusy] = useState<boolean>(false)
  const { toast } = useToast()
  const { handleVerifyRecaptcha, isRecaptchaReady } = useRecaptcha(RECAPTCHA_RESET_PASSWORD_REQUEST_ACTION)

  async function handleResetPasswordRequest() {
    const { recaptchaToken, error } = await handleVerifyRecaptcha()
    if (error) {
      toast(t('error.generic'))
      setBusy(false)
      return
    }

    if (merchantId === '') {
      toast(t('error.merchant_id_required'))
      return
    }

    setBusy(true)

    await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: URL_RESET_PASSWORD,
      data: {
        credential: merchantId,
        recaptchaToken,
        recaptchaAction: RECAPTCHA_RESET_PASSWORD_REQUEST_ACTION
      }
    })
      .then(async (response) => {
        const verifyPasswordReset = await handleUserAction(response.data.link)
        if (verifyPasswordReset?.success) {
          toast(t('message.check_inbox'))
          setTimeout(() => {
            window.location.reload()
          }, 2500)
        } else {
          toast(t('error.reset_password_expired'))
        }
      })
      .catch(() => {
        toast(t('error.merchant_id_not_found'))
      })

    setBusy(false)
  }

  return (
    <IonPage className="login">
      {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton href="/login">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>{t('action.go_back')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="login-logo-container">
          <IonImg className="ion-padding login-logo" src="/assets/img/logo-blue.png" />
        </div>
        <IonInput
          className="ion-padding"
          type="text"
          placeholder={t('merchant_id_or_email') }
          onIonChange={(e: any) => setMerchantId(e.target.value) }
        />

        <IonButton expand="block" onClick={handleResetPasswordRequest} disabled={!isRecaptchaReady}>{t('action.reset_password')}</IonButton>
        <RecaptchaMessage />
      </IonContent>
    </IonPage>
  )
}

export default ResetPasswordRequest
