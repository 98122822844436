import './Login.css'
import { Link } from 'react-router-dom'
import { IonButton, IonContent, IonImg, IonInput, IonItemDivider, IonLoading, IonPage, IonText } from '@ionic/react'
import { useState } from 'react'

import { getUserData, loginUser, logoutUser, sendVerification } from '../../firebase'
import { useDispatch } from 'react-redux'
import { setUserData, setUserState } from '../../redux/actions'
import { useTranslation } from 'react-i18next'
import { User } from 'firebase/auth'
import axios from 'axios'
import InputPassword from '../../components/common/InputPassword/InputPassword'
import useToast from '../../hooks/useToast'
import { RECAPTCHA_LOGIN_ACTION, URL_USER } from '../../utils/constants'
import { useRecaptcha } from '../../hooks/useRecaptcha'
import RecaptchaMessage from '../../components/common/RecaptchaMessage/RecaptchaMessage'
import DocsLinks from '../../components/common/DocsLinks/DocsLinks'

const Login: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [credential, setCredential] = useState('')
  const [password, setPassword] = useState('')
  const [busy, setBusy] = useState<boolean>(false)
  const [unverifiedUser, setUnverifiedUser] = useState<User | null>(null)
  const { toast } = useToast()
  const { handleVerifyRecaptcha, isRecaptchaReady } = useRecaptcha(RECAPTCHA_LOGIN_ACTION)

  async function login() {
    setBusy(true)
    setUnverifiedUser(null)

    const { recaptchaToken, error } = await handleVerifyRecaptcha()
    if (error) {
      toast(t('error.login'))
      setBusy(false)
      return
    }

    await axios({
      method: 'GET',
      url: URL_USER,
      params: { credential, recaptchaToken, recaptchaAction: RECAPTCHA_LOGIN_ACTION }
    })
      .then(async (response) => {
        const result: any = await loginUser(response.data.email, password)

        if (!result.success) { // TODO improve firebase error messages. object like EditStore.tsx or i18n directly
          if (result?.error?.code === 'auth/too-many-requests') {
            toast(t('error.too_many_requests'))
          } else if (result?.error?.code === 'auth/network-request-failed') {
            toast(t('error.network_failed'))
          } else {
            console.error({ result })
            toast(t('error.login'))
          }
        } else if (result.user) {
          if (!result.user.emailVerified) {
            setUnverifiedUser(result.user)
            await logoutUser()
          } else {
            dispatch(setUserState(result.user))
            localStorage.setItem('AUTH_TOKEN', result.user.uid)
            if (response?.data?.identifier || response?.data?.merchant_id) {
              localStorage.setItem('USER_MERCHANT_ID', response?.data?.identifier || response?.data?.merchant_id)
            }
            dispatch(setUserData(await getUserData(credential)))
            window.location.reload()
          }
        }
      })
      .catch(() => {
        toast(t('error.login'))
      })

    setBusy(false)
  }

  function resendEmail() {
    sendVerification(unverifiedUser)
  }

  return (
    // TODO form
    <IonPage className="login">
      {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}
      <IonContent className="ion-padding">
        <div className='flex-space-between-container'>
          <div>
            <div className="login-logo-container">
              <IonImg className="ion-padding login-logo" src="/assets/img/logo-blue.png" />
            </div>

            <IonInput
              id="credential"
              className="ion-padding"
              placeholder={`${t('merchant_id')} / ${t('field.identifier')}`}
              type="text"
              onIonChange={(e: any) => setCredential(e.target.value)}
            />
            <InputPassword
              id="password"
              className="ion-padding"
              placeholder={t('password')}
              onIonChange={(e: any) => setPassword(e.target.value)}
            />

            <div className="reset-password">
              <p>
                {t('message.forgot_password')} <Link to="/reset_password">{t('action.recover')}</Link>
              </p>
            </div>

            <IonButton id="submit-login" expand="block" onClick={login} disabled={!isRecaptchaReady}>
              {t('login')}
            </IonButton>

            <RecaptchaMessage />

            <div className="register-container ion-padding ion-float-right">
              <p>
                {t('message.dont_have_account')} <Link to="/register">{t('register')}</Link>
              </p>
            </div>

            {unverifiedUser && (
              <>
                <IonItemDivider />
                <br />
                <br />
                <IonText>{t('message.verification_required', { email: unverifiedUser.email })}</IonText>
                <br />
                <br />
                <IonButton onClick={resendEmail} expand="block" className="ion-no-margin">
                  {t('message.resend_verification_email')}
                </IonButton>
              </>
            )}
          </div>

          <DocsLinks />
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Login
