import './GeneratePayment.css'
import {
  IonButton,
  IonContent,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage
} from '@ionic/react'
import axios from 'axios'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MultiplePayment, User } from '../../interfaces/interfaces'
import {
  GENERATE_PAYMENT_IMPERSONATE,
  IMPERSONATE_DATA,
  URL_ADD_PAYMENT_BY_MULTIPLE_PAYMENT,
  URL_GET_MULTIPLE_PAYMENT
} from '../../utils/constants'
import { validateMultiplePaymentForm } from '../../utils/validations'
import useToast from '../../hooks/useToast'
import moment from 'moment'
import { useSelector } from 'react-redux'

export const GeneratePayment: React.FC = () => {
  const currentUser: User | null = useSelector((state: any) => state.userData)
  const [multiplePayment, setMultiplePayment] = useState<MultiplePayment | null>(null)
  const [busy, setBusy] = useState(true)
  const seed = useParams<{ seed: string }>().seed
  const name = useRef<string>('')
  const email = useRef<string>('')
  const phone = useRef<string>('')
  const { toast } = useToast()

  useEffect(() => {
    const impersonateData = localStorage.getItem(IMPERSONATE_DATA)
    if (currentUser && currentUser.is_admin && currentUser.impersonateId && impersonateData && seed === GENERATE_PAYMENT_IMPERSONATE) {
      setMultiplePayment({ ...JSON.parse(impersonateData), User: currentUser })
      setBusy(false)
    } else if (seed !== GENERATE_PAYMENT_IMPERSONATE) {
      axios({
        method: 'GET',
        url: `${URL_GET_MULTIPLE_PAYMENT}/${seed}`,
        headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' }
      })
        .then((response) => {
          setMultiplePayment(response.data)
        })
        .finally(() => {
          setBusy(false)
        })
    }
  }, [seed, currentUser])

  function redirect() {
    window.location.href = '/'
  }

  function onSubmitForm(e: any) {
    e.preventDefault()
    setBusy(true)

    const formData = {
      name: name.current,
      email: email.current,
      phone: phone.current
    }

    const { success, message } = validateMultiplePaymentForm(formData)

    if (!success) {
      toast(message)
      setBusy(false)
      return false
    }

    const impersonateData = localStorage.getItem(IMPERSONATE_DATA)

    axios({
      method: 'POST',
      url: URL_ADD_PAYMENT_BY_MULTIPLE_PAYMENT,
      headers: currentUser && currentUser.is_admin && currentUser.impersonateId && impersonateData ? { Authorization: localStorage.getItem('AUTH_TOKEN') || '' } : {},
      data: {
        id: multiplePayment?.id,
        user_id: multiplePayment?.User?.id,
        ...formData,
        impersonateData
      }
    })
      .then((response) => {
        setBusy(false)
        if (currentUser && currentUser.is_admin && currentUser.impersonateId) {
          toast(t('impersonate.data_saved'))
        } else {
          window.location.href = response.data.url
        }
      })
      .catch(() => {
        toast(t('error.generic'))
      })
  }

  return (
    <IonPage className="generate-payment-page">
      <IonContent className="ion-padding">
        {busy ? (
          <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />
        ) : multiplePayment ? (
          <>
            {multiplePayment?.deleted || (currentUser?.is_admin && currentUser.impersonateId && JSON.parse(localStorage.getItem(IMPERSONATE_DATA)!).deleted) ? (
              <>
                <div className="login-logo-container">
                  <IonImg className="ion-padding login-logo" src="/assets/img/logo-blue.png" />
                </div>
                <div style={{ textAlign: 'center', marginBottom: 50 }}>
                  <h3>{t('message.multiple_payment_deleted')}</h3>
                </div>
                <IonButton expand="block" onClick={redirect}>
                  {t('action.go_to_login')}
                </IonButton>
              </>
            ) : moment(multiplePayment?.expiration, 'YYYY-MM-DD').isBefore(moment(), 'day')  || (currentUser?.is_admin && currentUser.impersonateId && JSON.parse(localStorage.getItem(IMPERSONATE_DATA)!).isExpired) ? (
              <>
                <div className="login-logo-container">
                  <IonImg className="ion-padding login-logo" src="/assets/img/logo-blue.png" />
                </div>
                <div style={{ textAlign: 'center', marginBottom: 50 }}>
                  <h3>{t('message.multiple_payment_expired')}</h3>
                </div>
                <IonButton expand="block" onClick={redirect}>
                  {t('action.go_to_login')}
                </IonButton>
              </>
            ) : (
              <>
                <h2 className="generate-payment-title">{t('message.welcome_generate_payment')}</h2>
                <h3>
                  {t('message.generate_payment_info', {
                    store: multiplePayment?.User?.name,
                    description: multiplePayment?.description
                  })}
                </h3>
                <h4>
                  {t('message.generate_payment_info2', {
                    store: multiplePayment?.User?.name
                  })}
                </h4>

                <form className="ion-padding generate-payment-container" onSubmit={onSubmitForm}>
                  <IonList lines="full" className="ion-no-margin ion-no-padding">
                    <IonItem>
                      <IonLabel position="stacked">
                        {t('field.name')}
                        {' '}
                        <span className='required'>*</span>
                      </IonLabel>
                      <IonInput
                        required
                        name="name"
                        type="text"
                        onInput={(e: any) => {
                          name.current = e.target.value
                        }}
                        value={name.current}
                      />
                    </IonItem>
                    <IonItem className="ion-margin-top">
                      <IonLabel position="stacked">{t('field.email')}</IonLabel>
                      <IonInput
                        name="email"
                        inputMode="email"
                        type="email"
                        onInput={(e: any) => {
                          email.current = e.target.value
                        }}
                        value={email.current}
                      />
                    </IonItem>
                    <IonItem className="ion-margin-top">
                      <IonLabel position="stacked">{t('field.phone')}</IonLabel>
                      <IonInput
                        name="phone"
                        inputMode="decimal"
                        type="number"
                        step="any"
                        onInput={(e: any) => {
                          phone.current = e.target.value
                        }}
                        value={phone.current}
                      />
                    </IonItem>
                  </IonList>
                  <IonButton expand="block" type="submit" className="ion-margin-top">
                    {t('action.create_payment')}
                  </IonButton>
                </form>
              </>
            )}
          </>
        ) : (
          <>
            <div className="login-logo-container">
              <IonImg className="ion-padding login-logo" src="/assets/img/logo-blue.png" />
            </div>
            <div style={{ textAlign: 'center', marginBottom: 50 }}>
              <h3>{t('message.invalid_url')}</h3>
            </div>
            <IonButton expand="block" onClick={redirect}>
              {t('action.go_to_login')}
            </IonButton>
          </>
        )}
      </IonContent>
    </IonPage>
  )
}

export default GeneratePayment
