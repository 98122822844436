import './RecaptchaMessage.css'
import { Trans, useTranslation } from 'react-i18next'

function RecaptchaMessage() {
  const { t } = useTranslation()

  return (
    <div className="recaptcha-message">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
      <Trans i18nKey="recaptcha_message" components={{ a: <a /> }} />
    </div>
  )
}

export default RecaptchaMessage
