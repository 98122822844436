import moment from 'moment'
import { User, MultiplePayment, SearchItemFilters } from '../interfaces/interfaces'
import {
  DEFAULT_VALUE,
  STATUS_CANCELLED_CECA_TEXT,
  STATUS_CANCELLED_ID,
  STATUS_COMPLETED_CECA_TEXT,
  STATUS_COMPLETED_ID,
  STATUS_DENIED_CECA_TEXT,
  STATUS_DENIED_ID,
  STATUS_EXPIRED_CECA_TEXT,
  STATUS_EXPIRED_ID,
  STATUS_SENT_CECA_TEXT,
  STATUS_SENT_ID
} from './constants'

export function getMonthName(date: string) {
  const dt = new Date(date)
  const month = dt.toLocaleString('default', { month: 'long' })
  return month.charAt(0).toUpperCase() + month.slice(1)
}

export function getStatusById(id: number) {
  switch (id) {
    case STATUS_SENT_ID:
      return STATUS_SENT_CECA_TEXT
    case STATUS_CANCELLED_ID:
      return STATUS_CANCELLED_CECA_TEXT
    case STATUS_COMPLETED_ID:
      return STATUS_COMPLETED_CECA_TEXT
    case STATUS_EXPIRED_ID:
      return STATUS_EXPIRED_CECA_TEXT
    case STATUS_DENIED_ID:
      return STATUS_DENIED_CECA_TEXT
    default:
      return STATUS_SENT_CECA_TEXT
  }
}

/**
 * A function to check if the user is an admin impersonating or is a store
 * @param user User to check if is impersonating or is a store
 * @returns {boolean}
 * @example Is admin? -> !isImpersonatingOrStore(user)
 */
export function isImpersonatingOrStore(user: User | null): boolean {
  return !user?.is_admin || (user?.is_admin && user?.impersonateId) ? true : false
}

/**
 * A function to get the merchant id from the localStorage / user / admin impersonating store
 * @param user User to get the merchant id
 * @returns {string}
 */
export function getUserMerchantId(user: User | null): string {
  return localStorage.getItem('USER_MERCHANT_ID') || user?.merchant_id || ''
}

export function getLocalStorageUserToggle(key: string): boolean {
  return localStorage.getItem(key) ? Boolean(Number(localStorage.getItem(key))) : false
}

export function isDeletedOrExpired(multiplePayment: MultiplePayment) {
  return multiplePayment.deleted || moment(multiplePayment.expiration, 'YYYY-MM-DD').isBefore(moment(), 'day')
}

export function filtersAreEmpty(filters: SearchItemFilters) {
  return Object.values(filters).every((val) => val === null || val === '' || val === DEFAULT_VALUE)
}

export function formatEuroNumber(numStr: string | number) {
  if (typeof numStr === 'number') numStr = numStr.toString()
  numStr = numStr.replace('€', '').replace(',', '.')
  let num = parseFloat(numStr)
  let formattedNum = num.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
  return formattedNum.replace(/\s/g, '')
}

export function getCookie(name: string) {
  const cookies = document.cookie.split('; ')
  const cookie = cookies.find((c) => c.startsWith(name))
  return cookie ? cookie.split('=')[1] : null
}

export function setCookie(name: string, value: string, days: number) {
  const expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + days)
  document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`
}
