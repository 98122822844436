import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentAdmin, setEditingAdmin } from '../../../redux/actions'
import { chevronBack } from 'ionicons/icons'
import axios from 'axios'
import { User } from '../../../interfaces/interfaces'
import useToast from '../../../hooks/useToast'
import { URL_UPDATE_ADMIN } from '../../../utils/constants'

export const EditAdmin: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const adminData: User = useSelector((state: any) => state.admins.current)
  const [name, setName] = useState(adminData.name)
  const [email, setEmail] = useState(adminData.email)
  const [busy, setBusy] = useState<boolean>(false)
  const { toast } = useToast()

  const handleUpdateAdminError: any = {
    'auth/email-already-exists': () => toast(t('error.email_already_exists')),
    default: () => toast(t('error.generic'))
  }

  const handleDismiss = () => {
    dispatch(setEditingAdmin(false))
  }

  const updateAdmin = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setBusy(true)

    const data = {
      uid: adminData.uid,
      name,
      currentEmail: adminData.email,
      email
    }

    await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: URL_UPDATE_ADMIN,
      data
    })
      .then(() => {
        dispatch(setCurrentAdmin(null))
        dispatch(setEditingAdmin(false))
      })
      .catch((error) => {
        (handleUpdateAdminError[error.response.data.code] || handleUpdateAdminError.default)()
        setBusy(false)
      })
  }

  return (
    <IonPage className='edit-admin-page'>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle> {t('title.edit_admin')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

        <div>
          <form onSubmit={updateAdmin}>
            <IonList lines="full" className="ion-no-margin ion-no-padding">
              <IonItem>
                <IonLabel position="stacked">{t('field.name')}</IonLabel>
                <IonInput
                  required
                  autocomplete="new-password"
                  type="text"
                  onIonChange={(e: any) => setName(e.target.value)}
                  value={name}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t('field.email')}</IonLabel>
                <IonInput
                  required
                  autocomplete="new-password"
                  type="email"
                  onIonChange={(e: any) => setEmail(e.target.value)}
                  value={email}
                />
              </IonItem>
            </IonList>

            <div className="ion-padding">
              <IonButton expand="block" type="submit" className="ion-no-margin">{t('action.save_changes')}</IonButton>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default EditAdmin
