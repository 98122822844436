import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setEditingUserProfile } from '../../../redux/actions'
import { chevronBack } from 'ionicons/icons'
import { updateUserPassword } from '../../../firebase'
import InputPassword from '../../common/InputPassword/InputPassword'
import useToast from '../../../hooks/useToast'
import { PASSWORD_REGEX } from '../../../utils/constants'

export const EditProfile: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [busy, setBusy] = useState<boolean>(false)
  const { toast } = useToast()

  const handleDismiss = () => {
    dispatch(setEditingUserProfile(false))
  }

  const updatePassword = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    if (currentPassword === '') {
      toast(t('error.current_password_mismatch'))
    } else if (newPassword !== confirmNewPassword) {
      toast(t('error.confirm_password_mismatch'))
    } else if (!newPassword.match(PASSWORD_REGEX)) {
      toast(t('error.password_validation'))
    } else {
      setBusy(true)
      const result = await updateUserPassword(currentPassword, newPassword)

      if (!result?.success) {
        setBusy(false)
        setCurrentPassword('')
        setNewPassword('')
        setConfirmNewPassword('')
        result?.error === 'auth/wrong-password' ? toast(t('error.current_password_mismatch')) : toast(t('error.generic'))
      } else {
        window.location.reload()
      }
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle> {t('title.edit_profile')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

        <div>
          <form onSubmit={updatePassword}>
            <IonList lines="full" className="ion-no-margin ion-no-padding">
              <IonItem>
                <IonLabel position="stacked">{t('field.current_password')}</IonLabel>
                <InputPassword
                  required
                  autocomplete="new-password"
                  onIonChange={(e: any) => setCurrentPassword(e.target.value)}
                  value={currentPassword}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t('field.new_password')}</IonLabel>
                <InputPassword
                  required
                  autocomplete="new-password"
                  onIonChange={(e: any) => setNewPassword(e.target.value)}
                  value={newPassword}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t('field.confirm_new_password')}</IonLabel>
                <InputPassword
                  required
                  autocomplete="new-password"
                  onIonChange={(e: any) => setConfirmNewPassword(e.target.value)}
                  value={confirmNewPassword}
                />
              </IonItem>
            </IonList>

            <div className="ion-padding">
              <IonButton expand="block" type="submit" className="ion-no-margin">{t('action.update_password')}</IonButton>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default EditProfile
