import { DEFAULT_SEARCH_ITEM_FILTERS } from '../utils/constants'

export const defaultState = {
  user: null,
  profile: {
    editing: false,
    creatingSubUser: false,
    currentSubUser: null
  },
  stores: {
    current: null,
    creating: false,
    editing: false,
    refresh: false,
    list: []
  },
  admins: {
    current: null,
    creating: false,
    editing: false,
    list: []
  },
  payments: {
    current: null,
    creating: false,
    list: [],
    dbList: null,
    dbPage: 1,
    searchFilters: DEFAULT_SEARCH_ITEM_FILTERS
  },
  multiple_payments: {
    current: null
  },
  favorites: {
    current: null,
    creating: false,
    list: []
  },
  userData: null,
  pwaEvent: null,
  descriptionFilter: null,
  notifications: {
    isLoading: true,
    list: [],
    count: 0,
    unreadCount: 0,
    refetch: () => {}
  }
}

export default function(state = defaultState, { type, payload }: { type: string; payload: any }): any {
  switch (type) {
    case 'SET_PWA_EVENT':
      return {
        ...state,
        pwaEvent: payload
      }
    case 'SET_USER_STATE':
      return {
        ...state,
        user: payload
      }
    case 'SET_USER_DATA':
      return {
        ...state,
        userData: payload
      }
    case 'SET_CURRENT_STORE':
      return {
        ...state,
        stores: {
          current: payload
        }
      }
    case 'SET_CREATING_USER':
      return {
        ...state,
        stores: {
          creating: payload
        }
      }
    case 'SET_EDITING_STORE':
      return {
        ...state,
        stores: {
          ...state.stores,
          editing: payload
        }
      }
    case 'REFRESH_STORES_LIST':
      return {
        ...state,
        stores: {
          refresh: payload
        }
      }
    case 'SET_STORES_LIST':
      return {
        ...state,
        stores: {
          list: payload
        }
      }
    case 'SET_CURRENT_ADMIN':
      return {
        ...state,
        admins: {
          current: payload
        }
      }
    case 'SET_CREATING_ADMIN':
      return {
        ...state,
        admins: {
          creating: payload
        }
      }
    case 'SET_EDITING_ADMIN':
      return {
        ...state,
        admins: {
          ...state.admins,
          editing: payload
        }
      }
    case 'SET_ADMINS_LIST':
      return {
        ...state,
        admins: {
          list: payload
        }
      }
    case 'SET_CURRENT_PAYMENT':
      return {
        ...state,
        payments: {
          ...state.payments,
          current: payload
        }
      }
    case 'SET_CREATING_PAYMENT':
      return {
        ...state,
        payments: {
          ...state.payments,
          creating: payload
        }
      }
    case 'REFRESH_PAYMENTS_LIST':
      return {
        ...state,
        payments: {
          ...state.payments,
          list: payload,
          dbList: null
        }
      }
    case 'REFRESH_DB_PAYMENTS_LIST':
      return {
        ...state,
        payments: {
          ...state.payments,
          dbList: payload
        }
      }
    case 'SET_EDITING_USER_PROFILE':
      return {
        ...state,
        profile: {
          editing: payload
        }
      }
    case 'SET_CREATING_SUB_USER':
      return {
        ...state,
        profile: {
          creatingSubUser: payload
        }
      }
    case 'SET_CURRENT_SUB_USER':
      return {
        ...state,
        profile: {
          currentSubUser: payload
        }
      }
    case 'SET_CREATING_FAVORITE_USER':
      return {
        ...state,
        favorites: {
          creating: payload
        }
      }
    case 'SET_CURRENT_FAVORITE_USER':
      return {
        ...state,
        favorites: {
          current: payload
        }
      }
    case 'SET_CURRENT_MULTIPLE_PAYMENT':
      return {
        ...state,
        multiple_payments: {
          current: payload
        }
      }
    case 'SET_DESCRIPTION_FILTER':
      return {
        ...state,
        descriptionFilter: payload
      }
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        notifications: payload
      }
    case 'SET_DB_PAGE':
      return {
        ...state,
        payments: {
          ...state.payments,
          dbPage: payload
        }
      }
    case 'SET_SEARCH_FILTERS':
      return {
        ...state,
        payments: {
          ...state.payments,
          searchFilters: payload
        }
      }
    default:
      return state
  }
}
