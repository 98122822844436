import {
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonPage,
  useIonViewDidEnter
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import PaymentsList from '../../components/payments/PaymentList/PaymentsList'
import { useDispatch, useSelector } from 'react-redux'
import { add } from 'ionicons/icons'
import { refreshDbPaymentsList, setCreatingPayment, setDbPage, setSearchFilters } from '../../redux/actions'
import AddPayment from '../../components/payments/AddPayment/AddPayment'
import Payment from '../../components/payments/Payment/Payment'
import ToolbarSearch from '../../components/common/ToolbarSearch/ToolbarSearch'
import axios from 'axios'
import { Payment as PaymentInterface, SearchItemFilters, User } from '../../interfaces/interfaces'
import { DEFAULT_VALUE, URL_SEARCH_PAYMENTS } from '../../utils/constants'
import { useState } from 'react'
import { filtersAreEmpty, isImpersonatingOrStore } from '../../utils/helper'

export const Payments: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentUser: User | null = useSelector((state: any) => state.userData)
  const currentPayment: PaymentInterface | null = useSelector((state: any) => state.payments.current)
  const creatingPayment: PaymentInterface | boolean = useSelector((state: any) => state.payments.creating)
  const addPayment = () => dispatch(setCreatingPayment(true))
  const [showStatistics, setShowStatistics] = useState(false)
  const [storeFilter, setStoreFilter] = useState<number>(DEFAULT_VALUE)
  const [subUserFilter, setSubUserFilter] = useState<number>(DEFAULT_VALUE)
  const descriptionFilter: string | null = useSelector((state: any) => state.descriptionFilter)

  const searchItem = async (event: any, filters: SearchItemFilters) => {
    if (event) event.preventDefault()

    dispatch(setDbPage(1))
    dispatch(setSearchFilters(filters))

    const content = document.querySelector('ion-content')
    if (content) content.scrollToTop(0)

    if (isImpersonatingOrStore(currentUser) && filters && filtersAreEmpty(filters)) {
      dispatch(refreshDbPaymentsList(null))
      return
    }

    if (filters && Object.values(filters).every((val) => val === null || val === '')) {
      dispatch(refreshDbPaymentsList(null))
      return
    }

    await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      params: { merchant_id: localStorage.getItem('USER_MERCHANT_ID') || '' },
      url: URL_SEARCH_PAYMENTS,
      data: { ...filters, page: 1 }
    }).then((response) => {
      dispatch(refreshDbPaymentsList(response.data))
    }).catch(() => {
      dispatch(refreshDbPaymentsList([]))
    })
  }

  const showingComponent = currentPayment || creatingPayment

  useIonViewDidEnter(() => { // TODO refactor this
    document.querySelector('.payments-page')?.classList.remove('ion-page-hidden', 'ion-page-invisible')
    document.querySelector('.payments-page .ion-page')?.classList.remove('ion-page-hidden', 'ion-page-invisible')
  })

  return (
    <IonPage className='payments-page'>
      {!showingComponent &&
        <IonHeader>
          <ToolbarSearch
            title={showStatistics ? t('statistics') : t('payments')}
            searchItem={searchItem}
            showStatistics={showStatistics}
            setShowStatistics={setShowStatistics}
            setStoreFilter={setStoreFilter}
            setSubUserFilter={setSubUserFilter}
            descriptionFilter={descriptionFilter}
            advanced
          />
        </IonHeader>
      }
      <IonContent className={showStatistics ? 'no-scroll' : ''}>
        {!showingComponent && (
          <PaymentsList
            showStatistics={showStatistics}
            storeFilter={storeFilter}
            subUserFilter={subUserFilter}
            currentUser={currentUser}
          />
        )}
        {currentPayment && <Payment />}
        {creatingPayment && <AddPayment />}
        {!showingComponent && currentUser && isImpersonatingOrStore(currentUser) && (
          <IonFab onClick={addPayment} horizontal="end" vertical="bottom" slot="fixed" className={`add-payment-button ${showStatistics ? 'bounce-hide' : 'bounce-show'}`}>
            <IonFabButton>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
    </IonPage>
  )
}

export default Payments
