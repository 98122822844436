import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react'
import axios from 'axios'
import { t } from 'i18next'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FavoritesList from '../../components/favorites/FavoritesList/FavoritesList'
import FavoriteUserForm from '../../components/favorites/FavoriteUserForm/FavoriteUserForm'
import useToast from '../../hooks/useToast'
import { FavoriteUser, FavoriteUserFormData, User } from '../../interfaces/interfaces'
import { setCreatingFavoriteUser, setCurrentFavoriteUser } from '../../redux/actions'
import { URL_SAVE_FAVORITE_USER } from '../../utils/constants'
import getFavoriteUsers from '../../utils/getFavoriteUsers'
import { validateFavoriteUserForm } from '../../utils/validations'

export const Favorites: React.FC = () => {
  const currentUser: User = useSelector((state: any) => state.userData)
  const creatingFavoriteUser: boolean = useSelector((state: any) => state.favorites.creating)
  const editingFavoriteUser: FavoriteUser | null = useSelector((state: any) => state.favorites.current)
  const favoritesList = creatingFavoriteUser || editingFavoriteUser
  const dispatch = useDispatch()
  const { toast } = useToast()
  const defaultShareMethod = 'email'
  const emailInput = useState<boolean>(
    editingFavoriteUser ? editingFavoriteUser.shareMethod.toString() === defaultShareMethod : true
  )

  const handleFavoriteError: any = { // TODO utils function / hook that handles errors with toasts
    'store/favorite-already-exists': () => toast(t('error.favorite_already_exists')),
    default: () => toast(t('error.generic'))
  }

  useIonViewDidEnter(() => {
    // TODO refactor this
    document.querySelector('.favorites-page')?.classList.remove('ion-page-hidden', 'ion-page-invisible')
    document.querySelector('.favorites-page .ion-page')?.classList.remove('ion-page-hidden', 'ion-page-invisible')
  })

  const handleDismiss = () => {
    dispatch(editingFavoriteUser ? setCurrentFavoriteUser(null) : setCreatingFavoriteUser(false))
  }

  const saveFavoriteUser = async (data: FavoriteUserFormData) => {
    const { success, formData, message } = validateFavoriteUserForm(data, emailInput[0])
    if (!success) {
      toast(message)
      return false
    }

    if (editingFavoriteUser) formData.id = editingFavoriteUser.id

    await axios({
      method: 'POST',
      url: URL_SAVE_FAVORITE_USER,
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      data: formData
    })
      .then(() => {
        if (currentUser.is_admin && currentUser.impersonateId) toast(t('impersonate.data_saved'))
        handleDismiss()
      })
      .catch((error) => { // TODO create a fetch hook to catch errors with the handleError object above (???)
        (handleFavoriteError[error.response?.data?.code] || handleFavoriteError.default)()
      })
  }

  const getFavoriteUsersList = async (): Promise<FavoriteUser[]> => {
    const favoriteUsersList = await getFavoriteUsers()
    if (!favoriteUsersList) toast(t('error.generic'))
    return favoriteUsersList
  }

  return (
    <IonPage className="favorites-page">
      <IonContent>
        {!favoritesList
          ? (
            <FavoritesList getFavoriteUsers={getFavoriteUsersList} />
          )
          : (
            <FavoriteUserForm
              onSubmit={saveFavoriteUser}
              handleDismiss={handleDismiss}
              emailInput={emailInput}
              defaultShareMethod={defaultShareMethod}
              currentUser={currentUser}
            />
          )}
      </IonContent>
    </IonPage>
  )
}

export default Favorites
