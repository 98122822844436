import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonChip,
  IonItem,
  IonLabel
} from '@ionic/react'
import { chevronBack } from 'ionicons/icons'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentMultiplePayment } from '../../../redux/actions'
import { MultiplePayment as MultiplePaymentInterface, User } from '../../../interfaces/interfaces'
import { t } from 'i18next'
import moment from 'moment'
import { APP_URL, GENERATE_PAYMENT_IMPERSONATE, URL_DELETE_MULTIPLE_PAYMENT } from '../../../utils/constants'
import ConfirmDeleteModal from '../../common/ConfirmDeleteModal/ConfirmDeleteModal'
import PaymentShareButtons from '../../payments/PaymentShareButtons/PaymentShareButtons'
import SendToFavoriteUserModal from '../../payments/SendToFavoriteUserModal/SendToFavoriteUserModal'
import getFavoriteUsers from '../../../utils/getFavoriteUsers'
import QRCode from 'qrcode.react'
import { formatEuroNumber, isDeletedOrExpired } from '../../../utils/helper'

export const MultiplePayment: React.FC = () => {
  const multiplePayment: MultiplePaymentInterface = useSelector((state: any) => state.multiple_payments.current)
  const user: User | null = useSelector((state: any) => state.userData)
  const dispatch = useDispatch()

  function handleDismiss() {
    dispatch(setCurrentMultiplePayment(null))
  }

  const url = `${APP_URL}/generate_payment/${user?.is_admin && user?.impersonateId ? GENERATE_PAYMENT_IMPERSONATE : multiplePayment.seed}`
  const shareTitle = t('message.payment_share_title', { from: user?.name })
  const shareMessage = t('message.payment_share_text', {
    desc: multiplePayment.description,
    amount: formatEuroNumber(multiplePayment.amount),
    expiration: multiplePayment.expiration ? moment(multiplePayment.expiration).format('DD/MM/YYYY') : '',
    token: t('message.no_token_needed'),
    url
  })

  return (
    <IonPage className="details">
      <IonHeader color="primary">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle>{multiplePayment.description}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel>{t('field.amount')}</IonLabel>
          <IonChip>{formatEuroNumber(multiplePayment.amount)}</IonChip>
        </IonItem>
        {multiplePayment.expiration && (
          <IonItem>
            <IonLabel>{t('field.expiration')}</IonLabel>
            <IonChip>
              {multiplePayment.expiration
                ? moment(multiplePayment.expiration).format(process.env.REACT_APP_DATE_FORMAT)
                : ''}
            </IonChip>
          </IonItem>
        )}

        {!isDeletedOrExpired(multiplePayment) ? (
          <ConfirmDeleteModal
            id={multiplePayment.id || 0}
            url={URL_DELETE_MULTIPLE_PAYMENT}
            dispatchFn={() => setCurrentMultiplePayment(null)}
            title="action.delete_multiple_payment"
            message="message.delete_multiple_payment_confirmation"
            currentUser={user}
          />
        ) : null}

        {!isDeletedOrExpired(multiplePayment) ? (
          <div className="qr-code-container">
            <div>
              <QRCode
                value={url}
                size={144}
                renderAs={'svg'}
              />
            </div>
          </div>
        ) : null}
      </IonContent>

      {!isDeletedOrExpired(multiplePayment) ? (
        <>
          <PaymentShareButtons payment={multiplePayment} message={{ shareTitle, shareMessage }} currentUser={user} />

          <SendToFavoriteUserModal
            message={{ shareTitle, shareMessage }}
            getFavoriteUsers={getFavoriteUsers}
          />
        </>
      ) : null}
    </IonPage>
  )
}

export default MultiplePayment
