import './Dashboard.css'
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route } from 'react-router'
import { fileTrayFull, heart, person, settings, storefront, swapHorizontal } from 'ionicons/icons'
import Profile from '../Profile/Profile'
import Stores from '../Stores/Stores'
import Admins from '../Admins/Admins'
import Payments from '../Payments/Payments'
import Favorites from '../Favorites/Favorites'
import { IonReactRouter } from '@ionic/react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Notification, NotificationsState, User } from '../../interfaces/interfaces'
import MultiplePayments from '../MultiplePayments/MultiplePayments'
import { IMPERSONATE_DARK_MODE, URL_GET_NOTIFICATIONS } from '../../utils/constants'
import { getLocalStorageUserToggle } from '../../utils/helper'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { setNotifications } from '../../redux/actions'
import { useEffect } from 'react'

export const Dashboard: React.FC = () => {
  const { t } = useTranslation()
  const userData: User | null = useSelector((state: any) => state.userData)
  const isAdmin = userData?.is_admin && !userData?.impersonateId
  const dispatch = useDispatch()
  const { unreadCount: notificationsUnreadCount } = useSelector((state: { notifications: NotificationsState }) => state.notifications)

  if (getLocalStorageUserToggle(IMPERSONATE_DARK_MODE) || userData?.dark_mode) document.body.classList.add('dark')

  const onIonTabsDidChange = async (event: CustomEvent) => {
    const tabs = Object.values(document.querySelectorAll('ion-tab-button')).map((tab) => tab.getAttribute('tab'))
    tabs.forEach((tab) => {
      if (tab === event.detail.tab) {
        document.querySelector(`.${tab}-page`)?.classList.remove('ion-page-hidden', 'ion-page-invisible')
        document.querySelector(`.${tab}-page .ion-page`)?.classList.remove('ion-page-hidden', 'ion-page-invisible')
      } else {
        document.querySelector(`.${tab}-page`)?.classList.add('ion-page-hidden', 'ion-page-invisible')
        document.querySelector(`.${tab}-page .ion-page`)?.classList.add('ion-page-hidden', 'ion-page-invisible')
      }
    })
  }

  const getNotifications = async (): Promise<Notification[]> => {
    return await axios({
      method: 'GET',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: URL_GET_NOTIFICATIONS
    })
      .then((response) => {
        const notifications = response.data.length ? response.data : []
        return notifications
      })
      .catch(() => {
        return null
      })
  }

  const { data: notificationsList, isLoading, refetch } = useQuery<Notification[]>({
    queryKey: ['getNotifications'],
    queryFn: () => getNotifications()
  })

  useEffect(() => {
    dispatch(setNotifications({
      isLoading: isLoading ?? true,
      list: notificationsList || [],
      count: notificationsList?.length || 0,
      unreadCount: notificationsList?.filter((notification) => !notification.readAt).length || 0,
      refetch: refetch || (() => {})
    }))
  }, [notificationsList])

  return isAdmin == null
    ? null
    : (
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            {isAdmin ? (
              <>
                <Route exact path="/stores" component={Stores} />
                <Route exact path="/admins" component={Admins} />
                <Route exact path="/payments" component={Payments} />
              </>
            ) : (
              <>
                <Route exact path="/payments" component={Payments} />
                <Route exact path="/multiple_payments" component={MultiplePayments} />
                <Route exact path="/favorites" component={Favorites} />
              </>
            )}
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/dashboard">
              <Redirect to={isAdmin ? '/stores' : '/payments'} />
            </Route>
          </IonRouterOutlet>

          <IonTabBar slot="bottom" onIonTabsDidChange={onIonTabsDidChange}>
            {isAdmin && (
              <IonTabButton tab="stores" href="/stores" className='tab-stores'>
                <IonIcon icon={storefront} />
                <IonLabel>{t('stores')}</IonLabel>
              </IonTabButton>
            )}
            {isAdmin && (
              <IonTabButton tab="admins" href="/admins" className='tab-admins'>
                <IonIcon icon={settings} />
                <IonLabel>{t('admins')}</IonLabel>
              </IonTabButton>
            )}

            <IonTabButton tab="payments" href="/payments" className='tab-payments'>
              <IonIcon icon={swapHorizontal} />
              <IonLabel>{t('payments')}</IonLabel>
            </IonTabButton>

            {!isAdmin && (
              <IonTabButton tab="multiple_payments" href="/multiple_payments" className='tab-multiple_payments'>
                <IonIcon icon={fileTrayFull} />
                <IonLabel>{t('multiple_payments')}</IonLabel>
              </IonTabButton>
            )}
            {!isAdmin && (
              <IonTabButton tab="favorites" href="/favorites" className='tab-favorites'>
                <IonIcon icon={heart} />
                <IonLabel>{t('favorites')}</IonLabel>
              </IonTabButton>
            )}

            <IonTabButton tab="profile" href="/profile" className='tab-profile'>
              <IonIcon icon={person} />
              {notificationsUnreadCount > 0 ? (
                <div className="notifications-count tab-router">
                  <span>{notificationsUnreadCount}</span>
                </div>
              ) : null}
              <IonLabel>{userData?.is_admin && userData?.impersonateId ? userData?.merchant_id : t('profile')}</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    )
}

export default Dashboard
