import './Payment.css'
import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { chevronBack, logoEuro } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPayment } from '../../../redux/actions'
import moment from 'moment'
import QRCode from 'qrcode.react'
import PaymentShareButtons from '../PaymentShareButtons/PaymentShareButtons'
import SendToFavoriteUserModal from '../SendToFavoriteUserModal/SendToFavoriteUserModal'
import getFavoriteUsers from '../../../utils/getFavoriteUsers'
import { Payment as PaymentInterface, User } from '../../../interfaces/interfaces'
import { APP_URL, EASYPAY_URL, STATUS_SENT_CECA_TEXT } from '../../../utils/constants'
import { formatEuroNumber, isImpersonatingOrStore } from '../../../utils/helper'

export const Payment: React.FC = () => {
  const payment: PaymentInterface | null = useSelector((state: any) => state.payments.current)
  const user: User | null = useSelector((state: any) => state.userData)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  function handleDismiss() {
    dispatch(setCurrentPayment(null))
  }

  if (!payment) return null

  const shareTitle = t('message.payment_share_title', { from: user?.name })
  const paymentUrl = user?.is_admin && user?.impersonateId ? APP_URL : (payment.token ? EASYPAY_URL : payment.url)
  const shareMessage = t('message.payment_share_text', {
    desc: payment.description,
    amount: formatEuroNumber(payment.amount),
    expiration: payment.expiration ? moment(payment.expiration).format('DD/MM/YYYY') : '',
    token: payment.token || t('message.no_token_needed'),
    url: paymentUrl
  })

  return (
    <IonPage className="details">
      <IonHeader color="primary">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle>{payment.description}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!payment.url && (
          <div className="payment-without-url">
            <p>{t('message.payment_without_url')}</p>
          </div>
        )}
        {!isImpersonatingOrStore(user) && (
          <>
            <IonItem>
              <IonLabel>{t('store')}</IonLabel>
              <IonChip>{payment.User.parentStore?.name ?? payment.User.name}</IonChip>
            </IonItem>
            <IonItem>
              <IonLabel>{t('field.merchant_id')}</IonLabel>
              <IonChip>{payment.User.parentStore?.merchant_id ?? payment.User.merchant_id}</IonChip>
            </IonItem>
          </>
        )}
        {payment.numOperation && (
          <IonItem>
            <IonLabel>{t('field.num_operation')}</IonLabel>
            <IonChip>{payment.numOperation}</IonChip>
          </IonItem>
        )}
        <IonItem>
          <IonLabel>{t('field.date')}</IonLabel>
          <IonChip>{moment(payment.date, 'DD/MM/YYYY').format(process.env.REACT_APP_DATE_FORMAT)}</IonChip>
        </IonItem>
        <IonItem>
          <IonLabel>{t('field.status')}</IonLabel>
          <IonChip>{payment.status}</IonChip>
        </IonItem>
        <IonItem>
          <IonLabel>{t('field.amount')}</IonLabel>
          <IonChip>{formatEuroNumber(payment.amount)}</IonChip>
        </IonItem>
        {payment.expiration && (
          <IonItem>
            <IonLabel>{t('field.expiration')}</IonLabel>
            <IonChip>
              {payment.expiration ? moment(payment.expiration).format(process.env.REACT_APP_DATE_FORMAT) : ''}
            </IonChip>
          </IonItem>
        )}
        {payment.url && (payment.status === STATUS_SENT_CECA_TEXT || payment.status === t('status.just_created')) && (
          <IonItem>
            <IonLabel>{t('field.url')}</IonLabel>
            <a href={paymentUrl} target={'_blank'} rel="noreferrer">
              <IonChip color="primary">
                <IonIcon icon={logoEuro} />
                <IonLabel>{t('message.go_pay')}</IonLabel>
              </IonChip>
            </a>
          </IonItem>
        )}
        {payment.token && (
          <IonItem>
            <IonLabel>{t('field.token')}</IonLabel>
            <IonChip>{payment.token}</IonChip>
          </IonItem>
        )}

        {payment.multiplePaymentId ? (
          <div className='multiple-payment-client-info'>
            <IonItem>
              <IonLabel color="primary">{t('field.client_info')}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>{t('field.name')}</IonLabel>
              <IonChip>{payment.name}</IonChip>
            </IonItem>
            {payment.email && (
              <IonItem>
                <IonLabel>{t('field.email')}</IonLabel>
                <IonChip>{payment.email}</IonChip>
              </IonItem>
            )}
            {payment.phone && (
              <IonItem>
                <IonLabel>{t('field.phone')}</IonLabel>
                <IonChip>{payment.phone}</IonChip>
              </IonItem>
            )}
          </div>
        ) : null}

        {payment.url && (payment.status === STATUS_SENT_CECA_TEXT || payment.status === t('status.just_created')) && (
          <div className="qr-code-container">
            <div>
              <QRCode
                value={payment.url}
                size={144}
                renderAs={'svg'}
              />
            </div>
          </div>
        )}
      </IonContent>

      {payment.url && isImpersonatingOrStore(user) && (
        <>
          <SendToFavoriteUserModal
            message={{ shareTitle, shareMessage }}
            getFavoriteUsers={getFavoriteUsers}
          />
          <PaymentShareButtons
            payment={payment}
            message={{ shareTitle, shareMessage }}
            currentUser={user}
          />
        </>
      )}
    </IonPage>
  )
}

export default Payment
