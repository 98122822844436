import { chatboxEllipsesOutline, mail, paperPlane } from 'ionicons/icons'
import logoWhatsappNumber from '../assets/img/logoWhatsappNumber.svg'

const getSendMethodIcon = (shareMethod: string) => {
  switch (shareMethod) {
    case 'email':
      return mail
    case 'sms':
      return chatboxEllipsesOutline
    case 'whatsapp':
      return logoWhatsappNumber
  }
}

export default getSendMethodIcon
