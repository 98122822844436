import axios from 'axios'
import { User } from '../interfaces/interfaces'
import { URL_GET_SUB_USERS } from './constants'

async function getSubUsers(storeId: number): Promise<User[]> {
  return await axios({
    method: 'GET',
    headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
    url: URL_GET_SUB_USERS,
    params: { id: storeId }
  })
    .then((response) => {
      return response.data.length ? response.data : []
    })
    .catch(() => {
      return null
    })
}

export default getSubUsers
