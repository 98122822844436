import './SubUsersList.css'
import { IonList, IonItem, IonLabel, IonChip, IonIcon, IonLoading } from '@ionic/react'
import React, { useState } from 'react'
import { setCurrentSubUser } from '../../../redux/actions'
import SkeletonItems from '../SkeletonItems/SkeletonItems'
import { User } from '../../../interfaces/interfaces'
import useReactQuery from '../../../hooks/useReactQuery'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import getSubUsers from '../../../utils/getSubUsers'
import { accessibility } from 'ionicons/icons'
import useToast from '../../../hooks/useToast'
import impersonateUser from '../../../utils/impersonateUser'

export const SubUsersList: React.FC<{ storeId: number, isAdmin?: boolean }> = ({ storeId, isAdmin = true }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { toast } = useToast()
  const [busy, setBusy] = useState<boolean>(false)

  const { data, isLoading } = useReactQuery<User[]>('getSubUsers', () => getSubUsers(storeId))

  // TODO impersonate sub-user
  // async function _impersonateUser(subUser: User) {
  //   setBusy(true)
  //   impersonateUser(subUser, () => {
  //     toast(t('error.generic'))
  //     setBusy(false)
  //   })
  // }

  return (
    <IonList className="sub-users-list">
      {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

      {data?.length ? (
        data.map((subUser: User) => (
          <div
            key={subUser.id}
            className={`sub-user-card ${subUser.deleted ? 'item-disabled' : ''} ${!isAdmin ? 'cursor-pointer' : ''}`}
            aria-hidden="true"
            onClick={() => {
              if(!isAdmin) dispatch(setCurrentSubUser(subUser))
            }}
          >
            <div className='sub-user-info'>
              <IonItem>
                <IonLabel>{t('field.identifier')}</IonLabel>
                <IonChip color="primary">{subUser.identifier}</IonChip>
              </IonItem>
              <IonItem lines='none'>
                <IonLabel>{t('field.name')}</IonLabel>
                <IonChip color="primary">{subUser.name}</IonChip>
              </IonItem>
            </div>
            {/* {isAdmin && !subUser.deleted && !subUser.parentStore?.deleted && subUser.parentStore?.allowSubUsers && (
              <button className='reset-button impersonate-sub-user-button' onClick={() => { _impersonateUser(subUser) }} >
                <IonIcon className="impersonate-user" icon={accessibility} />
              </button>
            )} */}
          </div>
        ))
      ) : isLoading && (
        <SkeletonItems items={3} />
      )}
    </IonList>
  )
}
