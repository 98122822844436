/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/padding.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'

/* Theme variables */
import './App.css'
import './theme/variables.css'
import './theme/animations.css'

/* Pages */
import Dashboard from './pages/Dashboard/Dashboard'
import EmailVerification from './pages/EmailVerification/EmailVerification'
import Login from './pages/Login/Login'
import Register from './pages/Register/Register'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import ResetPasswordRequest from './pages/ResetPasswordRequest/ResetPasswordRequest'

import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { IonApp, IonLoading, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { getCurrentUser, getUserData, logoutUser } from './firebase'
import './i18n'
import { setPWAEvent, setUserData, setUserState } from './redux/actions'
import { User } from './interfaces/interfaces'
import GeneratePayment from './pages/GeneratePayment/GeneratePayment'
import CookiesModal from './components/common/CookiesModal/CookiesModal'
import { RECAPTCHA_V3_SITE_KEY } from './utils/constants'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

require('dotenv').config()

setupIonicReact()

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem('AUTH_TOKEN')
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const queryClient = new QueryClient()

const RoutingSystem: React.FC = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <IonApp>
            <IonReactRouter>
              <IonRouterOutlet>
                <Route exact path="/register" component={Register} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/reset_password" component={ResetPasswordRequest} />
                <Route exact path="/confirm_reset_password" component={ResetPassword} />
                <Route exact path="/email_verification" component={EmailVerification} />
                <Route exact path="/generate_payment/:seed" component={GeneratePayment} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/stores">
                  <Redirect to="/dashboard" />
                </Route>
                <Route exact path="/admins">
                  <Redirect to="/dashboard" />
                </Route>
                <Route exact path="/payments">
                  <Redirect to="/dashboard" />
                </Route>
                <Route exact path="/multiple_payments">
                  <Redirect to="/dashboard" />
                </Route>
                <Route exact path="/favorites">
                  <Redirect to="/dashboard" />
                </Route>
                <Route exact path="/profile">
                  <Redirect to="/dashboard" />
                </Route>
              </IonRouterOutlet>
            </IonReactRouter>
          </IonApp>
        </ApolloProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  )
}

const autoLogout = async () => {
  const dispatch = useDispatch()

  const lastActive = localStorage.getItem('LAST_ACTIVE') ?? new Date().toISOString()
  const lastActiveMinutes = Math.round((Date.now() - new Date(lastActive).getTime()) / 60000)

  if (lastActiveMinutes >= 30) {
    await logoutUser()
    localStorage.clear()
    dispatch(setUserState(null))
  }

  const idleTimer = useIdleTimer({
    onIdle: async () => {
      await logoutUser()
      localStorage.clear()
      dispatch(setUserState(null))
      window.location.href = '/login'
    },
    onAction: () => {
      idleTimer.reset()
      idleTimer.getLastActiveTime() && localStorage.setItem('LAST_ACTIVE', new Date().toISOString())
    },
    timeout: 1000 * 60 * 30,
    crossTab: true,
    startManually: true,
    events: ['click', 'dblclick', 'touchstart', 'touchmove']
  })

  idleTimer.start()
}

const App: React.FC = () => {
  const dispatch = useDispatch()
  const [busy, setBusy] = useState<boolean>(true)
  const currentUser: User | null = useSelector((state: any) => state.userData)
  const deferredPrompt: any = useSelector((state: any) => state.pwaEvent)

  autoLogout()

  useEffect(() => {
    getCurrentUser().then(async (user: any) => {
      setBusy(false)
      const merchantId = localStorage.getItem('USER_MERCHANT_ID') || ''

      const userData = await getUserData(merchantId)
      if (!userData || userData.deleted || (userData.parentStoreId && (userData.parentStore?.deleted || !userData.parentStore?.allowSubUsers))) {
        await logoutUser()
        localStorage.clear()
      }

      if (user?.uid !== currentUser?.uid) dispatch(setUserData(userData))

      if (!user) {
        if (window.location.pathname === '/action') {
          const url = new URL(window.location.href)
          const mode = url.searchParams.get('mode') || ''
          switch (mode) {
            case 'resetPassword':
              window.location.href = '/confirm_reset_password' + url.search
              break
            // case 'recoverEmail':
            //   break;
            case 'verifyEmail':
              window.location.href = '/email_verification' + url.search
              break
            default:
              window.location.href = '/login'
          }
        }

        if (
          window.location.pathname !== '/login' &&
          window.location.pathname !== '/action' &&
          window.location.pathname !== '/confirm_reset_password' &&
          window.location.pathname !== '/email_verification' &&
          !window.location.pathname.includes('/generate_payment/')
        ) {
          window.location.href = '/login'
        }
      }

      const urlsToDashboard = [
        '/',
        '/login',
        '/register',
        '/action',
        '/reset_password',
        '/confirm_reset_password',
        '/email_verification'
      ]
      if (user && urlsToDashboard.includes(window.location.pathname)) {
        window.location.href = '/dashboard'
      }
    })
  })

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      if (!deferredPrompt) dispatch(setPWAEvent(e))
    })

    return () => {
      window.removeEventListener('beforeinstallprompt', () => {})
    }
  }, [])

  return (
    <IonApp>
      {busy ? <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} /> : (
        <>
          <RoutingSystem />
          <CookiesModal />
        </>
      )}
    </IonApp>
  )
}

export default App
