import { useCallback, useState, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import axios from 'axios'
import { URL_VERIFY_RECAPTCHA_TOKEN } from '../utils/constants'

export const useRecaptcha = (action: string) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false)

  useEffect(() => {
    setIsRecaptchaReady(!!executeRecaptcha)
  }, [executeRecaptcha])

  const handleVerifyRecaptcha = useCallback(async (verifyToken = false) => {
    if (!executeRecaptcha) {
      return { recaptchaToken: '', error: 'recaptcha/not-initialized' }
    }

    try {
      const recaptchaToken = await executeRecaptcha(action)
      if (verifyToken) {
        const params = new URLSearchParams({ recaptchaToken, recaptchaAction: action }).toString()
        const response = await axios({
          method: 'GET',
          headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
          url: `${URL_VERIFY_RECAPTCHA_TOKEN}?${params}`
        })

        if (response.status !== 200) {
          return { recaptchaToken: '', error: 'recaptcha/verify-failed' }
        }
      }

      return { recaptchaToken }
    } catch (e) {
      return { recaptchaToken: '', error: (e as Error).message }
    }
  }, [executeRecaptcha, action])

  return { handleVerifyRecaptcha, isRecaptchaReady }
}
